import { GuidanceNoteType } from '@property-folders/common/types/GuidanceNoteType';
import { NotesTable } from '@property-folders/common/types/NotesTable';
import { ExternalLink } from './ExternalLink';
import { LegalActs } from '@property-folders/common/util/pdfgen/formatters/clauses';
import { LegalJurisdiction } from '@property-folders/common/data-and-text/constants';

import type { FormatActOptions } from '@property-folders/common/util/pdfgen/formatters/clauses';
import type { LegalActsType, LegalJurisdictionValue } from '@property-folders/common/data-and-text/constants';

/**
 * Format Act in TSX.
 * - Based on the `formatAct()` function.
 * @param legalJurisdiction Legal jurisdiction.
 * @param actName Act name relative to `legalJurisdiction`.
 * @param options Optional formatting options.
 * @returns Formatted Act string.
 * ```tsx
 * formatAct(LegalJurisdiction.Commonwealth, 'ANewTaxSystemGoodsAndServicesTaxAct1999');
 * // <span className='fst-italic'>A New Tax System (Goods and Services Tax) Act 1999</span> (Cth)
 * formatAct(LegalJurisdiction.Commonwealth, 'ANewTaxSystemGoodsAndServicesTaxAct1999', { noItalics: true });
 * // A New Tax System (Goods and Services Tax) Act 1999 (Cth)
 * formatAct(LegalJurisdiction.Commonwealth, 'ANewTaxSystemGoodsAndServicesTaxAct1999', { allItalics: true });
 * // <span className='fst-italic'>A New Tax System (Goods and Services Tax) Act 1999 (Cth)</span>
 * formatAct(LegalJurisdiction.Commonwealth, 'ANewTaxSystemGoodsAndServicesTaxAct1999', { noLegalJurisdiction: true });
 * // <span className='fst-italic'>A New Tax System (Goods and Services Tax) Act 1999</span>
 * formatAct(LegalJurisdiction.Commonwealth, 'ANewTaxSystemGoodsAndServicesTaxAct1999', { noItalics: true, noLegalJurisdiction: true });
 * // A New Tax System (Goods and Services Tax) Act 1999
 * ```
 */
export function FormatAct<TLegalJurisdiction extends LegalJurisdictionValue>(
  legalJurisdiction: TLegalJurisdiction,
  actName: keyof LegalActsType[TLegalJurisdiction],
  options?: FormatActOptions
) {
  const act = LegalActs[legalJurisdiction][actName] as string;
  const { allItalics, noItalics, noLegalJurisdiction } = options ?? {};

  if (allItalics) {
    return <span className='fst-italic'>{act} ({legalJurisdiction})</span>;
  } else if (noItalics) {
    return <>{act}{!noLegalJurisdiction && ` (${legalJurisdiction})`}</>;
  } else {
    return <><span className='fst-italic'>{act}</span>{!noLegalJurisdiction && ` (${legalJurisdiction})`}</>;
  }
}

const executorGuidanceBody = <div>
  <span className='fw-bold'>Has probate been granted?</span>
  <ul>
    <li>To ensure a Vendor is legally signing an Agreement as Executor, you should check they have the proper
      authority to do so. When a South Australian resident dies with a valid Will in place, an Executor is appointed.
      The Executor is usually the deceased’s next of kin or other close family member or individual.
      The Public Trustee can act as Executor under a deceased person’s Will.
    </li>
    <li>A Grant of Probate (<span className='fw-bold'>Probate</span>) must be validly granted by the Supreme Court of SA
      to enable the Executor to distribute and dispose of the assets of the deceased person.
    </li>
    <li>You should request a copy of the registered Probate from the Vendor and keep a copy on file as evidence of
      valid signing capacity. If Probate has not been granted at the time the Contract is executed,
      you should liaise with the Vendor’s solicitor or conveyancer to find out when Probate is expected to be granted
      and in order to draft any required provisions into the Contract.
    </li>
  </ul>
</div>;
const adminGuidanceBody = <div>
  <span className='fw-bold'>Have Letters of Administration been granted?</span>
  <ul>
    <li>To ensure a Vendor is legally signing an Agreement as Administrator, you should check they have the proper
      authority to do so.
    </li>
    <li>When a South Australian resident dies without a valid Will in place, an Administrator is appointed in order to
      distribute the person’s estate.
    </li>
    <li>Usually, the deceased person’s next of kin, another close relative or family friend will apply for Letters of
      Administration with the Supreme Court of South Australia.
    </li>
    <li>You should request a copy of the registered Letters of Administration from the Vendor and keep a copy on file
      as evidence of valid signing capacity.
    </li>
  </ul>
</div>;

export const notesTable: NotesTable = {
  [GuidanceNoteType.ResidentialLandType]: {
    title: 'Notes for Land Type',
    body: <div>
      <div>
        <span><span style={{ fontWeight: 'bolder', fontStyle: 'italic' }}>Residential land</span> means—</span>
        <ol type={'a'}>
          <li>land on which a single place of residence or two places of residence are situated, or in the course of
            construction, and on which there are no other improvements; or
          </li>
          <li>vacant land on which it is lawful to construct a place of residence; or</li>
          <li>a community lot or unit under the <ExternalLink
            href={'https://www.austlii.edu.au/au/legis/sa/consol_act/cta1996224/'}>Community Titles Act
            1996</ExternalLink> or the <ExternalLink
            href={'https://www.austlii.edu.au/au/legis/sa/consol_act/sta1988173/'}>Strata Titles Act
            1988</ExternalLink> comprising a single place of residence, whether constructed or in the course of
            construction, including a place used or designed for use for a purpose ancillary to the place of residence;
            or
          </li>
          <li>an exclusive right (whether deriving from the ownership of a share or interest in a body corporate or
            partnership or arising in some other way) to the separate occupation of a single place of residence, whether
            constructed or in the course of construction, including a place used or designed for use for a purpose
            ancillary to the place of residence,
          </li>
        </ol>
        <span>but does not include—</span>
        <ol type={'a'} start={5}>
          <li>land or a community lot or unit that is used wholly for non-residential purposes; or</li>
          <li>land that is more than 2.5 hectares in area (or such other area as may be prescribed); or</li>
          <li>an exclusive right to the separate occupation of land referred to in paragraph (e) or (f);</li>
        </ol>
      </div>
      <div>
        <span><span style={{ fontWeight: 'bolder', fontStyle: 'italic' }}>Rural land</span> means land where one or both of the following are satisfied—</span>
        <ul>
          <li>The land is more than 2.5 hectares in area (regardless of whether it has a residence on it or not);
            and/or
          </li>
          <li>The land is used wholly for primary production purposes.</li>
        </ul>
        <p>
          Even if the above is satisfied, you should consider if it’s more appropriate to deem the land 'Commercial
          land' given the nature of the property and the purpose for which it is being used.
        </p>
      </div>
      <div>
        <span><span style={{ fontWeight: 'bolder', fontStyle: 'italic' }}>Commercial land</span> means land where—</span>
        <ul>
          <li>the land is used wholly for non-residential purposes, but excluding rural land.</li>
          <li>the uses include retail, industrial, office, hotels, caravan parks, warehouses and similar.</li>
        </ul>
        <p>
          You should not use this document for the preparation of a contract relating to a property in a retirement
          village pursuant to the Retirement villages Act 2016 (SA).
        </p>
      </div>
    </div>
  },
  'saleMethodNote': {
    buttonTitle: 'Notes for Method of Sale',
    title: 'Which Method of Sale will be chosen for the Sale of the Property?',
    body: <div>
      <span className='fw-bold'>Auction</span>
      <ul>
        <li>The Vendor must set a reserve price prior to the Auction. The reserve price must not, at any time before or
          during the Auction, be set at an amount exceeding 110% of the Vendor’s Selling Price (section 24J(a)(ba) {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}).
        </li>
        <li>A Sales Agency Agreement (<span className='fw-bold'>SAA</span>) for the sale of residential land by Auction may not be varied by increasing the
          amount specified in the Agreement as the selling price sought by, or acceptable to, the Vendor (section
          20(5a) {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}).
        </li>
        <li>If a SAA is terminated prior to the expiry of the term or is varied to reduce the term of the SAA,
          agents must not enter into a new SAA with the Vendor specifying a Vendor’s Selling Price which is higher
          than the Vendor’s Selling Price in the expired or varied SAA (section 20(6f) {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}).</li>
        <li>No cooling-off period applies for an auction. Contract conditions to be negotiated prior to the Auction.
        </li>
      </ul>
      <span className='fw-bold'>Private Treaty</span>
      <ul>
        <li>The Vendor sets the purchase price and the Agent negotiates with potential purchasers on behalf of the
          Vendor, for the best possible price.
        </li>
        <li>Once a contract has been signed, a cooling-off period allows the Purchaser to cancel the Contract following
          receipt by the Purchaser of the Form 1 (Vendor’s Statement). The cooling-off period ends at 5pm on the second
          clear Business Day from when the Form 1 is served.
        </li>
      </ul>
      <span className='fw-bold'>Off-market</span>
      <ul>
        <li>An ‘off-market’ sale is a sale that has not been officially marketed to the public or has been marketed with
          minimal advertising or scheduled open inspections. A Purchaser can present an offer to a Vendor/Agent with
          little competition from other potential purchasers.
        </li>
      </ul>
      <span className='fw-bold'>Expression of interest (EOI) (or sale by tender)</span>
      <ul>
        <li>The Agent sets a deadline on which the Vendor intends to sell the Property (generally a 4-6 week campaign).
          Potential purchasers can inspect the Property and if they want to progress further, they can submit their
          ‘best and final offer’ in writing, before the deadline to the Agent, which will include conditions of offer
          such as finance, settlement date. When the EOI period ends, the Vendor and the Agent will review the offers
          and determine the successful offer.
        </li>
        <li>If an acceptable offer is not found by the Vendor, the Property can be placed on the market as a private
          treaty sale or another EOI campaign can be commenced.
        </li>
      </ul>
    </div>
  },
  attorney: {
    title: 'Notes for Power of Attorney',
    body: <div>
      <span className='fw-bold'>Is a party executing under power of attorney?</span>
      <ul>
        <li>To ensure a party is legally signing an Agreement under power of attorney, you should ask the party for a
          copy of the power of attorney document and if you are unsure as to whether it confers the requisite authority
          on the party to execute the Agreement, seek the assistance of a solicitor or conveyancer.
        </li>
        <li>You can enter into a sales agency agreement on the assumption the power of attorney is valid, however, you
          should ensure the power of attorney is valid, prior to signing up a contract.
        </li>
        <li>Where an attorney is acting, still enter the registered proprietor’s name, but include the attorney’s name
          and contact details, where indicated. The attorney will be the person signing this Agreement.
        </li>
      </ul>
    </div>
  },
  executorNatural: {
    title: 'Notes for Executor',
    body: executorGuidanceBody
  },
  executorCompany: {
    title: 'Notes for Executor',
    body: executorGuidanceBody
  },
  adminNatural: {
    title: 'Notes for Administrator',
    body: adminGuidanceBody
  },
  adminCompany: {
    title: 'Notes for Administrator',
    body: adminGuidanceBody
  },
  guardian: {
    title: 'Notes for Guardian',
    body: <div>
      <span className='fw-bold'>Is a party executing as a guardian?</span>
      <ul>
        <li>A guardian is a parent of a child, or a person appointed by a court or tribunal to manage a person’s estate
          where that person is unable to manage their own affairs due to some form of incapacity.
        </li>
        <li>You should only proceed where the guardian confirms they have the appropriate power to act on behalf of the
          registered proprietor on the Property.
        </li>
        <li>You should seek assistance from a solicitor if you are unsure whether a guardian has the proper power to act
          on behalf of a protected person in a property sale.
        </li>
        <li>Where a guardian is acting, still enter the registered proprietor’s name below, but include the guardian’s
          name and contact details, where indicated. The guardian will be the person signing this Agreement.
        </li>
      </ul>
    </div>
  },
  'pmap': {
    title: 'What is the PMAP?',
    body: <div>
      <ul>
        <li>The PMAP is the higher of the Agent’s Estimated Selling Price and the Vendor’s Selling Price. Advertised
          prices or representations made to prospective buyers cannot be below the PMAP.
        </li>
        <li>If a price range is used when marketing the Property, the upper limit of the range must not be more than 10%
          above the lower limit.
        </li>
        <li>The Agent’s estimate is the Agent’s genuine estimate based on comparable sales and market research and is
          not a valuation. The Vendor may request a written valuation be obtained from a valuer at the Vendor’s cost
          (section 24A(1)(d) {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}).
        </li>
      </ul>
    </div>
  },
  'agentEstPrc': {
    title: 'Notes for Price',
    body: <div>
      <ul>
        <li>The Agent’s Estimated Selling Price must be the Agent’s genuine estimate based on comparable sales and
          market research. It is not a valuation.
        </li>
        <li>The Vendor may request a written valuation be obtained from a valuer, at the Vendor’s cost (section
          24A(1)(d) {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}).
        </li>
        <li>The price you have estimated as the selling price for the Property should be expressed as a single figure,
          without any qualifying words or symbols (section 20(1)(a) {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}).
        </li>
        <li>The price the Vendor has estimated as an acceptable selling price for the Property should be expressed as a
          single figure, without any qualifying words or symbols (section 20(1)(b) {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}).</li>
        <li>The Vendor is required to nominate a single price sought or acceptable for sale.</li>
        <li>The Prescribed Minimum Advertising Price (PMAP) is the higher of the Agent’s Estimated Selling Price and
          the Vendor’s Selling Price. Advertised prices or representations made to prospective buyers cannot be
          below the PMAP.</li>
        <li>If a price range is used when marketing the Property, the upper limit of the range must not be more than
          10% above the lower limit.</li>
        <li>The Agent’s estimate is the Agent’s genuine estimate based on comparable sales and market research and
          is not a valuation.  The Vendor may request a written valuation be obtained from a valuer at the Vendor’s
          cost (section 24A(1)(d) {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}).</li>
      </ul>
    </div>
  },
  'vendorPrc': {
    title: 'Notes for Vendor’s Selling Price',
    body: <div>
      <ul>
        <li>The price the Vendor has estimated as an acceptable selling price for the Property should be expressed as a
          single figure, without any qualifying words or symbols (section 20(1)(b) {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}).
        </li>
        <li>The Vendor is required to nominate a single price sought or acceptable for sale.</li>
      </ul>
    </div>
  },
  'vendorPrcAuctionIncrease5a': {
    title: 'Vendor’s Selling Price at Auction ',
    body: <div>
      <div>Per Section 20 (5a) of the {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}:</div>
      <p className='ms-2 mt-2'>
        A sales agency agreement for the sale of residential land by auction may not be varied by increasing the amount specified in the agreement as the selling price sought by, or acceptable to, the vendor.
      </p>
    </div>
  },
  'comparableSales': {
    title: 'Has the Agent provided the Vendor with comparable sales information?',
    body: <div>
      Agents must gather information as to what they are relying on to support the Agent’s Estimated Selling Price. It
      is a requirement to include recent sales figures for comparable properties in the area and any other information
      the Agent relies upon when setting the price.
    </div>
  },
  calculationProfessionalFee: {
    title: 'What is a Professional Fee?',
    body: <div>
      <ul>
        <li>A Professional Fee is the amount payable by a Vendor to the Agent for professional services undertaken in
          connection with the sale of the Property.
        </li>
        <li>This can be in the form of either a fixed fee or commission. Each Agency will have
          methods of setting the applicable fees and commission rate for a particular sale.
        </li>
        <li>Agents are free to set their own Professional Fees and must be agreed by the Vendor.</li>
      </ul>
    </div>
  },
  cgt: {
    title: 'Notes for CGT applicability',
    body: 'If the sale price is $750,000 or over, all Vendors will require a CGT clearance to avoid a withholding tax at settlement being paid to Australian Taxation Office (ATO).'
  },
  gst: {
    title: 'Notes for GST applicability',
    body: <div>
      If the Property constitutes new residential premises (substantial renovations and commercial residential
      premises <span className='fw-bold'>not</span> applicable) or if the Property constitutes potential residential
      land included in a property subdivision plan as defined in Subdivision 14-E of Schedule 1 – {FormatAct(LegalJurisdiction.Commonwealth, 'TaxationAdministrationAct1953')} then the Vendor must give the Purchaser a GST
      Withholding Notice at least 14 days prior to settlement. The Purchaser is personally liable to pay the required
      GST amount to the ATO and is discharged from paying that to the Vendor in the purchase price at settlement.
    </div>
  },
  auctionFee: {
    title: 'What is an Auction Fee?',
    body: <div>
      The Auction Fee is an amount set by the Agent and is payable by the Vendor in order to recover the Auctioneer's
      fee for preparing for and conducting the Auction, the time of the Agent's representatives to prepare for and
      attend on Auction day, any technology fees (if applicable) for Auction compliance and additional administrative
      costs.  It allows the agency to recover any costs incurred as a result of auction being the Vendor’s agreed
      method of sale.
    </div>
  },
  gstReg: {
    title: 'Is the Vendor required to be registered for GST?',
    body: <ul>
      <li>The Vendor is only advising the Agent whether they are required to be registered for GST. This is not a
        determination for the Agent to make.
      </li>
      <li>The Vendor should obtain advice from their financial advisor regarding GST related issues.</li>
    </ul>
  },
  upliftCommis: {
    title: 'Will a commission uplift be used?',
    body: <div>
      <ul>
        <li>Agents may negotiate an amount to be charged to the Vendor in addition to the Professional Fee. This is
          calculated as an agreed percentage to be applied to the amount by which the sale price exceeds the agreed
          higher sales threshold.
        </li>
        <li>This is in consideration of the work done and the sale price achieved by the Agent.</li>
        <li>By example, if the agreed higher sales price threshold is set at $500,000 and the Contract sale price is
          $600,000, then the commission uplift if set at 20% is to be 20% of the additional $100,000 (inc GST).
        </li>
      </ul>
    </div>
  },
  staturorySearchesAndDocumentationFee: {
    title: 'Which statutory searches and other legal costs should be recovered from Vendors?',
    body: <div>
      <ul>
        <li>Vendors must pay all costs associated with obtaining the statutory searches required for the purposes of
          preparing a Form 1 (Vendor’s statement) which must be served prior to the Contract and starts the
          cooling-off period. These include Property Interest Report, Council Search, title documents (eg easements,
          caveats, court orders and plans) and SA Water Special Meter Readings.
        </li>
        <li>Agents should ensure each search required is itemised and costed in order to recover all associated fees
          from Vendors.
        </li>
        <li>Additionally, preparation and certification costs associated with the Form 1 should also be charged to the
          Vendor and all costs associated with the use of reaforms to manage a property transaction.
        </li>
      </ul>
    </div>
  },
  administrationFee: {
    title: 'What is an Administration Fee?',
    body: <div>
      <ul>
        <li>You can negotiate an amount to be charged to a Vendor in addition to the Professional Fee in order to
          recover administrative costs associated with running the sale of the Property.
        </li>
        <li>This amount can include office overheads, stationary and any other administrative expenses.</li>
      </ul>
    </div>
  },
  agencyDuration: {
    title: 'Notes for Agreement duration',
    body: <div>
      <ul>
        <li>The Term of the Agreement <span className='fw-bold'>must not exceed 90 days</span> unless the Vendor
          consents to an extension of this Agreement in accordance with the requirements of the {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}.
        </li>
        <li>The Agent is reminded to issue a ‘Notice of Expiry’ (see reaforms <a href='/forms/categories/1001/forms/131/documents'>‘Residential
          Sales Agency Agreement – Expiry Pending Notice’ - SAR011</a>), <span className='fw-bold'>no earlier than 14 days</span> before
          this Agreement is due to expire, in order to extend the Term of this Agreement for a further 90 days, being
          the prescribed time pursuant to Regulation 19 of the {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingRegulations2010')}.
        </li>
        <li>Sales agency agreements with vendors who carry on the business of a developer of land, and where the
          Property has been subdivided, may be for durations of longer than 90 days. On expiry, the Agreement will
          continue as a ‘general agency’ agreement until either party terminates with one month’s written notice.
        </li>
      </ul>
    </div>
  },
  startOfAgreement: {
    title: 'When will the Agreement commence?',
    body: <div>
      <ul>
        <li>The parties can commence the Agreement as at the date of the Agreement, when both parties sign it or the
          parties can elect for the Agreement to be forward dated upon another event occurring.
        </li>
        <li>The Vendor should fully understand the terms and conditions of the Agreement before signing.</li>
      </ul>
    </div>
  },
  soleAgency: {
    title: 'What is a Sole Agency (exclusive) agreement?',
    body: <div>
      <span className='fw-bold'>Sole Agency (exclusive)</span> – means the <span className='text-decoration-underline'>exclusive appointment of the Agent</span> for
      the purpose of marketing and selling the Property for the Term of the Agreement.
    </div>
  },
  generalAgency: {
    title: 'What is a General Agency (non-exclusive) Agreement?',
    body: <div><span className='fw-bold'>General Agency (non-exclusive)</span> – means the <span
      className='text-decoration-underline'>non-exclusive appointment of the Agent</span> for the purpose of marketing
      and selling the Property for the Term of the Agreement. The Vendor can appoint other agents to market and sell the
      Property.</div>
  },
  associatedAgents: {
    title: 'Will the Agent appoint another agent (associated agent) to assist in selling the Property?',
    body: <div>
      <ul>
        <li>The Vendor is to provide written authority if it consents to the Agent appointing other licensed agents to
          assist in the sale of the Property (known as ‘agents in conjunction’).
        </li>
      </ul>
    </div>
  },
  previousAgent: {
    title: 'Has the Vendor signed any previous sales agency agreements with other agents that require termination?',
    body: <ul>
      <li>It is the Vendor’s responsibility to make sure they terminate all previous sales agency agreements with other
        agents by written notice and a copy of the signed written notice should be provided to the Agent.
      </li>
      <li>The Vendor may sign a notice authorising the Agent to send to the previous agent on behalf of the Vendor.</li>
      <li>The Vendor needs to be advised that if they do not terminate previous sales agency agreements with any other
        agents, then they may have to pay professional fees to both the Agent and previous agent.
      </li>
    </ul>
  },
  consumerCreditChattels: {
    title: 'What are consumer credit chattels?',
    body: <div>
      The contracts on chattels (goods in the house ie stove, air conditioner) that are still under lease or consumer
      credit contract but are to be included in the purchase must either be paid out by the Vendor (so that clear title
      to those chattels can be passed to the Purchaser at settlement) or assigned to the Purchaser. However, note that
      the credit provider may refuse to accept an assignment from the Vendor to the Purchaser.
    </div>
  },
  tenancy: {
    title: 'Is there an existing tenancy agreement for the Property?',
    body: <div>
      <ul>

        <li><span className='fw-bold'>Fixed term tenancy agreement</span> – a fixed term tenancy agreement exists where
          the parties have agreed on a specific length of tenancy (usually 6 months or 12 months or can be for any
          period as long as it is a fixed period)</li>
        <li>Either party can provide written notice of an intention to terminate the tenancy at the end of a fixed term
          pursuant to section 83A of the {FormatAct(LegalJurisdiction.SouthAustralia, 'ResidentialTenanciesAct1995')} (<span
          className='fw-bold'>Act</span>) (for Landlords) and section 86B of the Act (for Tenants). The period of
          notice must be at least 28 days. (Refer to Forms/Notices on reaforms).</li>
        <li>If a fixed term tenancy agreement has not been terminated by either party before or at the end of the fixed
          term, the fixed term tenancy agreement continues as a periodic tenancy agreement.</li>
        <li><span className='fw-bold'>Periodic tenancy agreement</span> – this is for a recurring period without a fixed
          term. It can be written or verbal. A periodic tenancy agreement can continue indefinitely with a tenancy
          period equivalent to the interval between rental payment times under a periodic tenancy agreement and will
          recur automatically until either party provides a written notice to terminate a periodic tenancy agreement
          (refer to Forms/Notices on reaforms).</li>
      </ul>
      <div className='fw-bold mb-3 mt-5'>Sale subject to existing tenancy</div>
      <ul>
        <li>Section 47A of the Act provides
          that before entering into a residential tenancy agreement, a landlord must
          ensure a prospective tenant is advised if the landlord has advertised the residential property for sale and of
          any existing sales agency agreement for the sale of the Property.
        </li>
        <li>If a tenant is to remain on the Property and the Property is to be sold subject to an existing residential
          tenancy, the landlord/Vendor (or Agent on behalf of the Vendor/landlord) needs to provide a written notice to
          the tenant, not later than 14 days after the landlord enters into a sales agency agreement for the sale of the
          Property, in accordance with section 71A of the Act.</li>
        <li>The Property will not be advertised for sale or made available for inspection by prospective purchasers
          before the day falling 14 days after the tenant is given written notice of the Vendor’s intention to sell the
          Property (section 71A(1)(b) of the Act).</li>
      </ul>
    </div>
  },
  knownEncroachments: {
    title: 'Is the Vendor aware of any encroachments and fences not on boundary lines of the Property?',
    body: <div>
      <ul>
        <li>An encroachment is when a structure intrudes onto another person’s land, crossing the legal boundary.
          Examples – driveways, buildings, eaves, balconies.
        </li>
        <li>If a Vendor is aware of known encroachments, they should disclose these to the Agent.</li>
        <li>Fences that are between neighbouring properties should be located on the Property boundary.</li>
        <li>If the Vendor is aware of any fences on the Property that are not on the legal Property boundary, then they
          must disclose these to the Agent. The legal boundary can only be determined by a licensed surveyor.
        </li>
      </ul>
    </div>
  },
  alterationsWithoutConsent: {
    title: 'Is the Vendor aware of any alterations and improvements that have been erected on the Property without consent?',
    body: <div>
      <p>If any alterations and/or improvements have been made to the Property or erected on the Property without proper
        legal approval/consent from the relevant council or planning authority, this must be disclosed by the Vendor to
        the Agent. </p>
      <p>Refer to Plan SA for further information.</p>
    </div>
  },
  propertyFoldersBlurb: {
    title: 'More about Property Folders',
    body: <div>
      <div className='mb-3'>Property Folders help you create and store related, compliant forms for a specific property
        transaction, in the one place.
      </div>
      <ul>
        <li>Information you enter into a Property Folder transfers to all documents created within that folder.</li>
        <li>Property information is accessed directly from Land Services SA, ensuring all address, title and registered
          proprietor details are correct.
        </li>
        <li>Easy to use, industry leading e-signing, with no configuration required.</li>
        <li>Records all changes to documents and who they were made by, in one place.</li>
        <li>Create Variations (also known as addendums) to existing forms with ease.</li>
        <li>Legal rules built into documents to maximise compliance.</li>
        <li>Notes to guide and assist you are embedded throughout the Property Folder and forms.</li>
      </ul>
      <div>The more information you enter when setting up your Property Folder, the less you will need to enter later
      </div>
    </div>
  },
  clauseManagement: {
    title: 'About Clauses',
    body: <div>
      You can create custom clauses here, and assign them to different forms. When a user creates a new document, they
      will be able to easily select from the relevant clauses. The user will be able to edit a clause in a particular
      document if needed. reaforms comes with a large number of Standard Clauses built-in to get you started.
    </div>
  },
  inputTaxedPremises: {
    title: 'What is an input taxed residential premises?',
    body: <ul>
      <li>Residential premises means land or a building that is occupied as a residence or for residential
        accommodation; or is intended to be occupied, and is capable of being occupied, as a residence or for
        residential accommodation.
      </li>
      <li>Input taxed means a good for which GST cannot be charged or claimed, usually applies to existing (not new)
        premises.
      </li>
      <li>The ATO provides <ExternalLink
        href='https://www.ato.gov.au/business/gst/when-to-charge-gst-(and-when-not-to)/input-taxed-sales/residential-premises/#Sale_of_Residential_Premises'>some
        guidelines</ExternalLink> on how to determine if a premises is input taxed.
      </li>
      <li>The Agent is not qualified to give advice on whether a premises is input taxed. The Vendor and Purchaser must
        obtain their own professional advice.
      </li>
    </ul>
  },
  gstWithholdingContract: {
    title: 'Guidance notes for GST Withholding Tax',
    body: <ul>
      <li>If the Property constitutes new residential premises (<span className='fst-italic'>substantial renovations and commercial residential premises not applicable</span>)
        or if the Property constitutes potential residential land included in a property subdivision plan as defined in
        Subdivision 14-E of Schedule 1 – {FormatAct(LegalJurisdiction.Commonwealth, 'TaxationAdministrationAct1953')} then
        General Condition Clause 28 applies.
      </li>
      <li>The Vendor must give the Purchaser a GST Withholding Notice at least 14 days prior to settlement. The
        Purchaser is personally liable to pay the required GST amount to the ATO and is discharged from paying that to
        the Vendor in the purchase price at settlement.
      </li>
    </ul>
  },
  periodicPress: {
    title: 'Has a Periodic Press Promotion Budget been agreed?',
    body: <ul>
      <li>In addition to the advertising and marketing amounts to be charged to a Vendor (as set out in Item 7,
        you can negotiate with the Vendor for an additional amount to be used for periodic marketing activities.</li>
      <li>The amount of additional expenditure can be set as a weekly, fortnightly or monthly amount.
        Alternatively, the Vendor can agree to a fixed amount to be spent in the lead up to an open inspection(s),
        for the purpose of creating additional interest from prospective purchasers.</li>
    </ul>
  },
  poolCompliance: {
    title: 'Notes for pool/spa compliance',
    body: <div>
      <div className='fw-bold mb-3 mt-5'>What is a swimming pool?</div>
      <ul>
        <li>A swimming pool (‘<span className='fw-bold'>Pool</span>’) means an excavation or structure that is capable of being filled with water and is used primarily
        for swimming, wading, paddling or the like and includes a bathing or wading pool or spa pool (but does not
        include a spa bath).
        </li>
      </ul>
      <div className='fw-bold mb-3'>What is a spa pool?</div>
      <ul>
        <li>
        A spa pool is a water retaining structure that can hold more than 680 litres of water and is designed for human
        use. It has equipment to heat the water and inject air bubbles or water into it.
        </li>
      </ul>
      <div className='fw-bold mb-3'>If there is a Pool on the Property </div>
      <ul>
        <li>The Vendor must ensure that on or before settlement, any Pool on the Property is compliant with the
          {' '}{FormatAct(LegalJurisdiction.SouthAustralia, 'PlanningDevelopmentAndInfrastructureAct2016')}.
        </li>
        <li>
        There is no obligation in the Greatforms standard Contract template for the Vendor to produce and/or obtain a
        Pool compliance certificate prior to Settlement. If the Purchaser requires one, they would need to negotiate
        that prior to entering into the Contract, and it could be added as an Additional Condition if the Vendor agrees
        to do so.
        </li>
      </ul>
    </div>
  },
  /** @deprecated */
  poolComplianceContract: {
    title: 'Notes for pool/spa pool compliance',
    body: <div>
      If applicable, the Vendor must by law at or before Settlement ensure that the swimming pool or spa pool
      (‘<span className='fw-bold'>Pool</span>’) complies with all Pool safety requirements.
      There is no obligation in the Greatforms standard Contract template for the Vendor to produce and/or
      obtain a Pool compliance certificate prior to Settlement. If the Purchaser requires one, they would need to
      negotiate that prior to entering into the Contract, and it could be added as an Additional Condition if the Vendor
      agrees to do so.</div>
  },
  purchaserSaleSpecialRemainder: {
    title: 'Guidance notes for Contract terms for Purchaser sale',
    body: <><p className='fst-italic'>Filled out fields not shown here. They will be filled in the final document.</p>
      <ol className="special-contract start-1">
        <li>
          SALE OF THE PURCHASER’S PROPERTY
          <div>The Purchaser will use best endeavours to sell and effect settlement of the property, the subject of this
            Special Condition.</div>
        </li>
        <ol>
          <li>This Contract is conditional upon the Purchaser entering into a contract on or before the [Deadline for
            contract] for the sale of property at [Address of Property to be sold] for a price of not less than [Minimum
            sale price] or such lesser sum that the Purchaser may accept and the settlement of that contract occurring
            on or before the [Deadline for settlement].
          </li>
          <li>This Contract is conditional upon the sale of the Purchaser's property, being the property at [Address of
            Property to be sold] settling on or before the [Date of settlement] pursuant to a contract entered into by
            the Purchaser dated the [Date of entry into contract].
          </li>
          <li>In the event that the condition in SC 2.1 or SC 2.2 (if applicable) is not satisfied within the time
            prescribed and provided the Purchaser has not waived this Special Condition and communicated such waiver to
            the Vendor in writing either party (but, in the case of the Purchaser, provided it has used best endeavours
            as required by this clause) may immediately terminate this Contract by giving notice in writing
            to the other.
          </li>
          <li>In the event of termination of this Contract pursuant to SC 2.3 and provided the Purchaser has used best
            endeavours all monies paid by or on behalf of the Purchaser will be repaid to the Purchaser.
          </li>
          <li>In the event of termination of this Contract pursuant to SC 2.3 in circumstances where the Purchaser has
            failed to use best endeavours the Vendor will be entitled to the Deposit which is forfeited and to proceed
            against the Purchaser for damages for breach of Contract.
          </li>
        </ol>
      </ol>
    </>
  },
  disclosureOfBenefits: {
    title: 'Disclosure of Benefits',
    body: <ul>
      <li>Agents are required to disclose certain benefits (including rebates, discounts or refunds) they may receive
          from third parties, that have been received in connection with a property transaction.</li>
      <li>Agents must disclose the following information:<br /><br />
      (a)	the nature and source of the benefit; and <br />
        (b)	the amount (or estimated value) of any benefit received.</li>
      <li>Benefits can be disclosed by way of attaching a ‘Form R2’ to a Sales Agency Agreement or completing the relevant sections in
        Item 7 of the Sales Agency Agreement.</li>
    </ul>
  },
  distributionDelay: {
    title: 'Distribution of legal documents to signing parties',
    body: <>
      <p>You are legally required to give a signer a copy of any Offer, Contract, or Agreement that they sign, <span className='fst-italic'>immediately upon that party signing.</span></p>
      <p>Where they sign electronically - via an email or SMS link, or on the screen of a Greatforms user - Greatforms will immediately send them a copy of the document they electronically signed, if Greatforms has an email address or a mobile phone number recorded for that party.</p>
      <p>If the party has no email or mobile phone number recorded, or if the party has signed on paper, it is your obligation to provide a copy of the document they signed by some other means.</p>
      <h5>Delaying distribution of a fully signed document</h5>
      <p>You must also communicate to all parties once a document is fully signed by all parties.</p>
      <p>Greatforms allows automatic distribution of your fully signed documents by Greatforms to all parties (where Greatforms has an email address or a mobile phone number recorded for all parties).</p>
      <p>Greatforms also allows for a delayed distribution of a fully signed document to all parties, for example, if a Vendor signs a Contract of Sale, it can be nice to communicate this to a Purchaser personally, rather than have them find out when the fully signed agreement is emailed to them.</p>

      <p>In cases like this, you can uncheck the "Instantly distribute..." option in the Signing Options. When unchecked Greatforms:
        <ul>
          <li>will still immediately distribute the fully signed document to the last party to sign</li>
          <li>will pause the distribution of the fully signed document to all of the prior signers</li>
          <li>will provide actions for you to manually trigger the distribution to those prior signers</li>
          <li>will automatically trigger distribution to those prior signers between 11pm and midnight (Adelaide time) if not manually distributed earlier, to ensure there is certainty about the date a document was fully signed and communicated to all parties.</li>
        </ul>
      </p>
    </>
  },
  nbnntdinclusion: {
    title: 'Identification of the NBN Box',
    body: <>
      <p>The NBN Network Termination Device (NBN Box) as provided by NBN Co. is required to remain with the property. The NBN box is keyed to the property address and will not work elsewhere. The power supply and cables for the NBN Box are also considered part of the NBN Box.</p>
      <p>Not all properties will have an NBN Box. Some properties are not serviced by the NBN, either because they are not yet covered, or are part of a development in which a qualifying broadband type was privately installed. </p>
      <p>
        Here are some clues you can use to identify an NBN Box:
        <ul>
          <li>An NBN box should have NBN Co. branding.</li>
          <li>Fibre-to-the-premises NBN Boxes are often secured to the wall and have a case surrounding the actual device. These should have the NBN Co. Logo and are NBN Boxes</li>
          <li>Hybrid-fibre-coaxial NBN Boxes will connect to what may have previously been used for Pay TV, eg Foxtel. These should have the NBN Co. Logo and are NBN Boxes.</li>
          <li>Devices may be provided instead by the Internet Service Provider (ISP), for example, Telstra, Optus or others. These are not NBN Boxes, and return may have to be organised with that ISP instead. Please ask them.</li>
          <li>Devices connected with the older style telephone ports that already existed at the property. These are sometimes branded with the ISP as above, but this is not always the case. These are not NBN Co. boxes unless otherwise branded.</li>
          <li>Devices which connect to another device within a room, such as the NBN Box, are not provided by the NBN. These are often what provides the "WiFi". These are not NBN Co. boxes unless otherwise branded.</li>
          <li>Devices purchased from suppliers other than NBN are not generally NBN Boxes.</li>
          <li>Please refer to other information sources for Fixed Wireless and Fixed Satellite NBN services.</li>
        </ul>
      </p>
      <p>NBN Co. also provides guidance around moving with an NBN Box. Please see the <a target="_blank" href='https://www.nbnco.com.au/residential/getting-connected/preparing-for-the-nbn#installation-equipment'>NBN Co.</a> website for more details.</p>
      <p>Greatforms does not advise directly on the identification of the NBN Box, this is only a guide.</p>
    </>
  }
};
