import {
  ContentType,
  FormCode,
  FormCodeUnion,
  FormFamilyState,
  FormInstance,
  FormOrderState,
  FormOrderType,
  FormSigningState, Maybe,
  OfferMeta,
  SigningPartyType
} from '@property-folders/contract';
import { FormCardRenderOpts, FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { determineFormState, FormState } from '@property-folders/common/yjs-schema/property';
import { Alert, Button, Card, Collapse, Dropdown } from 'react-bootstrap';
import { Icon } from './Icon';
import React, { useMemo, useRef, useState } from 'react';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Predicate } from '@property-folders/common/predicate';
import { localDate } from '@property-folders/common/util/formatting';
import { missingWetPdfWarning } from '../display/properties/MissingWetPdfWarning';
import { useLightweightTransaction } from '../hooks/useTransactionField';
import { ExtraFormCode, PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { useReactRouterData } from '../hooks/useReactRouterHooks';
import { RouterData } from '@property-folders/web/src/App';
import { ShowIfVariationRequired } from '../display/form/ShowIfVariationRequired';
import { clickNoBubble } from '@property-folders/common/util/clickNoBubble';
import { SpinnerButton } from './AsyncButton';
import { processPdf } from '@property-folders/common/util/pdf/pdf-process';
import { FormWarningDialog, ShowWarnParams } from './Wizard/FormWarningDialog';
import { FormInstanceSigningProgressBar } from './FormInstanceSigningProgressBar';
import { FormInstanceInfoSnippets } from './FormInstanceInfoSnippets';
import { useQpdfWorker } from '../hooks/useQpdfWorker';
import { useCurrentEntity } from '../hooks/useEntity';

export function RequiresFormText ({ formCode }: {formCode: FormCodeUnion}) {
  return <div className='mt-1 text-center fst-italic fs-6 mx-3'><div>Requires Signed {FormTypes[formCode].label}</div></div>;
}

export const FormInstanceCard = ({ failedFormCodeList, instance, fCode, onOpen, onCreate, onOrder, onSimRelease, onViewOrder, onServe, onDelete, onCreateAnother, onUpload, cardClass, onOpenRelated, mini, openText, renderOpts, formFamilyState, notActionable = false, children, forceFocus, variationRecommendationEnabled, cardNotClickable, warnOnActions }:{
  onOpen?: ()=>void,
  onCreate?: (opts: {verbosity?: string})=>void,
  onOrder?: { (): Promise<void>, disable?: boolean },
  onSimRelease?: () => void,
  onViewOrder?: () => void,
  onServe?: () => void,
  onAdd?: ()=>void,
  onDelete?: ()=>void
  onOpenRelated?: ()=>void,
  onCreateAnother?: () => void,
  onUpload?: (data: Uint8Array, contentType: string) => Promise<void>,
  mini?: boolean,
  instance?: FormInstance,
  formFamilyState?: FormFamilyState, // Presence indicates desire to summarise all signing dates
  fCode: string,
  cardClass?: string,
  openText?: string,
  renderOpts?: FormCardRenderOpts,
  notActionable?: boolean,
  warnOnActions?: {
    open?: boolean,
    order?: boolean,
    upload?: boolean,
    title: string,
    message: string
  },
  failedFormCodeList?: FormCodeUnion[]
  children?: JSX.Element
  forceFocus?: boolean,
  variationRecommendationEnabled?: boolean // Must be a static value
  cardNotClickable?: boolean
}) => {
  const noHover = notActionable || renderOpts?.notYetImplemented;

  const formFamily = FormTypes[fCode]?.formFamily;
  const familyType = FormTypes[formFamily];
  const { shortSAA } = useCurrentEntity() ??{};
  const variants = FormTypes[fCode].verbosityModes?.filter(v=>{
    if (formFamily !== FormCode.RSAA_SalesAgencyAgreement) return true;
    if (shortSAA) return true;
    if (v.mode !== 'short') return true;
    return false;
  });

  const relatedForms = useMemo(()=>Object.values(FormTypes).filter(f=>f.formFamily === formFamily), [formFamily]);
  const { value: offerManagement } = useLightweightTransaction<OfferMeta>({ ydocForceKey: PropertyRootKey.Meta, myPath: 'offerManagement' });
  const { transId: propertyId } = useReactRouterData<RouterData>();
  const [processingAction, setProcessingAction] = useState<
    '' | 'uploading' | 'ordering' | 'opening'
  >('');
  const disableActions = Boolean(processingAction);
  const [showWarn, setShowWarn] = useState<Maybe<ShowWarnParams>>(undefined);
  const qpdfWorker = useQpdfWorker();

  const title =  FormTypes[instance ? instance.formCode : fCode]?.label;
  const debug = FormTypes[fCode]?.debug;
  const formState = instance
    ? determineFormState(instance)
    : FormState.NOT_ADDED;
  const relatedFormsCanExist = formFamily === FormCode.Form1
    ? false // The Form1 lineage has been used in an unexpected way, to essentially have 2 different forms be the primary. This is confusing the condition check below, which is expecting something that cannot be succeeded, to be able to be succeeded. I don't know what the side effects are of changing the form family, so I'm not going to. We may need to rethink the subscription form definition rules or something. One possibility is actually just filtering out any subscription form from the condition used to check, as subscription forms are standalone, but in general, we need to check out what other things reference the FormType list for a family, and determine the effects.
    : instance?.order
      ? false
    // Having only one related form means there's only a primary form, so nothing is related
      : new Set([FormState.SIGNED, FormState.EXPIRED]).has(formState) && relatedForms.length > 1;

  const uploadInput = useRef<HTMLInputElement | null>(null);
  const triggerUploadInput = onOrder ? () => {
    if (!uploadInput?.current) return;
    uploadInput.current.click();
  } : undefined;

  const handleOpen = setupWarnIntercept(
    onOpen ? () => {
      setProcessingAction('opening');
      onOpen();
      setProcessingAction('');
    } : undefined,
    warnOnActions?.open ? (inner) => {
      setShowWarn({
        formCode: fCode as FormCodeUnion,
        title: warnOnActions.title,
        message: warnOnActions.message,
        actionName: 'create',
        continueFn: () => {
          setShowWarn(undefined);
          inner();
        }
      });
    } : undefined);
  const handleCreateFactory = (variant?: string) => setupWarnIntercept(
    onCreate ? () => {
      setProcessingAction('opening');
      onCreate({ verbosity: variant });
      setProcessingAction('');
    } : undefined,
    warnOnActions?.open ? (inner) => {
      setShowWarn({
        formCode: fCode as FormCodeUnion,
        title: warnOnActions.title,
        message: warnOnActions.message,
        actionName: 'create',
        continueFn: () => {
          setShowWarn(undefined);
          inner();
        }
      });
    } : undefined);
  const handleOrder = setupWarnIntercept(
    onOrder ? () => {
      setProcessingAction('ordering');
      onOrder().finally(() => setProcessingAction(''));
    } : undefined,
    warnOnActions?.order ? (inner) => {
      setShowWarn({
        formCode: fCode as FormCodeUnion,
        title: warnOnActions.title,
        message: warnOnActions.message,
        actionName: 'order',
        continueFn: () => {
          setShowWarn(undefined);
          inner();
        }
      });
    } : undefined);
  const handleUpload = setupWarnIntercept(
    triggerUploadInput,
    warnOnActions?.order ? (inner) => {
      setShowWarn({
        formCode: fCode as FormCodeUnion,
        title: warnOnActions.title,
        message: warnOnActions.message,
        actionName: 'upload',
        continueFn: () => {
          setShowWarn(undefined);
          inner();
        }
      });
    } : undefined);

  // if a form can be served then that should be the primary action, move everything else to the dropdown
  const serveDropdownItems = [
    ...(formState === FormState.NOT_ADDED && variants?.length && handleCreateFactory()
      ? variants.map(({ label, mode })=><Dropdown.Item
        variant="outline-secondary"
        onClick={clickNoBubble(handleCreateFactory(mode))}
        title={'Add form to folder'}
        disabled={noHover}
      >
        Create {label}
      </Dropdown.Item>)
      : formState === FormState.NOT_ADDED && handleOpen
        ? [<Dropdown.Item
          variant="outline-secondary"
          onClick={clickNoBubble(handleOpen)}
          title={'Add form to folder'}
          disabled={noHover}
        >
          Create
        </Dropdown.Item>]
        : formState !== FormState.NOT_ADDED && handleOpen
          ? [<Dropdown.Item
            variant="outline-secondary"
            onClick={clickNoBubble(handleOpen)}
            title={'View/Edit form'}
            disabled={noHover}
          >
            {(openText || 'Open')}
          </Dropdown.Item>]
          : []),

    relatedFormsCanExist && onOpenRelated
      ? <Dropdown.Item
        onClick={onOpenRelated}
        title='This is a family of documents which may include continuations or addendums to the original form'
      >Related</Dropdown.Item>
      : undefined,

    onCreateAnother
      ? <Dropdown.Item
        variant='outline-secondary'
        onClick={onCreateAnother}
      >Create</Dropdown.Item>
      : undefined,

    instance && onDelete && debug
      ? <Dropdown.Item variant='outline-danger' onClick={clickNoBubble(onDelete)}>Delete</Dropdown.Item>
      : undefined
  ].filter(Predicate.isNotNull);

  const handleCardClick = () => {
    if (disableActions || instance?.external || cardNotClickable) return;
    if (formState === FormState.ORDER_PREPARING && onViewOrder) {
      return onViewOrder();
    }
    if (relatedFormsCanExist && onOpenRelated && (formFamilyState?.instances?.length??0) > 1) {
      return onOpenRelated();
    }
    if (!noHover && onOrder && !onOrder.disable) {
      setProcessingAction('ordering');
      return onOrder();
    }
    if (!noHover && onOpen) {
      setProcessingAction('opening');
      return onOpen();
    }
  };

  const rsaaRequired = !renderOpts?.notYetImplemented && failedFormCodeList?.includes(FormCode.RSAA_SalesAgencyAgreement);

  const checkVariations = variationRecommendationEnabled && familyType.recommendVariation && familyType.commonWatchPaths && Object.values(FormTypes).some(ft=>ft.isVariation && ft.formFamily===formFamily);

  const [variationOpen, setVariationOpen] = useState(false);

  const variationWatchPaths = useMemo(()=>{
    if (!familyType.commonWatchPaths) return [];
    return Object.values(familyType.commonWatchPaths).flat();
  }, [familyType.commonWatchPaths]);

  return <Card
    key={fCode}
    className={clsJn(
      'border-0 shadow',
      cardClass,
      mini && 'mini',
      (instance || !noHover) && !instance?.external && !cardNotClickable && 'clickable',
      !((instance || !noHover) && !instance?.external) && !cardNotClickable && 'disabled',
      !noHover && 'hoverable',
      renderOpts?.processNotForm && 'process',
      forceFocus ? 'hoverable-force' : 'hoverable-force-off'
    )}
  >
    {!!onUpload && <input
      ref={uploadInput} className='d-none' type='file' accept='.pdf,application/pdf'
      onChange={async event => {
        if (!event.target.files?.length) return;
        const file = event.target.files[0];
        if (!file) return;
        setProcessingAction('uploading');
        try {
          const decryptedPdf = await qpdfWorker.decrypt({ pdf: await file.arrayBuffer() });
          const pdfFile = await processPdf(decryptedPdf);
          if (!pdfFile?.pdf || pdfFile.isEncrypted) return;
          await onUpload(pdfFile.pdf, ContentType.Pdf);
        } finally {
          setProcessingAction('');
        }
      }}
    />}

    <Card.Header onClick={handleCardClick}>
      <div className={clsJn('thick-title')}>
        {title}{renderOpts?.iconAfter && <Icon name={renderOpts.iconAfter}  icoClass='ms-2'/>}
      </div>
    </Card.Header>
    <Card.Body className='d-flex flex-column' onClick={handleCardClick}>

      {renderOpts?.notYetImplemented && <div className='fs-4 text-center mt-3'>Coming Soon</div>}
      {rsaaRequired && <RequiresFormText formCode={FormCode.RSAA_SalesAgencyAgreement} />}
      {!rsaaRequired && (children ? children :
        <div className='pseudoform-body d-flex flex-column justify-content-between' style={{ flex: '1 1 auto' }}>
          <div>
            <div className='mb-2'>
              {(() => {
                if (!instance) {
                  return <div className='status create-ops' style={{ width: 'max-content', marginInline: 'auto' }}>
                    {handleOrder && <SpinnerButton
                      variant='tertiary'
                      className='border-0 d-flex flex-row justify-content-between gap-2'
                      disabled={disableActions || noHover || onOrder?.disable}
                      onClick={clickNoBubble(handleOrder)}
                      processing={processingAction === 'ordering'}
                    >
                      <Icon name='add_shopping_cart'/><span>Order</span>
                    </SpinnerButton>}
                    {onUpload && <SpinnerButton
                      variant='tertiary'
                      className='border-0 d-flex flex-row justify-content-between gap-2'
                      disabled={disableActions || noHover}
                      onClick={clickNoBubble(handleUpload)}
                      processing={processingAction === 'uploading'}
                    >
                      <Icon name='upload' pack='material-symbols' /><span>Upload</span>
                    </SpinnerButton>}

                    {!renderOpts?.notYetImplemented && renderOpts?.suggestionActions?.includes('create') && (
                      formState === FormState.NOT_ADDED && variants?.length && handleCreateFactory()
                        ? variants.map(({ label, mode }) => <SpinnerButton
                          variant='tertiary'
                          className='border-0 d-flex flex-row gap-2'
                          noJustify={true}
                          disabled={disableActions || noHover}
                          onClick={clickNoBubble(handleCreateFactory(mode))}
                          processing={processingAction === 'opening'}
                        >
                          <Icon name='add'/><span>Create{variants?.length>1?' '+label:''}</span>
                        </SpinnerButton>)
                        : handleOpen && <SpinnerButton
                          variant='tertiary'
                          className='border-0 d-flex flex-row justify-content-between gap-2'
                          disabled={disableActions || noHover}
                          onClick={clickNoBubble(handleOpen)}
                          processing={processingAction === 'opening'}
                        >
                          <Icon name='add'/><span>Create</span>
                        </SpinnerButton>)
                    }
                  </div>;
                }

                return <FormInstanceSigningProgressBar instance={instance} />;
              })()}
            </div>
            <div className='psuedoform-content mb-2'>
              <FormInstanceInfoSnippets propertyId={propertyId} instance={instance} familyState={formFamilyState} offerManagement={offerManagement} />
            </div>
            {formState === FormState.SIGNED && (() => {
              const instanceSigningOrder = (formFamilyState?.instances || [])
                .map(inst=>({
                  ...inst,
                  sortTime: inst?.signing?.session?.completedTime ?? inst?.modified ?? Date.now()
                }))
                .sort((a,b) => b.sortTime - a.sortTime);

              function generateDocSummarySection(instList: typeof instanceSigningOrder, title: string) {
                return <>
                  <div className='fw-bold mt-3'>{title}</div>
                  {instList.map((inst, idx) => {
                    const isMissingPdf = inst?.signing?.parties?.some(p => p.type === SigningPartyType.SignWet && p?.signedTimestamp && !p.signedPdf);
                    let stateStr = 'Draft';
                    switch (inst?.signing?.state) {
                      case FormSigningState.Configuring:
                        stateStr = 'Configuring';
                        break;
                      case FormSigningState.OutForSigning:
                      case FormSigningState.OutForSigningPendingServerProcessing:
                      case FormSigningState.OutForSigningPendingUpload:
                        stateStr = 'Signing';
                        break;
                      case FormSigningState.Signed:
                      case FormSigningState.SignedPendingDistribution:
                      case FormSigningState.SignedPendingUpload:
                        stateStr = 'Signed';
                        break;
                    }

                    return <div key={`${idx}:${inst.id}`} className='d-flex'>
                      <div title={isMissingPdf ? missingWetPdfWarning : ''} className={clsJn('d-flex align-items-center', isMissingPdf && 'warning-text')}>{stateStr}</div>
                      <div className="ms-auto">{localDate(inst.sortTime) ?? '-'}</div>
                    </div>;
                  })}
                </>;
              }

              const variationOrder = instanceSigningOrder.filter(inst=> FormTypes[inst.formCode]?.isVariation);
              const subsequentOrder = instanceSigningOrder.filter(inst=> inst.formCode === ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent);

              return <>
                {!!variationOrder.length && generateDocSummarySection(variationOrder, 'Variations')}
                {!!subsequentOrder.length && generateDocSummarySection(subsequentOrder, 'Subsequents')}
              </>;
            })()}
          </div>
          {instance?.order?.filler?.system === 'EPF' && <img
            src={LinkBuilder.propertiesImages('epf_logo.png')}
            className='w-100' />}
        </div>
      )}
    </Card.Body>
    {checkVariations && <ShowIfVariationRequired formCode={familyType.formFamily} pathWatchList={variationWatchPaths}>
      {({ createAction, pickNavigate, targetFormCode })=>{
        if (!pickNavigate) {
          return <Alert variant='secondary' className='m-0 px-4' style={{ borderBottom: 0 }} onClick={e => {e.stopPropagation(); setVariationOpen(!variationOpen);}}>
            <div className='w-100 d-flex'><strong>{targetFormCode === ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent ? 'Subsequent recommended' : 'Variation recommended'}</strong><Icon name={variationOpen?'expand_more':'expand_less'} icoClass='ms-auto'/></div>
            <Collapse in={variationOpen}>
              <div id="example-collapse-text" className='mt-2'>
                {
                  targetFormCode === ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent
                    ? <div>
                      reaforms has detected that the Agency Agreement is approaching expiry with no
                      Contract signed. We recommend creating a Subsequent Agency Agreement to continue
                      your authority as Agent.
                    </div>
                    :<div>
                      reaforms has detected that there are changes that affect this
                      document. We recommend that a variation is created and signed
                      by the parties.
                    </div>
                }
                <div className='text-center mt-2'><Button onClick={createAction} variant='outline-secondary' className='mt-2'>Create {targetFormCode === ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent ? 'Subsequent' : 'Variation'}</Button></div>
              </div>

            </Collapse>
          </Alert>;
        }
        return undefined;
      }}
    </ShowIfVariationRequired> }
    <Card.Footer className='d-flex justify-content-end'>
      <div className='footer-buttons d-flex'>
        {(() => {
          if (instance?.order?.type === FormOrderType.Agent && [FormOrderState.ThirdPartyPreparing, FormOrderState.ReturnedToThirdParty].includes(instance?.order?.state || FormOrderState.None) && onViewOrder) {
            return <>
              {debug && onSimRelease && <Button
                variant='outline-danger'
                onClick={clickNoBubble(onSimRelease)}
              >Sim Release</Button>}
              <Button
                variant='outline-secondary'
                onClick={clickNoBubble(onViewOrder)}
                title='View Order'
              >
                View Order
              </Button></>;
          }

          return <>
            {renderOpts?.suggestionActions?.includes('view') && formState !== FormState.NOT_ADDED && <Button disabled={true} variant="outline-secondary" className='text-center'>View</Button>}
            {onOrder && formState === FormState.NOT_ADDED && <SpinnerButton
              variant="outline-secondary"
              onClick={clickNoBubble(onOrder)}
              title={'Order form'}
              disabled={disableActions || noHover || onOrder.disable}
              processing={processingAction==='ordering'}
            >
              Order
            </SpinnerButton>}
            {onUpload && formState === FormState.NOT_ADDED && <SpinnerButton
              variant="outline-secondary"
              onClick={clickNoBubble(triggerUploadInput)}
              title={'Upload form'}
              disabled={noHover}
              processing={processingAction === 'uploading'}
            >
              Upload
            </SpinnerButton>}
            {renderOpts?.suggestionActions?.includes('request') && <Button disabled={true} variant="outline-secondary" className='text-center'>Request</Button>}

            {onServe && <Button
              variant='outline-secondary'
              onClick={clickNoBubble(onServe)}
              title='Serve to Purchaser'
            >
              Serve
            </Button>}

            {!onServe && handleOpen && !instance?.external && <SpinnerButton
              variant="outline-secondary"
              onClick={clickNoBubble(handleOpen)}
              title={formState === FormState.NOT_ADDED ? 'Add form to folder' : 'View/Edit form'}
              disabled={disableActions || noHover}
              processing={processingAction === 'opening'}
            >
              {formState === FormState.NOT_ADDED && !renderOpts?.processNotForm ? 'Create' : (openText || 'Open')}
            </SpinnerButton>}

            {!onServe && relatedFormsCanExist && onOpenRelated && <Button
              variant='outline-secondary'
              onClick={onOpenRelated}
              title='This is a family of documents which may include continuations or addendums to the original form'
            >
              Related
            </Button>}

            {!onServe && onCreateAnother && <Button
              variant='outline-secondary'
              onClick={onCreateAnother}
            >Create</Button>}

            {instance && onDelete && debug && !onServe && <Button variant='outline-danger' onClick={clickNoBubble(onDelete)}>Delete</Button>}

            {onServe && serveDropdownItems.length > 0 && (
              <Dropdown>
                <Dropdown.Toggle
                  variant={'outline-secondary'}
                  className='ms-2 no-dropdown-button'
                >...</Dropdown.Toggle>
                <Dropdown.Menu>
                  {serveDropdownItems}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>;
        })()}
      </div>

    </Card.Footer>
    {!!showWarn && <FormWarningDialog
      formCode={fCode as FormCodeUnion}
      title={showWarn.title}
      message={showWarn.message}
      actionName={showWarn.actionName}
      onCancel={() => setShowWarn(undefined)}
      onContinue={() => showWarn?.continueFn()}
    />}
  </Card>;
};

function setupWarnIntercept(baseFunc?: () => void, intercept?: (inner: () => void) => void) {
  if (!baseFunc) return undefined;
  if (intercept) return () => intercept(baseFunc);
  return baseFunc;
}
