import { FeatureFlags } from '@property-folders/contract';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useEffect, useState } from 'react';

const envFeatureFlags: FeatureFlags = {
  newPropertyFolders: undefined,
  pfRemoteCompletion: undefined,
  teamManagementVisible: undefined,
  myFiles: undefined,
  customNotifications: undefined,
  shortSAA: undefined,
  propertyFolderTemplates: undefined,
  propertyFolderTemplatesList: undefined
};

export function useFeatureFlags() {
  const { data } = AuthApi.useGetAgentSessionInfo();
  const [ flags, setFlags ] = useState<FeatureFlags>({});

  useEffect(() => {
    const newFlags: FeatureFlags = data?.featureFlags ?? {};
    for (const key of Object.keys(envFeatureFlags) as (keyof FeatureFlags)[]) {
      const env = (import.meta as {env?: any})?.env ?? {};
      if (env['VITE_FF_' + key] == 'true') {
        newFlags[key] = true;
      }
    }

    setFlags(newFlags);
  }, [data]);

  return flags;
}
