import { ReactNode, useState } from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { defaultTheme } from 'react-select';
import { GroupBase, OptionsOrGroups } from 'react-select/dist/declarations/src/types';

const { colors } = defaultTheme;

export interface Props<Option, Group extends GroupBase<Option>> {
  options: OptionsOrGroups<Option, Group>;
  label: string;
  onSelect: (option: Option) => void;
}

const SearchableDropdown = <T,>(props: Props<T, any>) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <Button
          variant={isOpen ? 'secondary' : 'outline-secondary'}
          className={'dropdown-toggle'}
          onClick={() => setIsOpen((prev) => !prev)}
        >{props.label}
        </Button>
      }
    >
      <Select
        autoFocus
        backspaceRemovesValue={false}
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        menuPlacement={'auto'}
        closeMenuOnSelect={true}
        isClearable={false}
        menuIsOpen
        onChange={val => {
          setIsOpen(false);
          props.onSelect?.(val as T);
        }}
        options={props.options}
        placeholder="Search..."
        styles={{
          menu: (base) => ({
            ...base,
            width: '240px',
            borderRadius: 0,
            margin: 0
          }),
          control: (provided) => ({
            ...provided,
            width: '240px',
            borderRadius: 0
          }),
          option: (provided) => ({
            ...provided,
            cursor: 'pointer',
            padding: '3px 5px'
          })
        }}
        tabSelectsValue={true}
      />
    </Dropdown>
  );
};

// styled components

const Menu = (props: JSX.IntrinsicElements['div']) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        border: '1px solid #ced4da',
        position: 'absolute',
        zIndex: 2,
        width: '240px',
        borderRadius: 0
      }}
      {...props}
    />
  );
};
const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1
    }}
    {...props}
  />
);

const Dropdown = ({ children, isOpen, target, onClose }: {
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}) => (
  <div style={{ position: 'relative', borderRadius: 0 }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p: JSX.IntrinsicElements['svg']) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

export default SearchableDropdown;
