import { IncAndEx, InclExclOptions, Inclusions, MaterialisedPropertyData } from '@property-folders/contract';
import { TextClickCheck } from '../../dragged-components/form/TextClickCheck';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { Button, Form, Table } from 'react-bootstrap';
import { CollectionRemoveButton } from '../../dragged-components/form/CollectionRemoveButton';
import './CombinedInclusionsExclusions.scss';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import clsJn from '@property-folders/common/util/classNameJoin';
import { useContext, useMemo, useRef, useState } from 'react';
import { FormUserInteractionContext } from '../../context/FormUserInteractionContext';
import { chunk, difference, intersection } from 'lodash';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { getHierarchyNode } from '@property-folders/common/util/pathHandling';
import { LineageContext } from '../../hooks/useVariation';
import { TooltipWhenRequired } from '../../dragged-components/TooltipWhenDisabled';
import { WrField } from '../../dragged-components/form/CommonComponentWrappers';
import { boolYesNoOpts, ChattelOptions, OPT_CCC } from '@property-folders/common/data-and-text/constants';
import { TwoIconToggleListItemPresent } from './TwoIconToggleListItemPresent';
import { useSize } from '../../hooks/useSize';
import { ShowGuidanceNotesButton } from '../../dragged-components/guidance/ShowGuidanceNotesButton';

export function OtherIncEx ({
  allowOthers,
  noColumns,
  otherTitle,
  otherAddText,
  options,
  tileWidth = 220,
  varied,
  customDuplicates,
  ...restProps
}: TransactionConsumerProps & {
  allowOthers?: boolean,
  noColumns?: boolean,
  otherTitle?: string,
  otherAddText?: string
  options: (string|{name:string, label:string})[],
  tileWidth?: number,
  varied?: string[],
  customDuplicates?: Set<string>
}) {
  const { value: selectedList, handleInsert, removeItem, handleAwarenessBlur, handleAwarenessFocus, readOnly } = useTransactionField<IncAndEx['simple']>(restProps);

  const optionNames = useMemo(()=>options.map(o=>typeof o === 'string' ? o : o.name), options);
  const nonOptions = useMemo(()=>difference(selectedList, optionNames),[selectedList, optionNames]);

  const othersModified = nonOptions.filter(option=>varied?.includes(option)).length > 0;

  const [custom, setCustom] = useState('');
  return (allowOthers && !readOnly && <div className='mt-2'>
    {otherTitle && <div><span className={clsJn('fs-5', othersModified && 'varied-control')}>{otherTitle}</span></div>}
    <div className={clsJn('my-2', !noColumns && 'd-flex flex-wrap')}>{nonOptions.map((text, idx) => {
      const isDuplicated = customDuplicates?.has(text);
      return <div key={text} className='d-flex align-items-center py-1 px-2 border border-light border-1' style={{ minWidth: `${tileWidth-2}px` }}>
        <span className={clsJn(varied && varied.includes(text) && 'varied-control')}>{text}</span>
        {isDuplicated && <span className='invalid-feedback-colour'>&nbsp;(duplicated)</span>}
        <div className='ms-auto'><CollectionRemoveButton onRemove={()=>removeItem(text)} removable={true} key={text} /></div>
      </div>;
    })}</div>
    <div className='d-flex' style={{ width: '300px' }}>
      <Form.Control
        className='flex-grow-1 flex-shrink-1'
        value={custom}
        onChange={e=>setCustom(e.target.value)}
        onFocus={handleAwarenessFocus}
        onBlur={handleAwarenessBlur}
      ></Form.Control>
      <Button
        title='Add other entries that are not in the list above'
        className='flex-grow-0 flex-shrink-0'
        variant='light'
        onClick={()=>{custom && handleInsert(custom);setCustom('');}}
      >
        {otherAddText}
      </Button>
    </div>
  </div> || <></>);
}

export function CombinedInclusionsExclusions ({ options, requiredTipText, ...props }: {
  options: {name: string, label: string, noExclude?: boolean, guidanceNote?: string}[],
  requiredTipText?: string,
  purchaserMode?: boolean;
  contractMode?: boolean
}) {
  // Due to the new combined nature of manipulating 2 lists, we absolutely can't use useTransactionField
  // So we'll need to use binder, but we also need to check there aren't already group functions or something
  // in use which would still need to be hooked up. Perhaps a new hook need be developed that acts similar
  // to useTransactionField, but is resident of multiple places on the data model. I guess we'll see. We
  // should probably mark somewhere that we can't expect group functions to work on these or something
  // though.

  // In particular, we'll be updating 2 locations in the data model at once that aren't adjacent eachother, so we're
  // going to need the root.
  const { updateDraft: updateRoot } = useYdocBinder<MaterialisedPropertyData>({ path: '' });
  const { value: inclusions, fullPath: inclusionsPath } = useLightweightTransaction<Inclusions>({ parentPath: 'chattels' });
  const { value: inclusionListRaw, fullPath: inclusionPath, hasVaried: hasVariedInc, required: requiredInc } = useTransactionField<IncAndEx['simple']>({ parentPath: 'chattels', myPath: 'simple' });
  const { value: exclusionListRaw, fullPath: exclusionPath, hasVaried: hasVariedEx, required: requiredEx } = useTransactionField<IncAndEx['simple']>({ parentPath: 'exclusions', myPath: 'simple' });
  const { value: hideList, fullPath: hideListPath, hasVaried: hasVariedHide } = useTransactionField<InclExclOptions['hideList']>({ myPath: 'inclExclOpts.hideList' });
  const inclusionList = props.contractMode ? inclusionListRaw?.filter(i=>i!==ChattelOptions.CompliantSmokeAlarm) : inclusionListRaw;
  const exclusionList = props.contractMode ? exclusionListRaw?.filter(i=>i!==ChattelOptions.CompliantSmokeAlarm) : exclusionListRaw;
  const hasVaried = hasVariedInc || hasVariedEx || hasVariedHide;
  const required = requiredInc || requiredEx;
  const errorFocusTarget = composeErrorPathClassName(inclusionPath, '');
  const { userShouldSeeAllValidation } = useContext(FormUserInteractionContext);

  const { changeSet: variationChangeSet } = useContext(LineageContext);
  const containerDivRef = useRef<HTMLDivElement>(null);
  const { width } = useSize(containerDivRef, 100);
  const numberOfSplits = Math.floor(width / 375);

  const varied = useMemo(()=>{
    return [
      ...Object.keys(getHierarchyNode(inclusionPath, variationChangeSet?.added)??{}),
      ...Object.keys(getHierarchyNode(inclusionPath, variationChangeSet?.removed)??{}),
      ...Object.keys(getHierarchyNode(exclusionPath, variationChangeSet?.added)??{}),
      ...Object.keys(getHierarchyNode(exclusionPath, variationChangeSet?.removed)??{}),
      ...Object.keys(getHierarchyNode(hideListPath, variationChangeSet?.added)??{}),
      ...Object.keys(getHierarchyNode(hideListPath, variationChangeSet?.removed)??{})
    ];
  },
  [hasVaried, variationChangeSet]);
  const { doubleMatches, customDoubleMatches } = useMemo(()=>{
    if (!userShouldSeeAllValidation) return {};
    if (!(Array.isArray(inclusionList) && Array.isArray(exclusionList))) return {};
    const baseIntersection = intersection(inclusionList, exclusionList);

    return {
      doubleMatches: new Set(intersection(baseIntersection, options.map(o=>o.name))),
      customDoubleMatches: new Set(difference(baseIntersection, options.map(o=>o.name)))
    };
  }, [userShouldSeeAllValidation, inclusionList?.join(''), exclusionList?.join('')]);

  // Remembering that the list can also contain all the custom entries, so we need to start with the list of
  // fixed inclusions/exclusions and develop from there. Validation will likely require this list, and any matching
  // entries which are manually entered we'll just ignore

  const handleNoneApplicable: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    if (evt.target.checked === false) {
      // Not applicable was true, but is being cleared
      updateRoot?.(draft => {
        if (draft.chattels) delete draft.chattels.simple;
        if (draft.exclusions) delete draft.exclusions.simple;
      });
      return;
    }
    if (evt.target.checked === true) {
      updateRoot?.(draft => {
        if (!draft.chattels) draft.chattels = {};
        if (!draft.chattels.simple) draft.chattels.simple = [];
        const dcs = draft.chattels.simple;
        draft.chattels.simple.splice(0,dcs.length);

        if (!draft.exclusions) draft.exclusions = {};
        if (!draft.exclusions.simple) draft.exclusions.simple = [];
        const des = draft.exclusions.simple;
        draft.exclusions.simple.splice(0,des.length);
      });
    }
  };

  const emptyFields = !Array.isArray(inclusionList) && !Array.isArray(inclusionList);
  const noneApplicable = !(emptyFields || (Array.isArray(exclusionList) && exclusionList.length > 0) || (Array.isArray(inclusionList) && inclusionList.length > 0));
  const handleCheck = (mode: 'inc'|'ex', valueKey: string): React.ChangeEventHandler<HTMLInputElement> => (evt) => {
    const checked = evt.target.checked;
    updateRoot?.(draft=>{
      const targetNode = mode==='inc'?'chattels':mode==='ex'?'exclusions':'';
      if (!targetNode) return;
      const otherNode = targetNode==='chattels'?'exclusions':'chattels';

      if (!draft[targetNode]) draft[targetNode] = {};
      if (!draft[targetNode].simple) draft[targetNode].simple = [];
      const targetList = draft[targetNode].simple;

      if (!draft[otherNode]) draft[otherNode] = {};
      if (!draft[otherNode].simple) draft[otherNode].simple = [];
      const otherList = draft[otherNode].simple;

      const hideList = draft.inclExclOpts?.hideList;
      if (Array.isArray(hideList) && hideList.includes(valueKey)) {
        hideList.splice(hideList.findIndex(s=>s===valueKey),1);
      }

      if (checked && !targetList.includes(valueKey)) {
        targetList.push(valueKey);
        const otherIndex = otherList.findIndex(li=>li === valueKey);
        if (otherIndex !== -1) otherList.splice(otherIndex,1);
        return;
      }

      const existingIndex = targetList.findIndex(li=>li===valueKey);
      if (checked === false && existingIndex !== -1) {
        targetList.splice(existingIndex,1);
        return;
      }
    });

  };

  const rowRender = (value: string) => {
    const selectedOpt = options.find(o=>o.name === value);

    const duplicated = doubleMatches?.has(value);
    const hasVaried = varied.includes(value);

    const disable = hideList?.includes(value);

    return <tr>
      <td><span className={clsJn(hasVaried&&'varied-control', 'p-0 description-cell', disable && 'opacity-50')}>
        {selectedOpt?.label}{selectedOpt?.guidanceNote && <ShowGuidanceNotesButton noteId={selectedOpt?.guidanceNote} />}
      </span>{duplicated && <><div className='anywhere-invalid-feedback p-0'>Cannot be both included and excluded</div></>}</td>
      <td className='check-cell'><TextClickCheck
        isInvalid={duplicated}
        onSelected={handleCheck('inc', selectedOpt?.name)}
        checked={disable ? false : !!inclusionList?.includes(value)}
      /></td>
      <td className='check-cell'>{!selectedOpt?.noExclude && <TextClickCheck
        isInvalid={duplicated}
        onSelected={handleCheck('ex', selectedOpt?.name)}
        checked={disable ? false : !!exclusionList?.includes(value)}
      />}</td>
      <td><TwoIconToggleListItemPresent myPath={hideListPath} watchString={value} absentIcon='visibility' presentIcon='visibility_off'/></td>
    </tr>;
  };

  // So there was at some point a comment in pdfgen/contants.ts saying Compliant Smoke alarm
  // is removed from the list, however, because it is in prefill residential.ts, it appears
  // contracts as other, which is a bit weird. I don't know what ticket it was from, but I guess
  // this should be properly implemented
  const maskedSmokeAlarmOptions = props.contractMode ? [...options, { name: ChattelOptions.CompliantSmokeAlarm, label: ChattelOptions.CompliantSmokeAlarm }] : options;

  const rowCount = Math.ceil(options.length/numberOfSplits);
  const majorCols = chunk(options, rowCount);

  return <div ref={containerDivRef} className={clsJn('subsection scrollspy-target', 'mb-3')} data-focus-path="subsection-IncEx">
    <TooltipWhenRequired title={requiredTipText??''} required={required}>
      <div className='fs-4 mb-3'>Inclusions and Exclusions{required ? <sup className='fs-5' style={{ color: 'red' }}> *</sup> : null}</div>
    </TooltipWhenRequired>
    <TextClickCheck
      checked={noneApplicable}
      onSelected={handleNoneApplicable}
      label='None applicable'
    />
    <div className='incex-table-columns'>
      {majorCols.map(colSet => {
        return <div>
          <Table className={clsJn('IncEx-Table', errorFocusTarget)}>
            <tr><th></th><th className='px-1 header-cell'>Inclusion</th><th className='px-1 header-cell'>Exclusion</th><th></th></tr>
            {colSet.map(o=>rowRender(o.name))}
          </Table>
        </div>;
      })}
    </div>

    <OtherIncEx
      options={maskedSmokeAlarmOptions}
      allowOthers={true}
      otherAddText='Add Inclusion'
      otherTitle='Other Inclusions'
      parentPath='chattels'
      myPath='simple'
      varied={varied}
      customDuplicates={customDoubleMatches}
    />
    <OtherIncEx
      options={maskedSmokeAlarmOptions}
      allowOthers={true}
      otherAddText='Add Exclusion'
      otherTitle='Other Exclusions'
      parentPath='exclusions'
      myPath='simple'
      varied={varied}
      customDuplicates={customDoubleMatches}
    />
    {!props.contractMode && !props.purchaserMode && <div>
      <div className='mt-2 me-2'>
        <WrField.CheckRadio
          radioType='checkbox'
          label='Are there any consumer credit chattels?'
          name='cccEnable'
          myPath='cccEnable'
          parentPath={inclusionsPath}
          valueType='boolean'
          options={boolYesNoOpts}
          titleGuidanceNoteKey='consumerCreditChattels'
        />

      </div>

      {inclusions?.cccEnable &&<div className='mt-2 flex-grow-1' style={{ width: '300px' }} >
        <div className='flex-grow-1'><WrField.Control label={'Details of '+OPT_CCC} parentPath={inclusionsPath} myPath='cccDetail' name='cccDetail'/></div>
      </div>}
    </div>}
  </div>;

}