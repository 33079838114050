import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { boolYesNoOpts, poolComplianceStateOpts } from '@property-folders/common/data-and-text/constants';
import { WrField } from '../CommonComponentWrappers';
import { PoolCompliance as PoolComplianceType, PoolComplyState } from '@property-folders/contract';
import { ShowGuidanceNotesButton } from '@property-folders/components/dragged-components/guidance/ShowGuidanceNotesButton';

export const PoolCompliance = ({ contractMode = false, saaMode }:{contractMode?: boolean, saaMode?: boolean}) => {
  const { value: pool } = useLightweightTransaction<PoolComplianceType>({ myPath: 'pool' });
  return <div className='subsection scrollspy-target' data-focus-path="subsection-pool">
    <div className='fs-4 d-flex align-items-center'>Pool compliance <ShowGuidanceNotesButton noteId={'poolCompliance'} /></div>

    <div className='mt-2'>
      <WrField.CheckRadio
        label='Is there a swimming pool (which includes a spa pool)?'
        radioType='checkbox'
        options={boolYesNoOpts}
        valueType='boolean'
        inline={true}
        name='poolPresent'
        myPath='pool.present'
      />

    </div>

    {pool?.present && !saaMode && !contractMode && <div className='mt-2'>
      <WrField.CheckRadio
        label='Is the pool/spa compliant?'
        radioType='checkbox'
        options={poolComplianceStateOpts}
        valueType='int'
        name='poolComply'
        myPath='pool.complyState'
        inline={false}
      />
    </div>
    }

    {pool?.present && !saaMode && pool?.complyState === PoolComplyState.nonComply && <div className='mt-2'>
      <div className=''>Detail of works required</div>
      <WrField.Control textArea={{ rows: 3 }} name='poolWorks' myPath='pool.nonComplyWorks' label='Required works' maxLength={1000} />
    </div>}
  </div>;
};
