import { useEffect, useMemo, useState } from 'react';
import { Col, Row, Image, Container, DropdownButton, Dropdown } from 'react-bootstrap';
import { renderText } from '@property-folders/common/util/image/render-text';
import { PaddingConfig } from '@property-folders/common/util/image/padding-config';
import { SigningStateEntryMode } from '../SigningState';

interface SelectSystemGeneratedSignatureProps {
  fullName?: string;
  initials?: string;
  onImagesChanged: (signatureImage: string | undefined, initialsImage: string | undefined) => void;
  mode: SigningStateEntryMode;
}

const defaultPadding = 25;
const defaultPaddingConfig: PaddingConfig = { top: defaultPadding, left: 5, bottom: defaultPadding - 20, right: defaultPadding };
function defaultPaddingAdjustForFont(fontSize: number, fraction: number): PaddingConfig {
  return {
    ...defaultPaddingConfig,
    top: defaultPaddingConfig.top + Math.floor(fontSize * fraction)
  };
}

export const SystemGeneratedSignatureFontConfiguration = [
  { font: { name: 'Dancing Script', size: 72 }, canvas: { height: 100, width: 1000 }, padding: defaultPaddingAdjustForFont(72, 0.25) },
  { font: { name: 'Sacramento', size: 72 }, canvas: { height: 110, width: 1000 }, padding: defaultPaddingConfig },
  { font: { name: 'Square Peg', size: 72 }, canvas: { height: 100, width: 1000 }, padding: defaultPaddingAdjustForFont(72, 0.25) },
  { font: { name: 'Ephesis', size: 72 }, canvas: { height: 100, width: 1000 }, padding: defaultPaddingAdjustForFont(72, 0.15) },
  { font: { name: 'Sue Ellen Francisco', size: 72 }, canvas: { height: 120, width: 1000 }, padding: defaultPaddingConfig }
];

interface FontOption {
  label: string,
  value: number,
  data?: string
}

export function SelectSystemGeneratedSignature(props: SelectSystemGeneratedSignatureProps) {
  const [ fontIndex, setFontIndex ] = useState(0);
  const [ signatureImage, setSignatureImage ] = useState<string|undefined>();
  const [ initialsImage, setInitialsImage ] = useState<string|undefined>();
  const { mode } = props;

  useEffect(() => {
    const config = SystemGeneratedSignatureFontConfiguration[fontIndex];
    const newSignatureImage = renderText({
      ...config,
      text: props.fullName
    });
    const newInitialsImage = renderText({
      ...config,
      text: props.initials
    });
    setSignatureImage(newSignatureImage);
    setInitialsImage(newInitialsImage);
    props.onImagesChanged(newSignatureImage, newInitialsImage);
  }, [fontIndex, props.fullName, props.initials]);

  const options: FontOption[] = useMemo(() => {
    return SystemGeneratedSignatureFontConfiguration.map((config, idx) => {
      const fontConfig = SystemGeneratedSignatureFontConfiguration[idx];
      return {
        label: config.font.name,
        value: idx,
        data: renderText({
          ...fontConfig,
          text: props.fullName
        })
      };
    });
  }, [ props.fullName ]);

  return (
    <Container className={'mt-3'}>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row justify-content-between w-100'}>
          <p>Preview</p>
          <div style={{ width: '200px', textAlign: 'right' }}>
            <DropdownButton title='Select Style' variant="light">
              {options?.map(i => <Dropdown.Item key={i.data} onClick={() => setFontIndex(i.value)}><Image src={i.data} fluid={true}/></Dropdown.Item>)}
            </DropdownButton>
          </div>
        </div>
        <Row>
          {mode === SigningStateEntryMode.AdoptSignature && <Col md={8}>
            {props.fullName && <Image src={signatureImage} fluid={true}/>}
          </Col>}
          {mode === SigningStateEntryMode.AdoptInitials && <Col md={4}>
            {props.initials && <Image src={initialsImage} fluid={true}/>}
          </Col>}
        </Row>
      </div>
    </Container>
  );
}
