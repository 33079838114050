import { DOMExportOutput, EditorConfig, ElementNode, LexicalEditor, LexicalNode, NodeKey, SerializedElementNode, Spread } from 'lexical';
import { addClassNamesToElement } from '@lexical/utils';

type SerializedLabelNode = Spread<{ label: string, classname?: string }, SerializedElementNode>;

export class LabelNode extends ElementNode {
  __label: string;
  __className?: string;

  constructor(label: string, className?: string, key?: NodeKey) {
    super(key);
    this.__label = label;
    this.__className = className;
  }

  static getType(): string {
    return 'label';
  }

  static clone(node: LabelNode): LabelNode {
    return new LabelNode(node.__label, node.__className, node.__key);
  }

  insertNewAfter(): LexicalNode | null {
    return null;
  }

  isInline(): boolean {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = document.createElement('span');
    addClassNamesToElement(element, 'label-node', this.__className);
    element.setAttribute('data-label', this.__label);
    return element;
  }

  updateDOM(prevNode: this, dom: HTMLElement, config: EditorConfig): boolean {
    return prevNode.__label !== this.__label || prevNode.__className !== this.__className;
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    //remove this element from exported DOM
    return { element: null };
  }

  exportJSON(): SerializedLabelNode {
    return {
      ...super.exportJSON(),
      type: this.__type,
      label: this.__label,
      classname: this.__className
    };
  }

  static importJSON(serializedLabelNode: SerializedLabelNode): LabelNode {
    return $createLabelNode(serializedLabelNode.label, serializedLabelNode.classname);
  }
}

export function $createLabelNode(text: string, className?: string): LabelNode {
  return new LabelNode(text, className);
}

export function $isLabelNode(node: LexicalNode | null | undefined): node is LabelNode {
  return node instanceof LabelNode;
}
