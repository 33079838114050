import { FormUtil } from '@property-folders/common/util/form';
import { SigningSessionCCItem } from '@property-folders/contract';
import { FormContext } from '../../context/FormContext';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { useContext } from 'react';
import { DistributionParty, DistributionPartyRow, DistributionPartySnapshot } from './DistributionPartyStatusPanel';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { canonicalisers } from '@property-folders/common/util/formatting';

export function CCRecipientsState({
  onMarkPartyManuallyDistributed,
  onPartyToDownload,
  onResendToParty,
  onSendToParty,
  onRedownload
}: {
  onMarkPartyManuallyDistributed: (cc: SigningSessionCCItem) => void,
  onPartyToDownload: (cc: SigningSessionCCItem) => void,
  onResendToParty: (cc: SigningSessionCCItem) => void,
  onSendToParty: (cc: SigningSessionCCItem) => void,
  onRedownload: (cc: SigningSessionCCItem) => void,
}) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { formId, formName: formCode } = useContext(FormContext);
  const { value: ccs } = useLightweightTransaction<SigningSessionCCItem[]>({
    parentPath: FormUtil.getFormPath(formCode, formId),
    myPath: 'cc',
    bindToMetaKey: true
  });
  const parties = (ccs ?? []).map<DistributionParty & { cc: SigningSessionCCItem }>(cc => {
    return {
      id: cc.id,
      distributionState: cc.distributionState,
      snapshot: getCCSnapshot(cc),
      cc
    };
  });

  return <>
    {parties.map(p => (<DistributionPartyRow
      party={p}
      sessionInfo={sessionInfo}
      key={p.id}
      sendingNow={false}
      downloadAvailable={false}
      onMarkPartyManuallyDistributed={() => onMarkPartyManuallyDistributed(p.cc)}
      onPartyToDownload={() => onPartyToDownload(p.cc)}
      onResendToParty={() => onResendToParty(p.cc)}
      onSendToParty={() => onSendToParty(p.cc)}
      onRedownload={() => onRedownload(p.cc)}
    />))}
  </>;
}

function getCCSnapshot(cc: SigningSessionCCItem): DistributionPartySnapshot {
  switch (cc.type) {
    case 'email': {
      const email = canonicalisers.email(cc.email).display;
      return {
        name: cc.name || '',
        email,
        phone: ''
      };
    }
    case 'phone': {
      const phone = canonicalisers.phone(cc.phone).display;
      return {
        name: cc.name || '',
        email: '',
        phone
      };
    }
    case 'team': {
      return {
        name: cc.name,
        email: '',
        phone: '',
        team: { id: cc.teamId, entity: cc.entity }
      };
    }
  }
}
