import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { trimTextContentFromAnchor } from '@lexical/selection';
import { $getSelection, $isRangeSelection, RootNode } from 'lexical';
import { useEffect } from 'react';
import { ReplacementTokens, ReplacementTokenType } from '@property-folders/common/util/process-template';

export function MaxLengthPlugin({ maxLength, tokens, onCharacterCountChange }: { maxLength: number, tokens?: ReplacementTokens, onCharacterCountChange?: (c: number)=>void }): null {
  const [editor] = useLexicalComposerContext();

  const countCharacters = (text?: string): number => {
    if (!text?.length) return 0;
    for (const token of Object.keys(tokens||{})) {
      text = text.replaceAll(token, 'x'.repeat(tokens?.[token as ReplacementTokenType]?.length||0));
    }
    return text.length;
  };

  //initial character count
  useEffect(() => {
    editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      onCharacterCountChange?.(countCharacters(rootNode.getTextContent()));
    });
  }, []);

  useEffect(() => {
    return editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return;
      }
      const prevTextContent = editor
        .getEditorState()
        .read(() => rootNode.getTextContent());
      const textContent = rootNode.getTextContent();
      if (prevTextContent !== textContent) {
        const textLength = countCharacters(textContent);
        onCharacterCountChange?.(textLength);
        const delCount = textLength - maxLength;
        const anchor = selection.anchor;

        if (delCount > 0) {
          trimTextContentFromAnchor(editor, anchor, delCount);
          onCharacterCountChange?.(maxLength);
        } else {
          onCharacterCountChange?.(textLength);
        }
      }
    });
  }, [editor, maxLength]);

  return null;
}
