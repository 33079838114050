import { $getNodeByKey, $getRoot, $getSelection, RootNode } from 'lexical';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin';
import { ToolbarPlugin } from './lexical/ToolbarPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { $generateNodesFromDOM } from '@lexical/html';
import { TRANSFORMERS, $convertFromMarkdownString, $convertToMarkdownString } from './lexical/lexical-markdown/src';
import { ListNode, ListItemNode } from '@lexical/list';
import { UNDERLINE } from './lexical/UnderlineTransformer';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { DisabledPlugin } from './lexical/DisabledPlugin';
import './HtmlTextEditor.scss';
import clsJn from '@property-folders/common/util/classNameJoin';
import { INDENT } from './lexical/IndentTransformer';
import React, { memo } from 'react';
import { LexicalMsWordPasteInterceptorPlugin } from './lexical/LexicalMsWordPasteInterceptorPlugin';
import { ReplacementTokenNode, ReplacementTokenPlugin } from './lexical/replacement-token-plugin';
import { ConditionTokens, ReplacementTokens } from '@property-folders/common/util/process-template';
import { LabelNode } from './lexical/LabelNode';
import { $generateHtmlFromNodes } from './lexical/lexical-html/src';
import { ButtonNode } from './lexical/ButtonNode';
import ConditionPlugin from './lexical/condition-plugin';
import { ConditionContainerNode } from './lexical/condition-plugin/ConditionContainerNode';
import { ConditionTitleNode } from './lexical/condition-plugin/ConditionTitleNode';
import { ConditionContentNode } from './lexical/condition-plugin/ConditionContentNode';
import { AutoLinkPlugin, createLinkMatcherWithRegExp } from '@lexical/react/LexicalAutoLinkPlugin';

export enum EditorMode {
  MARKDOWN = 'MARKDOWN',
  HTML = 'HTML'
}

type HtmlTextEditorProps = {
  namespace: string
  onUpdate?: (output: string) => void;
  className?: string;
  value?: string;
  disabled?: boolean;
  onFocus?: (e: Event) => void;
  onBlur?: (e: Event) => void;
  outputMode: EditorMode,
  inputMode?: EditorMode
  style?: React.CSSProperties
  contentEditableStyle?: React.CSSProperties;
  toolbar?: () => JSX.Element;
  replacementTokens?: ReplacementTokens;
  conditionTokens?: ConditionTokens;
};

const transformers = [INDENT, ...TRANSFORMERS, UNDERLINE];

export const HtmlTextEditorComponent = (props: HtmlTextEditorProps) => {
  const editable = !props.disabled;
  const domParser = new DOMParser();
  const inputMode = props.inputMode ?? props.outputMode;
  const { outputMode } = props;

  const onFocus = (e: Event) => {
    if (typeof props.onFocus === 'function') {
      props.onFocus(e);
    }
  };
  const onBlur = (e: Event) => {
    if (typeof props.onBlur === 'function') {
      props.onBlur(e);
    }
  };

  const initialConfig: InitialConfigType = {
    namespace: props.namespace,
    theme: {
      text: {
        bold: 'font-semibold',
        underline: 'font-underline',
        italic: 'font-italic',
        strikethrough: 'font-line-through',
        underlineStrikethrough: 'underlined-line-through'
      },
      list: {
        olDepth: ['ol-depth-1', 'ol-depth-2', 'ol-depth-3', 'ol-depth-4'],
        nested: {
          listitem: 'list-item-nested'
        }
      },
      indent: 'indented-item'
    },
    onError: error => {
      console.error(error);
    },
    nodes: [
      HorizontalRuleNode,
      HeadingNode,
      LinkNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      ReplacementTokenNode,
      LabelNode,
      ButtonNode,
      ConditionContainerNode,
      ConditionTitleNode,
      ConditionContentNode,
      AutoLinkNode
    ], // not all are allowed, but MD plugin requires them.
    editorState: editor => {
      if (!props.value) {
        return;
      }

      if (inputMode === EditorMode.HTML) {
        // gotta bind text to a text node
        const dom = domParser.parseFromString(props.value, 'text/html');
        editor.update(() => {
          const nodes = $generateNodesFromDOM(editor, dom);
          $getRoot().select();
          $getSelection()?.insertNodes(nodes);

          if (inputMode !== outputMode) {
            props.onUpdate?.($convertToMarkdownString(transformers));
          }
        });
      } else {
        $convertFromMarkdownString(props.value ?? '', transformers);

        if (inputMode !== outputMode) {
          editor.getEditorState().read(() => {
            props.onUpdate?.($generateHtmlFromNodes(editor));
          });
        }
      }
    },
    editable
  };

  return <div className={clsJn('html-text-editor', props.className)} style={props.style}>
    <LexicalComposer initialConfig={initialConfig}>
      <NodeEventPlugin
        nodeType={RootNode}
        eventType='focus'
        eventListener={onFocus}
      />

      <NodeEventPlugin
        nodeType={RootNode}
        eventType='blur'
        eventListener={onBlur}
      />

      <NodeEventPlugin
        nodeType={ButtonNode}
        eventType='click'
        eventListener={(e, editor, nodeKey)=>{
          editor.update(() => {
            const buttonNode = $getNodeByKey(nodeKey) as ButtonNode;
            buttonNode.__onClick?.();
          });
        }}
      />

      <LexicalMsWordPasteInterceptorPlugin transformers={transformers} />

      {editable
        ? props.toolbar
          ? props.toolbar()
          : <ToolbarPlugin />
        : <></>}

      <RichTextPlugin
        contentEditable={<ContentEditable
          className='rte-content-editable form-control'
          key={props.namespace}
          style={props.contentEditableStyle}
        />}
        placeholder={<div className='rte-placeholder'>Enter some text...</div>}
        ErrorBoundary={LexicalErrorBoundary}
      />

      <OnChangePlugin
        onChange={(editorState, editor, _tags) => {
          editorState.read(() => {
            if (typeof props.onUpdate === 'function') {
              switch (props.outputMode) {
                case EditorMode.HTML:
                  props.onUpdate($generateHtmlFromNodes(editor));
                  break;
                case EditorMode.MARKDOWN:
                  props.onUpdate($convertToMarkdownString(transformers));
                  break;
              }
            }
          });
        }}
      />

      <HistoryPlugin />
      <ListPlugin />
      <TabIndentationPlugin />
      <DisabledPlugin disabled={props.disabled} />
      <ReplacementTokenPlugin replacementTokens={props.replacementTokens} conditionTokens={props.conditionTokens} />
      <ConditionPlugin />
      <AutoLinkPlugin matchers={MATCHERS} />
    </LexicalComposer>
  </div>;
};

const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

const EMAIL_REGEX =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const MATCHERS = [
  createLinkMatcherWithRegExp(URL_REGEX, (text) => {
    return text;
  }),
  createLinkMatcherWithRegExp(EMAIL_REGEX, (text) => {
    return `mailto:${text}`;
  })
];
export const HtmlTextEditor = memo(HtmlTextEditorComponent);
