import React, { useCallback, useEffect } from 'react';
import { unstable_useBlocker, unstable_BlockerFunction } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

const originalBeforeUnload = window.onbeforeunload;

export const NavigationBlocker = ({ when }: { when: boolean }) => {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return '';
      };
    }
    return () => {
      window.onbeforeunload = originalBeforeUnload;
    };
  }, [when]);

  const shouldBlock = useCallback<unstable_BlockerFunction>(({ currentLocation, nextLocation }) => {
    return when && currentLocation.pathname !== nextLocation.pathname;
  }, [when]);

  const blocker = unstable_useBlocker(shouldBlock);

  return (
    <Modal show={blocker.state === 'blocked'}>
      <Modal.Header><Modal.Title>You have unsaved changes</Modal.Title></Modal.Header>
      <Modal.Body>
        <p>You have unsaved changes, are you sure you want to leave this page without saving?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => blocker?.reset?.()}>Stay</Button>
        <Button variant={'danger'} onClick={() => blocker?.proceed?.()}>Leave without saving</Button>
      </Modal.Footer>
    </Modal>
  );
};
