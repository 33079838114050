import { DOMExportOutput, EditorConfig, LexicalNode, NodeKey } from 'lexical';
import { LabelNode } from './LabelNode';

export class ButtonNode extends LabelNode {
  __onClick: ()=>void;

  constructor(onClick: ()=>void, label: string, className?: string, key?: NodeKey) {
    super(label, className, key);
    this.__onClick = onClick;
  }

  static getType(): string {
    return 'button-node';
  }

  static clone(node: ButtonNode): ButtonNode {
    return new ButtonNode(node.__onClick, node.__label, node.__className, node.__key);
  }

  insertBefore(nodeToInsert: LexicalNode, restoreSelection?: boolean): LexicalNode {
    return super.insertBefore(nodeToInsert, restoreSelection);
  }

  insertAfter(nodeToInsert: LexicalNode, restoreSelection?: boolean): LexicalNode {
    return super.insertAfter(nodeToInsert, restoreSelection);
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    return element;
  }

  updateDOM(prevNode: this, dom: HTMLElement, config: EditorConfig): boolean {
    return super.updateDOM(prevNode, dom, config) || prevNode.__onClick !== this.__onClick;
  }

  exportDOM(): DOMExportOutput {
    //remove this element from exported DOM
    return { element: null };
  }

  static importJSON() {
    return undefined;
  }
}

export function $createButtonNode(text: string, onClick: ()=>void, className?: string): ButtonNode {
  return new ButtonNode(onClick, text, className);
}

export function $isButtonNode(node: LexicalNode | null | undefined): node is ButtonNode {
  return node instanceof ButtonNode;
}
