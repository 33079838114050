import { WizardStepPage, WizardStepPageProps } from './WizardStepPage';
import { useEffect, useMemo } from 'react';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { SelectEntityOption, SelectPrimaryAgent } from '../form/NarrowAgentInput';
import { companyTradingAs } from '@property-folders/common/util/formatting/functions/companyTradingAs';
import { useSelector } from 'react-redux';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { FolderType, OwningEntity, TransactionMetaData } from '@property-folders/contract';
import { Predicate } from '@property-folders/common/predicate';
import { BelongingEntityMeta } from '@property-folders/common/redux-reducers/entityMeta';
import { Col, Row } from 'react-bootstrap';

export function StandaloneAgencyWizardStepPage(props: Partial<WizardStepPageProps>) {
  const { value: meta } = useLightweightTransaction<TransactionMetaData>({ myPath: '', bindToMetaKey: true });
  const { value: entity } = useTransactionField<OwningEntity>({ myPath: 'entity', bindToMetaKey: true });
  const { updateDraft: updateMeta } = useYdocBinder<TransactionMetaData>({ path: '', bindToMetaKey: true });
  const memberEntities = useSelector((state: { entityMeta: BelongingEntityMeta }) => state.entityMeta);
  const entityOptions = useMemo(() => Object.values(memberEntities || {})
    .map<undefined | SelectEntityOption>(e => {
      if (
        !e
        || e.archived === true
        || (!e.useNewPropertyTransactions && meta?.folderType === FolderType.Property)
        || (meta?.folderType === FolderType.MyFile && (!e.useMyFiles || !e.teamManagementVisible))
      ) {
        return undefined;
      }

      const compositeName = companyTradingAs(e.name, e.tradeName);
      return {
        label: e.profileName || compositeName,
        compositeName,
        ...e
      };
    }).filter(Predicate.isNotNull), [memberEntities, meta?.folderType]);
  const selectedEntity = entityOptions.find(option => option?.entityId && option?.entityId === entity?.id);
  const selectEntity = (id: number, name: string) => {
    updateMeta?.(draft => {
      if (!draft.sharing) {
        draft.sharing = {
          createExclusions: [],
          createQueue: [],
          creating: false,
          lastSync: Date.now(),
          serverChangesPending: true
        };
      } else {
        draft.sharing.serverChangesPending = true;
      }

      draft.entity = {
        id,
        name
      };
    });
  };
  useEffect(() => {
    if (entityOptions.length === 1 && entityOptions[0].entityId !== entity?.id) {
      selectEntity(entityOptions[0].entityId, entityOptions[0].name);
    }
  }, [entityOptions]);

  if (entityOptions.length < 2) {
    return <></>;
  }

  return <WizardStepPage
    name='Agency'
    icon='agency'
    {...props}
    label={'Agency'}
  >
    <Row className='mb-3'>
      <Col>
        <SelectPrimaryAgent
          onSuggestionSelect={newValue => {
            if (!newValue) return;
            selectEntity(newValue.entityId, newValue.name);
          }}
          options={entityOptions}
          value={selectedEntity}
        />
      </Col>
    </Row>
  </WizardStepPage>;
}
