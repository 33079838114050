import { CreateCustomFieldDetails, CustomFieldDetails, DraggableType } from './types';
import { customFieldMetas, defaultCheckmark, defaultFontSize, defaultLineHeight, fallbackFieldText } from '@property-folders/contract/property/meta';
import React from 'react';
import { CustomFieldConfiguration, CustomFieldType, FieldPosition, MaterialisedPropertyData } from '@property-folders/contract';
import { getTextFieldText, SupportedFontFamily, TextDimensionEstimator } from '@property-folders/common/util/pdf';
import { CustomFieldPartyInfo } from './CustomField';
import { Rect } from '@property-folders/common/util/coords';

export function getStyles(details: CustomFieldDetails, scale: number) {
  const meta = customFieldMetas[details.type];
  const generated: React.CSSProperties = {
    height: (meta.signingSessionFieldType || meta.formField) ? undefined : '100%',
    paddingTop: '0.1em',
    whiteSpace: 'pre'
  };
  if (details.type === CustomFieldType.checkmark) {
    generated.fontFamily = 'DejaVu Sans';
  }

  if (details.mode === DraggableType.Existing) {
    if (details.type === CustomFieldType.remoteText) {
      if (details.multiline) {
        generated.height = '100%';
        generated.textWrap = 'wrap';
      }
    }

    for (const attr of meta.attributes) {
      switch (attr.name) {
        case 'fontSize':
          generated.fontSize = formatNumericAttribute(attr.name, details, value => `${scale * value}px`);
          if (meta.resize === 'auto') {
            generated.lineHeight = 1;
          }
          break;
        case 'lineHeight':
          generated.lineHeight = formatNumericAttribute(attr.name, details, value => `${scale * value}px`);
          break;
        case 'fontFamily':
          generated.fontFamily = getStringAttribute(attr.name, details);
          break;
      }
    }
  }
  return generated;
}

export function formatNumericAttribute<TFormatted>(name: string, field: CustomFieldConfiguration, formatter: (value: number) => TFormatted | undefined): TFormatted | undefined {
  if (!(name in field)) return undefined;

  const value = (field as any)[name];
  if (value == null) return undefined;

  if (typeof value !== 'number') return undefined;

  return formatter(value);
}

export function getStringAttribute(name: string, field: CustomFieldConfiguration): string | undefined {
  if (!(name in field)) return undefined;

  const value = (field as any)[name];
  if (value == null) return undefined;

  if (typeof value !== 'string') return undefined;

  return value;
}

export function autoResize(field: CustomFieldConfiguration, {
  force,
  estimator
}: {
  force?: boolean,
  estimator: TextDimensionEstimator
}) {
  const auto = customFieldMetas[field.type].resize === 'auto';
  if (!(force || auto)) return;
  field.position = estimatePosition(field, { estimator });
}

export function estimatePosition(field: CustomFieldConfiguration, { estimator }: { estimator: TextDimensionEstimator }) {
  switch (field.type) {
    case CustomFieldType.checkmark:
      return {
        ...field.position,
        ...estimator.estimateTextDimensions(field.checkmark, SupportedFontFamily.DejaVuSansCheckmarks, field.fontSize, { nopad: true })
      };
    // maybe others later
    case CustomFieldType.remoteRadio:
    case CustomFieldType.remoteCheck:
      if (field.label.trim()) {
        return {
          ...field.position,
          ...estimator.estimateTextDimensions(`${defaultCheckmark}   ${field.label}`.trim(), field.fontFamily, field.fontSize)
        };
      } else {
        const { height } = estimator.estimateTextDimensions(`${defaultCheckmark}`, field.fontFamily, field.fontSize);
        // noinspection JSSuspiciousNameCombination we want it to be square
        return {
          ...field.position,
          ...{
            height,
            width: height
          }
        };
      }
    case CustomFieldType.remoteText:
      if (field.multiline) {
        // until we support number-of-lines specified, we can't really estimate the dimensions.
        // currently text box sizes are still manually dragged
        return field.position;
      } else {
        return {
          ...field.position,
          ...estimator.estimateTextDimensions('Remote Text Field With Dangly', field.fontFamily, field.fontSize, { hFudge: 1.1 })
        };
      }
    default:
      return field.position;
  }
}

export function afterAttrChange({
  field,
  name,
  estimator,
  all
}: {
  field: CustomFieldConfiguration,
  name: string,
  estimator: TextDimensionEstimator,
  all: CustomFieldConfiguration[]
}) {
  console.log('afterAttrChange', field);
  const meta = customFieldMetas[field.type];
  const attr = meta.attributes.find(a => a.name === name);
  if (!attr?.afterEdit) return;

  switch (attr.afterEdit) {
    case 'calc-text-height':
      // recalculate height of text field, e.g. for single line text fields
      if (field.type === CustomFieldType.remoteText && !field.multiline) {
        field.position = {
          ...field.position,
          height: estimator.estimateTextDimensions(
            'Dangly',
            field.fontFamily,
            field.fontSize,
            { hFudge: 1.1 }
          ).height
        };
      }
      break;
    case 'turn-off-group-members': {
      const groupId = getGroup(field);
      if (!groupId) break;

      for (const other of all) {
        if (other === field || other.id === field.id) continue;
        if (getGroup(other) === groupId && 'on' in other) {
          delete other.on;
        }
      }
      break;
    }
  }
}

function getGroup(field: CustomFieldConfiguration): string | undefined {
  return 'group' in field
    ?field.group
    : undefined;
}

export class AttrDefaultLoader {
  constructor(private customFieldDefaults: Record<string, any>) { }

  public load<TForceType>(type: CustomFieldType, name: string): TForceType | undefined {
    const attr = customFieldMetas[type].attributes.find(a => a.name === name);
    const overrideDefault = this.customFieldDefaults[name];

    if (overrideDefault != null) return overrideDefault as TForceType;
    if (attr?.typeInfo && 'defaultValue' in attr.typeInfo) return attr.typeInfo.defaultValue as TForceType;

    return undefined;
  }
}

export function getNextGroupId(fields: CustomFieldConfiguration[], prefix: 'radio-' | 'check-'): string {
  const existingGroups = new Set<string>();
  for (const field of fields) {
    if ('group' in field && field.group) {
      existingGroups.add(field.group);
    }
  }
  let next = existingGroups.size + 1;
  let candidate = `${prefix}${next}`;
  while (existingGroups.has(candidate)) {
    next = next + 1;
    candidate = `${prefix}${next}`;
  }
  return candidate;
}

export function safeGenerateCustomField(opts: {
  field: CreateCustomFieldDetails,
  partyId: string | undefined,
  position: FieldPosition,
  newId: string,
  attrs: AttrDefaultLoader,
  data: MaterialisedPropertyData,
  party: CustomFieldPartyInfo | undefined,
  estimator: TextDimensionEstimator,
  existingFields?: CustomFieldConfiguration[]
}): CustomFieldConfiguration | undefined {
  const result = safeGenerateCustomFieldInner(opts);

  if (!result) return undefined;

  result.position = {
    ...result.position,
    ...adjustRectToMinDimensions(result.position, opts.field.type, true)
  };

  return result;
}

function safeGenerateCustomFieldInner({
  field,
  partyId,
  position,
  newId,
  attrs,
  data,
  party,
  estimator,
  existingFields
}: {
  field: CreateCustomFieldDetails,
  partyId: string | undefined,
  position: FieldPosition,
  newId: string,
  attrs: AttrDefaultLoader,
  data: MaterialisedPropertyData,
  party: CustomFieldPartyInfo | undefined,
  estimator: TextDimensionEstimator,
  existingFields?: CustomFieldConfiguration[]
}): CustomFieldConfiguration | undefined {
  switch (field.type) {
    case CustomFieldType.name:
    case CustomFieldType.authority:
    case CustomFieldType.address:
    case CustomFieldType.phone:
    case CustomFieldType.email:
    case CustomFieldType.company:
    case CustomFieldType.abn:
      if (!partyId) {
        console.warn('attempted to add party field without selected party information');
        return undefined;
      }
      return {
        id: newId,
        type: field.type,
        partyId,
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            getTextFieldText(field.type, party?.snapshot, data) || fallbackFieldText,
            attrs.load<string>(field.type, 'fontFamily'),
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.saleAddress:
    case CustomFieldType.saleTitle:
    case CustomFieldType.proposedAllotments:
    case CustomFieldType.propertySummary:
    case CustomFieldType.primaryVendor:
    case CustomFieldType.primaryPurchaser:
    case CustomFieldType.purchasePrice:
    case CustomFieldType.settlement:
    case CustomFieldType.salesperson:
      return {
        id: newId,
        type: field.type,
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            getTextFieldText(field.type, party?.snapshot, data) || fallbackFieldText,
            attrs.load<string>(field.type, 'fontFamily'),
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.text:
      return {
        id: newId,
        type: CustomFieldType.text,
        text: 'Custom text',
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            'Custom text',
            attrs.load<string>(field.type, 'fontFamily'),
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.checkmark:
      return {
        id: newId,
        type: CustomFieldType.checkmark,
        checkmark: attrs.load<string>(field.type, 'checkmark') || defaultCheckmark,
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            attrs.load<string>(field.type, 'checkmark') || defaultCheckmark,
            SupportedFontFamily.DejaVuSansCheckmarks,
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
            { nopad: true }
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize
      };
    case CustomFieldType.timestamp:
      if (!partyId) {
        console.warn('attempted to add party field without selected party information');
        return undefined;
      }
      return {
        id: newId,
        type: CustomFieldType.timestamp,
        partyId,
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            '22-MMM-2222',
            attrs.load<string>(field.type, 'fontFamily'),
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.initials:
      if (!partyId) {
        console.warn('attempted to add party field without selected party information');
        return undefined;
      }
      return {
        id: newId,
        type: field.type,
        partyId,
        position,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.signature:
      if (!partyId) {
        console.warn('attempted to add party field without selected party information');
        return undefined;
      }
      return {
        id: newId,
        type: field.type,
        partyId,
        position,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.purchaserName:
    case CustomFieldType.purchaserAddress:
      return {
        id: newId,
        type: field.type,
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            '123 Quite Long Name For A Terrace, Rather Large Suburb Name, WWW 5555',
            attrs.load<string>(field.type, 'fontFamily'),
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.contractDate:
      return {
        id: newId,
        type: field.type,
        position: {
          ...position,
          ...(estimator.estimateTextDimensions(
            // previously we'd only calculate enough space to display a formatted date, but then someone raised a bug
            // that this cuts off the 'Contract Date' label when on the field editing screen, which looks weird.
            // so, now we calculate enough space for <[icon] Contract Date> instead of a date like 22-MAR-2024.
            'ICO Contract Date',
            attrs.load<string>(field.type, 'fontFamily'),
            attrs.load<number>(field.type, 'fontSize') || defaultFontSize
          ))
        },
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour')
      };
    case CustomFieldType.remoteCheck: {
      const label = '';
      const item = {
        id: newId,
        type: field.type,
        position,
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        group: getNextGroupId(existingFields || [], 'check-'),
        label
      };
      autoResize(item, { estimator, force: true });
      return item;
    }
    case CustomFieldType.remoteRadio: {
      const label = '';
      const item = {
        id: newId,
        type: field.type,
        position,
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        group: getNextGroupId(existingFields || [], 'radio-'),
        label
      };
      autoResize(item, { estimator, force: true });
      return item;
    }
    case CustomFieldType.remoteText: {
      const item: CustomFieldConfiguration = {
        id: newId,
        type: field.type,
        position,
        fontColour: attrs.load<string>(field.type, 'fontColour'),
        fontSize: attrs.load<number>(field.type, 'fontSize') || defaultFontSize,
        fontFamily: attrs.load<string>(field.type, 'fontFamily'),
        lineHeight: attrs.load<number>(field.type, 'lineHeight') || defaultLineHeight,
        bg: attrs.load<boolean>(field.type, 'bg'),
        bgColour: attrs.load<string>(field.type, 'bgColour'),
        required: true
      };
      autoResize(item, { estimator, force: true });
      return item;
    }
    default:
      console.warn(`Cannot add ${field.type} field. Not yet implemented.`);
      return undefined;
  }
}

export function adjustRectToMinDimensions(base: Rect, type: CustomFieldType, creating?: boolean): Rect {
  const meta = customFieldMetas[type];
  if (!meta?.hMin && !meta?.wMin) return base;

  const { x, y, width, height } = base;
  return {
    x,
    y,
    width: applyMinDimension(width, meta?.wMin, creating && meta?.createMin),
    height: applyMinDimension(height, meta?.hMin, creating && meta?.createMin)
  };
}

function applyMinDimension(value: number, min?: number, forceMin?: boolean) {
  if (!min) return value;
  if (forceMin) return min;
  return Math.max(value, min);
}
