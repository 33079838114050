import './EmailPreview.scss';
import { BrandStyle } from '@property-folders/services/lib/email';
import { Image } from 'react-bootstrap';
import { reaformsOrange } from '@property-folders/common/visual';
import React from 'react';
import { Predicate } from '@property-folders/common/predicate';
import { AgentAvatar, AgentAvatarProps } from './AgentAvatar';
import { AuthApi } from '@property-folders/common/client-api/auth';

interface Sender {
  name?: string,
  avatar?: AgentAvatarProps,
}

const defaultSender: Sender = {
  name: 'reaforms Document Service',
  avatar: {
    initials: 'RD',
    agent: { name: 'reaforms Document Service', id: 0 },
    style: {
      color: '#ffffff',
      backgroundColor: '#4f6bed'
    }
  }
};

export function EmailPreview({
  brand,
  brandedContent,
  contact,
  subject,
  message,
  messageHtml,
  sender = defaultSender,
  links
}:{
  brand: Omit<BrandStyle, 'agencyContact' | 'logoAltText'>,
  brandedContent?: {
    text?: string,
    button?: string,
    buttonUrl?: string
    button2?: string,
    buttonUrl2?: string
  }
  contact?: {
    name: string;
    email?: string;
    phone?: string;
    message?: string;
  },
  subject?: string,
  sender?: {
    name?: string,
    avatar?: AgentAvatarProps,
  },
  message?: string,
  messageHtml?: string,
  links?: {
    url?: string,
    urlText?: string
  }[]
}) {
  const genMessage = message
    ?.split(/\n\n+/).filter(Predicate.isTruthy).map((para, i) => {
      return <p key={i}>{para.split(/\n/).filter(Predicate.isTruthy).map((line, idx, { length }) => idx < length -1
        ? <>{line}<br /></>
        : line
      )}</p>;
    });
  const avatarProps = {
    agent: { name: sender?.name || '', id: 0 },
    fontSize: '1.5rem',
    ...sender?.avatar
  };

  return <div className='email-preview-container d-flex justify-content-center'>
    <div className='centerline'>
      <div className='whitebox'>
        {sender?.name && <>
          <h4>{subject}</h4>
          <div className='d-flex flex-row align-items-center gap-2'>
            <AgentAvatar {...avatarProps} />
            <div>
              <h6>{sender?.name}</h6>
              <h6>To: Recipient</h6>
            </div>
          </div>
          <hr />
        </>}
        <div><Image className='email-preview-logo' src={brand.logoUrl}/></div>
        {(brandedContent?.button || brandedContent?.button2) &&
        <div className='brand-box' style={{
          backgroundColor: brand?.content?.backgroundColour || reaformsOrange,
          color: brand?.content?.foregroundColour || 'white'
        }}>
          <p className='mt-2'>{brandedContent.text}</p>
          {(brandedContent.button || brandedContent.button2) && <div className='d-flex justify-content-center'>
            {brandedContent.button && <a href={brandedContent.buttonUrl} style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
              <div className='fake-button me-2' style={{
                backgroundColor: brand?.button?.backgroundColour || 'white',
                color: brand?.button?.foregroundColour || reaformsOrange
              }} >{brandedContent.button}
              </div></a>}
            {brandedContent.button2 && <a href={brandedContent.buttonUrl2} style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
              <div className='fake-button' style={{
                backgroundColor: brand?.button?.backgroundColour || 'white',
                color: brand?.button?.foregroundColour || reaformsOrange
              }} >{brandedContent.button2}
              </div></a>}
          </div>}
        </div>}
        <div>
          {!!genMessage && <>{genMessage}</>}
          {!!messageHtml && <div dangerouslySetInnerHTML={{ __html: messageHtml }}/>}

          {!!links?.length && links?.some(l => l.urlText) && <div className='mb-3'>
            Please see the following link{links?.length>1?'s':''}:
            {links?.map(l => <div key={l.url} className={'ps-3'}>
              <a href={l.url||'#'} target={'_blank'}>{l.urlText}</a>
            </div>)}
          </div>}

          {contact && <>
            {contact.message
              ? <p>{contact.message}</p>
              : <p>Should you have any questions in relation to the document or the electronic signing process, please
                contact:</p>}
            <p>
              <b>{contact.name}</b>
              {contact.email && <><br /><a href='#'>{contact.email}</a></>}
              {contact.phone && <><br /><a href='#'>{contact.phone}</a></>}
            </p>
          </>}
        </div>
      </div>
    </div>
  </div>;
}

export function useAgentSessionPreviewEmailContact() {
  const { data } = AuthApi.useGetAgentSessionInfo();

  return {
    name: data?.name || '[Contact Name]',
    email: data?.email,
    entity: data?.entities?.[0]
  };
}

export function generateBrandText(person: string, entity: string, aDocumentName: string, addressSummary: string, action: string) {
  return `${person} from ${entity} has sent you ${aDocumentName} for ${addressSummary} to ${action}`;
}
