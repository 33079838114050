import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { WrField } from '../../dragged-components/form/CommonComponentWrappers';
import { fullPathJoin, useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { CollectionEditor, EditorListChildProps } from '../../dragged-components/form/CollectionEditor';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { CollectionRemoveButton } from '../../dragged-components/form/CollectionRemoveButton';
import { useContext } from 'react';
import { FormUserInteractionContext } from '../../context/FormUserInteractionContext';
import { mergePaths } from '@property-folders/common/util/pathHandling';
import { useSpecificErrorsForPath } from '../../hooks/useFocusErrorList';
import './SlidingScale.scss';
import { Button } from 'react-bootstrap';
import { InstallmentsItemType } from '@property-folders/contract';

const limitStyle = { width: '130px' };
const amountStyle = { width: '100px' };

const positionals = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth'
];

function InstallmentItem(props: TransactionConsumerProps & EditorListChildProps & {
  amountValuePlaceholder: string,
  amountTextEnd?: boolean,
  total: number
}) {
  const { value: collection } = useLightweightTransaction<InstallmentsItemType[]>({ parentPath: props.parentPath });
  const { handleRemove, fullPath } = useTransactionField(props);
  const shouldShowFullValidationError = useContext(FormUserInteractionContext).userShouldSeeAllValidation;
  const validationResult = useSpecificErrorsForPath(mergePaths(fullPath, 'limit'));

  // This is only for showing an error, if it is visible, and it is in error, it should be showing.
  // We shouldn't need to check mode because this entire component shouldn't render then
  const showErrorText = shouldShowFullValidationError && !validationResult?.valid && validationResult?.errors.some(e=>e === 'scaleLimitNotGreaterThanPrev');

  const isLastElement = props.myIndex === props.collectionLength-1;
  const previousItems = collection?.slice(0,(props.myIndex??0));
  const totalSoFar = previousItems?.reduce(((acc,cv)=>acc+(typeof cv.amount === 'number' ? cv.amount : NaN)),0);
  const removeButton = !isLastElement
    ? <CollectionRemoveButton removable={(props.myIndex !== 0 && props.removable)??false} onRemove={handleRemove} className={props.myIndex === 0 ? 'opacity-0' : undefined}/>
    : props.canInsertMore
      ? <Button variant='outline-secondary' onClick={()=>props.onInsert?.(false)} className='add'>+</Button>
      : <Button variant='outline-secondary' onClick={()=>{}} className='add opacity-0' disabled={true} >+</Button>;

  const rowContent = () => {
    if (isLastElement) {

      return <>
        <div className='text-part'>Final Instalment of {canonicalisers.audWithNegative(props.total-(totalSoFar??0)).display}</div>
        <div className='field-part'>
          <WrField.Control
            key='rowDate'
            name="limit"
            type='date'
            useCanonical={true}
            dateFromToday={true}
            parentPath={fullPath}
            myPath="date"
            valuePlaceholder='$'
            style={limitStyle}
          />
        </div>
      </>;
    } else {
      return <>
        <div className='text-part'>{positionals[props.myIndex]} Instalment</div>
        <div className='field-part'>
          <WrField.Control
            key='rowAmount'
            name="amount"
            parentPath={fullPath}
            myPath="amount"
            valuePlaceholder={props.amountValuePlaceholder??'$'}
            style={amountStyle}
            textEnd={props.amountTextEnd}
          />
          <WrField.Control
            key='rowDate'
            name="limit"
            type='date'
            useCanonical={true}
            dateFromToday={true}
            parentPath={fullPath}
            myPath="date"
            valuePlaceholder='$'
            style={limitStyle}
          />
        </div>
      </>;
    }
  };
  return <div className='d-flex flex-column w-100'>
    <div className='d-flex'>
      <div className='row-data w-100'>
        {rowContent()}
      </div>
      <div className='button-container'>
        {removeButton}
      </div>
    </div>
    {showErrorText && <div className='d-flex w-100 align-items-end justify-content-end d-block invalid-feedback'>Sale price limit must be greater than previous<div style={{ width: '3em' }}></div></div>}

  </div>
  ;
}

export function InstallmentsCollection(props: TransactionConsumerProps & {total: number}) {
  const fullPath = fullPathJoin(props);
  return <div className='sliding-scale'><CollectionEditor
    parentPath={fullPath}
    myPath='levels'
    childItemRenderer={InstallmentItem}
    autoAddFirst={2}
    allowAdd={true}
    childProps={{ total: props.total }}
    passAddToChildInstead={true}
    maximum={3}
  />
  </div>;

}