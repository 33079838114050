import { ContractScheduleType } from '@property-folders/contract';
import { WrField } from '../../dragged-components/form/CommonComponentWrappers';
import { useLightweightTransaction } from '../../hooks/useTransactionField';

export function PermittedEncumbrances (props) {
  const { fullPath, value: encu } = useLightweightTransaction<ContractScheduleType>({ myPath: 'contractSchedule' });
  return <div className='subsection scrollspy-target' data-focus-path="subsection-title-matters">
    <div className='fs-4'>Permitted Encumbrances</div>
    <div>The Property is sold subject to the following Permitted Encumbrances:</div>
    <WrField.BoolCheck
      parentPath={fullPath}
      myPath='anyNotDischarged'
      name='encumbrancesNotDischarged'
      label='any Encumbrances detailed in the Form 1 as not being discharged prior to or at Settlement (only select if a Form 1 has been served or made available for perusal prior to signing the Contract, or if the Purchaser has cooling-off rights once the Form 1 is served).'
    />
    <WrField.BoolCheck
      parentPath={fullPath}
      myPath='existingEncumbrances'
      name='existingEncumbrancesCheck'
      label='the following existing Encumbrance(s) (not required if top option selected):'
    />
    <WrField.BoolCheck
      parentPath={fullPath}
      myPath='newEncumbrances'
      name='newEncumbrancesCheck'
      label='the following new Encumbrance(s) to be created after the Contract Date:'
    />
    {encu?.newEncumbrances && <WrField.Control
      textArea={true}
      parentPath={fullPath}
      myPath='newEncumbrancesText'
      name='newEncumbrancesText'
      label='Details of new Encumbrance(s)'
    />}

    {encu?.existingEncumbrances && <WrField.Control
      name='mattersAffectTitle'
      textArea={true}
      parentPath={fullPath}
      myPath='noForm1NoCoolMatters'
      label='Details of Existing Encumbrance(s)'
    />}
    <WrField.BoolCheck
      parentPath={fullPath}
      myPath='notApplicable'
      name='encumbrancesNotApplicable'
      label='not applicable.'
    />
  </div>;
}