import { WrField } from './CommonComponentWrappers';
import React, { useEffect } from 'react';
import { useCurrentEntity } from '../../hooks/useEntity';
import { usePrimarySalesperson } from '../../hooks/usePrimarySalesperson';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { AgencyVendorMap } from '@property-folders/contract/yjs-schema/entity-settings';
import {
  StandardReaformsTransactionFeeCents
} from '@property-folders/contract/yjs-schema/model/prefill/residential';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { Form1SearchParty } from '@property-folders/contract';
import { noGreatformsCost } from '@property-folders/common/util/vpa-total-cost';

export function TransactionFeeSection() {
  const entity = useCurrentEntity();
  const agent = usePrimarySalesperson();
  const { value: whoSearches } = useLightweightTransaction<Form1SearchParty>({ parentPath: 'form1AndSearches', myPath: 'whoSearches' });
  const { value: transactionFeeSaved } = useLightweightTransaction<number>({ parentPath: 'transactionFee', myPath: 'transactionFeeAmountCents' });
  const { value: waiveFee } = useLightweightTransaction<boolean>({ parentPath: 'transactionFee', myPath: 'waiveFee' });
  const { value: hideWaived } = useLightweightTransaction<boolean>({ parentPath: 'transactionFee', myPath: 'hideWaived' });

  const transactionFee = ((transactionFeeSaved ?? StandardReaformsTransactionFeeCents) / 100).toFixed(2);
  const { updateDraft: updateTransactionFee } = useYdocBinder<MaterialisedPropertyData['transactionFee']>({ path: 'transactionFee' });
  useEffect(() => {
    updateTransactionFee?.(draft => {
      if (!draft) return;

      // this ensures that if the property folder was created during the waived transaction fee time then
      // we don't update it if they create a variation
      if (typeof draft.waiveFee !== 'boolean' && typeof entity?.waiveTransactionFee === 'boolean') {
        draft.waiveFee = entity.waiveTransactionFee;
      }
    });
  }, [entity?.waiveTransactionFee]);

  useEffect(()=>{
    if (hideWaived != null) return;
    updateTransactionFee?.(draft => {
      if (!draft) return;
      // While this should be a prefill, this new radio option is going to appear in 100s of property folders.
      draft.hideWaived = false;
    });
  }, [hideWaived]);

  // default is 0, so this takes care of that quite neatly
  const whoPays = agent?.whoPaysTransactionFee
    ? agent.whoPaysTransactionFee
    : entity?.whoPaysTransactionFee;

  let label = 'Greatforms Transaction Fee';
  if (whoPays == AgencyVendorMap.AGENCY) {
    label += ' $0.00 including GST';
  } else {
    label += ` $${transactionFee} including GST`;
  }

  const noFee = noGreatformsCost({ waiveFee, whoSearches });

  if (noFee) label = 'Nil';

  const showOptToHide = noFee;

  return <>
    <div className='subsection scrollspy-target' data-focus-path="subsection-form1-and-searches">
      <div className='fs-4 d-flex align-items-center'>Greatforms Transaction Fee</div>
      <div className='ms-2'>
        <div className='mt-2 pe-5'><WrField.BoolCheck
          name='transactionFee.enabled'
          parentPath='transactionFee'
          myPath='enabled'
          label={label}
          disabled={noFee}
          overrideChecked={true}
        />
        </div>
        {showOptToHide && <div className='mt-2' style={{ flex: '1 0 200px' }}>
          <WrField.CheckRadio
            label=''
            radioType='radio'
            options={{ false: 'Show Nil fee in document', true: 'Remove section from document' }}
            valueType='boolean'
            inline={false}
            name='transactionFee.hideWaived'
            parentPath='transactionFee'
            myPath='hideWaived'
          />
        </div>}
      </div>
    </div>
  </>;
}
