import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import clsJn from '@property-folders/common/util/classNameJoin';
import { compareFormInstances } from '@property-folders/common/util/compareFormInstances';
import { FormState, determineFormState } from '@property-folders/common/yjs-schema/property';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { FormCodeUnion, FormStates } from '@property-folders/contract';
import { FormInstanceCard } from '../dragged-components/FormInstanceCard';
import { Icon } from '../dragged-components/Icon';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { staticHistoryBuilder } from '../hooks/useVariation';
import { buildSigningTimelines } from '@property-folders/common/util/dataExtract';
import { formCompleted } from '@property-folders/common/util/form/formCompleted';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from './errors/modals';

interface LineageRelatedModalWrapperProps {
  formFamilyContext?: string
  formStates?: FormStates
  ydocId?: string
  headline?: string
  onFormCreate?: (formCode: FormCodeUnion)=>void
  onClose?: ()=>void
  children: JSX.Element,
  onExternalTermination: ()=>void
}

export function LineageRelatedModalWrapper(props: LineageRelatedModalWrapperProps) {
  return <div className="d-flex w-100 h-100 special-modal-container-outer">
    {props.children}
    <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={!!props.formFamilyContext} onClose={props.onClose} />}>
      <LineageRelatedModalWrapperInner {...props} />
    </ErrorBoundary>
  </div>;
}

export function LineageRelatedModalWrapperInner({ onExternalTermination, formFamilyContext: formFamilyCode, formStates, ydocId, headline, onFormCreate, onClose }: LineageRelatedModalWrapperProps) {
  const familyType = FormTypes[formFamilyCode??''];
  const navigate = useNavigate();
  const [currentScroll, setCurrentScroll] = useState<number|null>(null);
  const [overflowAmount, setOverflowAmount] = useState<number|null>(null);
  const horizontalScrollRef = useRef<HTMLDivElement>(null);
  useEffect(()=>{
    const elem = horizontalScrollRef.current;
    if (elem && formFamilyCode) {

      const handleScroll = () => {

        setCurrentScroll(elem.scrollLeft);
        setOverflowAmount(elem.scrollWidth - elem.offsetWidth);
      };
      elem.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);

      setCurrentScroll(elem.scrollLeft);
      setOverflowAmount(elem.scrollWidth - elem.offsetWidth);

      return ()=>{
        elem.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      };
    } else {
      setCurrentScroll(null);
      setOverflowAmount(null);
    }

  }, [horizontalScrollRef.current, formFamilyCode]);

  const formFamiliesAffectedByExpiry = Object.keys(formStates??{}).filter(fCode=>{
    const primaryForm = FormTypes[fCode];
    return !!primaryForm?.documentExpiryPaths?.duration;
  });

  const expiryAffectedInstances = formFamiliesAffectedByExpiry.map(fCode=>formStates?.[fCode].instances??[]).flat().filter(formCompleted);

  const [expiriesMap, setExpiriesMap] = useState<{[Property in keyof typeof FormTypes]: Date}>({});

  useEffect(()=>{
    Promise.all(formFamiliesAffectedByExpiry.map(async fCode=>{
      return { // We only use this from a contract lineage, so it's a sublineage
        [fCode]: buildSigningTimelines(await staticHistoryBuilder(formStates??{}, fCode, { isSublineage: true })).latestExpiry
      };
    })).then(fam => {
      setExpiriesMap(Object.assign({}, ...fam));
    });
  }, [expiryAffectedInstances.length]);

  const formTypeEntries = Object.entries(FormTypes);
  type FormTypeEntry = typeof formTypeEntries[number];
  function excludeExpiredFamilyVariations([fCode]: FormTypeEntry) {
    if (!FormTypes[fCode].isVariation) {
      return true;
    }
    const formFamily = FormTypes[fCode].formFamily;
    if (!expiriesMap[formFamily]) {
      return true;
    }
    // Not guard here, we don't want to accidentally filter out stuff unintentionally
    if (expiriesMap[formFamily] < new Date()) {
      return false;
    }
    return true;
  }

  let subSuggestions: string[] | undefined;

  if (formFamilyCode && typeof formStates === 'object') {
    const thisFamily = Object.entries(FormTypes).filter(([_, t])=>!t.primary&&t.formFamily===formFamilyCode);
    const incompleteForms = formStates[formFamilyCode].instances?.filter(i=>{
      const state = determineFormState(i);
      return state === FormState.DRAFT || state === FormState.AWAITING_SIGN || state === FormState.CONFIGURING;
    }).map(i=>i.formCode);
    subSuggestions = thisFamily.filter(([fCode]) => !incompleteForms?.includes(fCode)).filter(excludeExpiredFamilyVariations).map(([fcode])=>fcode);
  }

  const getFirstOutOfView = (mode: 'back'|'forward') => {
    const elem1 = horizontalScrollRef.current?.querySelectorAll('.card');
    const container = horizontalScrollRef.current?.getBoundingClientRect();
    let latestNotInView: Element | null = null;
    if (elem1 && container) {
      let listing = [...elem1];
      if (mode === 'forward') {
        listing = listing.reverse();
      }
      for (const elem of listing) {
        const elemRect = elem.getBoundingClientRect();
        if (mode === 'back') {
          if (elemRect.x - container.x < 0) {
            latestNotInView = elem;
          } else {
            break;
          }
        } else {
          if (elemRect.x+elemRect.width > container.x+container.width) {
            latestNotInView = elem;
          } else {
            break;
          }
        }

      }
    }
    return latestNotInView;
  };

  const navigateToForm = (instanceId: string, formCode: string) => {
    if (!ydocId) return;

    const formType = FormTypes[formCode];
    navigate(LinkBuilder.documentPath(
      {
        id: ydocId,
        nicetext: headline
      },
      {
        id: instanceId,
        nicetext: formType.label
      },
      !!formType.subscription));
  };

  const formFamilyContextItems = formFamilyCode && formStates && formStates[formFamilyCode]
    ? [...(formStates[formFamilyCode ?? '']?.instances ?? [])].sort(compareFormInstances)
    : [];

  // This is an ugly method to only trigger rescroll on overflow changes after the list has been
  // rendered. This occurs because the overflow is changed by the addition of the buttons, and what
  // happens is the apparent position will jump back, so that it will not be all the way to the
  // right. This is a double rising edge trigger, so that we don't jump to the right every time we resize
  // the page or rotate from portrait to landscape.
  const [itemChangeOverflowChange, setItemChangeOverflowChange] = useState(false);
  const [ rescrollToOverflowCounter, setRescrollToOverflowCounter] = useState(0);
  useEffect(()=>{
    setItemChangeOverflowChange(true);
  }, [!!formFamilyContextItems?.length]);
  useEffect(()=>{
    if (itemChangeOverflowChange) {
      setRescrollToOverflowCounter(n=>n+1);
      setItemChangeOverflowChange(false);
    }
  }, [overflowAmount]);
  useLayoutEffect(()=>{
    // Scroll to most recent card on open
    /* This could be employed instead to target specific cards, rather than just going straight to the right
      const cardList = horizontalScrollRef.current?.querySelectorAll('.card:not(.form-adder)');
      const lastCard = cardList && cardList.length > 0 ? cardList[cardList?.length-1] : undefined;
      lastCard?.scrollIntoView({ behavior: 'auto' });
      */
    horizontalScrollRef.current?.scrollTo({ left: horizontalScrollRef.current?.scrollWidth });
  }, [rescrollToOverflowCounter]);

  return <div className={clsJn('pseudo-modal-mask', !formFamilyContextItems.length && 'd-none')} onClick={onClose}>
    <div className='pseudo-modal bg-dark p-0 d-flex' onClick={(e)=>{e.stopPropagation();}}>
      {typeof overflowAmount === 'number' && overflowAmount > 0
        ? <Button className='side-scroll-btn left' disabled={currentScroll === 0} onClick={() => {
          const elem = getFirstOutOfView('back');
          if (elem) {
            elem.scrollIntoView ({ behavior: 'smooth', inline: 'start' });
          } else {
            horizontalScrollRef.current && (horizontalScrollRef.current.scrollLeft = 0);
          }
        }}><Icon name='chevron_left' /></Button>
        : <div style={{ width: '0.75rem' }}></div>
      }
      <div ref={horizontalScrollRef} className='card-container line-container'>
        <div className='card-line'>

          {/* Be mindful that if we do a filter before the index, we will lose our index for the update function*/}
          {formFamilyContextItems.map((instance, idx) => {
            return <FormInstanceCard
              key={`${idx}:${instance.id}`}
              fCode={instance.formCode}
              instance={instance}
              mini={true}
              onOpen={()=> navigateToForm(instance.id, instance.formCode)}
            />;
          })}
          {subSuggestions?.map((fCode, idx)=><FormInstanceCard
            key={`${idx}:${fCode}`}
            onOpen={fCode === familyType.externalTerminationCode ? onExternalTermination : ()=>onFormCreate?.(fCode)}
            fCode={fCode}
            cardClass='form-adder'
            renderOpts={FormTypes[fCode].renderOpts}
            mini={true}
          />)}
        </div>
      </div>
      {typeof overflowAmount === 'number' && overflowAmount > 0
        ? <Button className='side-scroll-btn right' disabled={currentScroll >= overflowAmount} onClick={() => {
          const elem = getFirstOutOfView('forward');
          if (elem) {
            elem.scrollIntoView ({ behavior: 'smooth', inline: 'end' });
          } else {
            horizontalScrollRef.current && (horizontalScrollRef.current.scrollLeft = overflowAmount);
          }
        }}> <Icon name='chevron_right' /></Button>
        : <div style={{ width: '0.75rem' }}></div>
      }
    </div>
  </div>;
}
