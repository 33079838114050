import { PendingRemoteSigningSessionPage, RemoteSigningSessionDataField } from '@property-folders/contract';
import { renderTextSimple } from '@property-folders/common/util/image/render-text';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { asPercentage } from './common';
import { Image } from 'react-bootstrap';
import { useMemo } from 'react';

export function DataField({ page: { width: pageWidth, height: pageHeight }, field, timeZone }: { page: PendingRemoteSigningSessionPage, field: RemoteSigningSessionDataField, timeZone: string }) {
  const location = field.position;
  const asImage = useMemo(() => {
    if (!(field.type === 'ts-party' && field.value && field.value > 0)) return undefined;

    return renderTextSimple({
      text: formatTimestamp(field.value, timeZone, false),
      containingBox: field.position,
      fontSize: field.appearance?.fontSize
    });
  }, [field.type, field.value]);

  return <div
    style={{
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
      left: asPercentage(location.x, pageWidth),
      bottom: asPercentage(location.y, pageHeight),
      width: asPercentage(location.width, pageWidth),
      height: asPercentage(location.height, pageHeight)
    }}>
    {asImage && <Image src={asImage} style={{ objectFit: 'contain' }} />}
  </div>;
}
