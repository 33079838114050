import './Condition.css';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import { $createParagraphNode, $getSelection, $isRangeSelection, $isRootNode, COMMAND_PRIORITY_LOW, CLICK_COMMAND, COMMAND_PRIORITY_NORMAL, KEY_DELETE_COMMAND, SELECTION_CHANGE_COMMAND, createCommand, KEY_ARROW_DOWN_COMMAND, KEY_ARROW_LEFT_COMMAND, KEY_ARROW_RIGHT_COMMAND, KEY_ARROW_UP_COMMAND, LexicalNode, ParagraphNode, TextNode, $getRoot, COMMAND_PRIORITY_CRITICAL, $isParagraphNode } from 'lexical';
import { useEffect } from 'react';
import { $createConditionContainerNode, $isConditionContainerNode, ConditionContainerNode } from './ConditionContainerNode';
import { $isConditionContentNode, $isSelectionOnFirstLineOfCondition, $isSelectionOnLastLineOfCondition, ConditionContentNode } from './ConditionContentNode';
import { $isConditionTitleNode, ConditionTitleNode } from './ConditionTitleNode';
import { $insertNodeToNearestRootV2 } from '../utils';
import { $isLabelNode } from '../LabelNode';

export const INSERT_CONDITION_COMMAND = createCommand<{condition: string, inverse: boolean}>(
  'INSERT_CONDITION_COMMAND',
);

export default function ConditionPlugin(): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (
      !editor.hasNodes([
        ConditionContainerNode,
        ConditionTitleNode,
        ConditionContentNode
      ])
    ) {
      throw new Error('ConditionPlugin: ConditionContainerNode, ConditionTitleNode, or ConditionContentNode not registered on editor');
    }

    // Insert paragraph above when pressing arrow_up and in first child
    const $onEscapeUp = () => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) && selection.isCollapsed()) {
        const container = $findMatchingParent(selection.anchor.getNode(), $isConditionContainerNode);
        if ($isConditionContainerNode(container) && $isSelectionOnFirstLineOfCondition(selection)) {
          const parent = container.getParent();
          if (parent !== null && parent.getFirstChild() === container) {
            container.insertBefore($createParagraphNode());
          }
        }
      }
      return false;
    };

    //dont allow up/down arrow keys to move selection into the title node
    const $preventTitleNav = (e, isUp: boolean)  => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) && selection.isCollapsed()) {
        const node = selection.anchor.getNode();
        const container = $findMatchingParent(node, $isConditionContentNode);

        //we only want to skip the title node if we are at the first/last child of the condition content
        if (container && ((isUp && $isSelectionOnFirstLineOfCondition(selection)) || (!isUp && $isSelectionOnLastLineOfCondition(selection)))) {
          const parent = $findMatchingParent(node, $isConditionContainerNode);
          if (parent) {
            const sibling = isUp ? parent.getPreviousSibling() : parent.getNextSibling();
            if ($isConditionContainerNode(sibling)) {
              const content = sibling.getChildAtIndex(1);
              if ($isConditionContentNode(content)) {
                const contentChild = content.getFirstChild() as ParagraphNode;
                const textNode = contentChild.getFirstChild() as TextNode;
                textNode?.selectEnd();
                e.preventDefault();
                return true;
              }
            } else {
              sibling?.selectEnd();
              e.preventDefault();
              return true;
            }
          }
        } else {
          //not currently in a condition - skip title if arrow_down
          if (!isUp) {
            //work out the next node downwards - find the ancestor that is a child of the root, then get its sibling
            let testNode: LexicalNode = node;
            while (testNode.getParent() && !$isRootNode(testNode.getParent())) {
              testNode = testNode.getParent();
            }
            const nextSelection = testNode.getNextSibling();
            if ($isConditionContainerNode(nextSelection)) {
              const content = nextSelection.getChildAtIndex(1);
              if ($isConditionContentNode(content)) {
                const contentChild = content.getFirstChild() as ParagraphNode;
                const textNode = contentChild.getFirstChild() as TextNode;
                textNode ? textNode.selectEnd() : contentChild.selectEnd();
                e.preventDefault();
                return true;
              }
            }
          }
        }
      }
      return false;
    };

    const $onEscapeDown = () => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) && selection.isCollapsed()) {
        const container = $findMatchingParent(selection.anchor.getNode(), $isConditionContainerNode);

        if ($isConditionContainerNode(container)) {
          const parent = container.getParent();
          if (parent !== null && parent.getLastChild() === container) {
            const titleParagraph = container.getFirstDescendant();
            const contentParagraph = container.getLastDescendant();

            if (
              (contentParagraph !== null &&
                selection.anchor.key === contentParagraph.getKey() &&
                selection.anchor.offset ===
                  contentParagraph.getTextContentSize()) ||
              (titleParagraph !== null &&
                selection.anchor.key === titleParagraph.getKey() &&
                selection.anchor.offset === titleParagraph.getTextContentSize())
            ) {
              container.insertAfter($createParagraphNode());
            }
          }
        }
      }

      return false;
    };

    return mergeRegister(
      // Structure enforcing transformers for each node type. In case nesting structure is not
      // "Container > Title + Content" it'll unwrap nodes and convert it back
      // to regular content.
      editor.registerNodeTransform(ConditionContentNode, (node) => {
        const parent = node.getParent();
        if (!$isConditionContainerNode(parent)) {
          const children = node.getChildren();
          for (const child of children) {
            node.insertBefore(child);
          }
          node.remove();
        }
      }),

      editor.registerNodeTransform(ConditionTitleNode, (node) => {
        const parent = node.getParent();
        if (!$isConditionContainerNode(parent)) {
          node.replace($createParagraphNode().append(...node.getChildren()));
          return;
        }
      }),

      editor.registerNodeTransform(ConditionContainerNode, (node) => {
        const children = node.getChildren();
        if (
          children.length !== 2 ||
          !$isConditionTitleNode(children[0]) ||
          !$isConditionContentNode(children[1])
        ) {
          for (const child of children) {
            node.insertBefore(child);
          }
          node.remove();
        }
      }),

      // When collapsible is the last child pressing down/right arrow will insert paragraph
      // below it to allow adding more content. It's similar what $insertBlockNode
      // (mainly for decorators), except it'll always be possible to continue adding
      // new content even if trailing paragraph is accidentally deleted
      editor.registerCommand(KEY_ARROW_DOWN_COMMAND, $onEscapeDown, COMMAND_PRIORITY_NORMAL),

      editor.registerCommand(KEY_ARROW_RIGHT_COMMAND, $onEscapeDown, COMMAND_PRIORITY_LOW),

      // When collapsible is the first child pressing up/left arrow will insert paragraph
      // above it to allow adding more content. It's similar what $insertBlockNode
      // (mainly for decorators), except it'll always be possible to continue adding
      // new content even if leading paragraph is accidentally deleted
      editor.registerCommand(KEY_ARROW_UP_COMMAND, $onEscapeUp, COMMAND_PRIORITY_NORMAL),

      editor.registerCommand(KEY_ARROW_LEFT_COMMAND, $onEscapeUp, COMMAND_PRIORITY_LOW),

      editor.registerCommand(KEY_ARROW_UP_COMMAND, e => $preventTitleNav(e, true), COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ARROW_DOWN_COMMAND, e => $preventTitleNav(e, false), COMMAND_PRIORITY_LOW),

      editor.registerCommand(
        INSERT_CONDITION_COMMAND,
        (args) => {
          editor.update(() => {
            const node = $createConditionContainerNode(true, args.condition, args.inverse);
            $insertNodeToNearestRootV2(node);
            node.getLastChild<ConditionContentNode>()?.getFirstChild<ParagraphNode>()?.select();
          });
          return true;
        },
        COMMAND_PRIORITY_LOW,
      ),
      //insert paragraph at the end of the document when clicking on the editor and last child is a condition container and clicking below it
      editor.registerCommand(
        CLICK_COMMAND,
        (args) => {
          editor.update(() => {
            const el = args.target as HTMLElement;
            if (el?.getAttribute('data-lexical-editor')) {
              const lastChild = $getRoot()?.getLastChild();
              if ($isConditionContainerNode(lastChild)) {
                const el = editor.getElementByKey(lastChild.getKey());
                const pos = getPositionRelativeToParent(el);
                if (args.offsetY > pos.bottom) {
                  const par = $createParagraphNode();
                  lastChild?.insertAfter(par);
                  par.selectEnd();
                  args.preventDefault();
                  return true;
                }
              }
            }
          });
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      //prevent selection of nodes that shouldnt be selected (move selection to next node)
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
              const node = selection.anchor.getNode();
              if ($isLabelNode(node) || $isConditionTitleNode(node) || $isConditionContainerNode(node)) {
                node.selectNext();
              }
              if ($isConditionContentNode(node)) {
                node.getFirstChild()?.selectEnd();
              }
            }
          });
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      //handle delete on empty line with condition on next line
      editor.registerCommand(
        KEY_DELETE_COMMAND,
        (event) => {
          editor.update(() => {
            const selection = $getSelection();
            if (selection?.dirty) return false; //some other delete has already happened maybe, so ignore to prevent double deletes
            if ($isRangeSelection(selection)) {
              const node = selection.anchor.getNode();
              if ($isParagraphNode(node) && node.getTextContentSize() === 0 && $isConditionContainerNode(node.getNextSibling())) {
                node.remove();
                event.preventDefault();
                return true;
              }
            }
          });
          return false;
        },
        COMMAND_PRIORITY_LOW,
      )
    );
  }, [editor]);

  return null;
}

function getPositionRelativeToParent(element) {
  const parent = element.parentElement;
  const parentRect = parent.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return {
    top: elementRect.top - parentRect.top,
    left: elementRect.left - parentRect.left,
    bottom: elementRect.bottom - parentRect.top,
    right: elementRect.right - parentRect.left
  };
}
