import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SigningStateEntryMode } from '../SigningState';
import { useBreakpointValue } from '@property-folders/components/hooks/useBreakpointValue';
import { useMeasure } from 'react-use';
import SignatureCanvas from 'react-signature-canvas';
import { getTrimmedCanvasCopy } from '@property-folders/common/util/image/trim-canvas';
import { Button } from 'react-bootstrap';

type getSignaturePadFn = SignatureCanvas['getSignaturePad'];
type SignaturePad = ReturnType<getSignaturePadFn>;

function ModalCompatibleCanvas({
  widthRatio,
  canvasProps,
  onChange,
  isVisible
}: {
  widthRatio: number,
  canvasProps?: React.CanvasHTMLAttributes<HTMLCanvasElement> | undefined,
  onChange: (value: string) => void,
  isVisible: boolean
}) {
  const [elementRef, { width: dimensionsWidth }] = useMeasure<HTMLDivElement>();
  const bp = useBreakpointValue({ base: '1', sm: '2', md: '3', lg: '4', xl: '5', xxl: '6' }, '0');
  const sigCanvas = useRef<HTMLCanvasElement | null>(null);
  const sigPad = useRef<SignaturePad | null>(null);

  const setSignatureOnChange = () => {
    if (!sigCanvas.current) {
      onChange('');
      return;
    }
    const trimmedCopy = getTrimmedCanvasCopy(sigCanvas.current);
    onChange(trimmedCopy.toDataURL() || '');
    trimmedCopy.remove();
  };

  const clearInput = () => {
    sigPad.current?.clear();
    onChange('');
  };

  const updateCanvasDimensions = (canvas: HTMLCanvasElement | null) => {
    if (!canvas?.parentElement) return;
    const clientWidth = canvas.parentElement.clientWidth;
    canvas.width = clientWidth; // width of the .canvasWrapper
    canvas.height = clientWidth / widthRatio;
  };

  const measuredRef = useCallback((node: SignatureCanvas | null) => {
    if (node == null) {
      sigCanvas.current = null;
      sigPad.current = null;
      return;
    }

    sigCanvas.current = node.getCanvas();
    sigPad.current = node.getSignaturePad();
    updateCanvasDimensions(node.getCanvas());
    node.getSignaturePad().clear();
    onChange('');
  }, []);

  useEffect(() => {
    updateCanvasDimensions(sigCanvas.current);
  }, [widthRatio, bp, dimensionsWidth, isVisible]);

  return <div ref={elementRef} className={'w-100'}>
    <SignatureCanvas
      canvasProps={canvasProps}
      penColor={'black'}
      ref={measuredRef}
      onEnd={setSignatureOnChange}
      clearOnResize={false}
    />
    <div className={'d-flex flex-row justify-content-end gap-2'}>
      <Button variant={'link'} className={'text-dark'} onClick={clearInput}>Clear</Button>
    </div>
  </div>;
}

export function SelectSelfDrawnSignature({
  onImagesChanged,
  mode,
  isVisible
}: {
  onImagesChanged: (signatureImage: string | undefined, initialsImage: string | undefined) => void;
  mode: SigningStateEntryMode;
  isVisible: boolean;
}) {
  const [signature, setSignature] = useState<string|undefined>(undefined);
  const [initials, setInitials] = useState<string|undefined>(undefined);

  const onSignatureChange = useCallback((value: string) => {
    const valueFixed = value ? value : undefined;
    setSignature(valueFixed);
    onImagesChanged(valueFixed, initials);
  }, []);
  const onInitialsChange = useCallback((value: string) => {
    const valueFixed = value ? value : undefined;
    setInitials(valueFixed);
    onImagesChanged(signature, valueFixed);
  }, []);
  const canvasProps = useMemo(() => {
    return {
      style: {
        border: '1px solid lightgray'
      }
    };
  }, []);

  return <div className={'mt-3 d-flex flex-row justify-content-between gap-1 select-self-drawn-signature'}>
    {mode === SigningStateEntryMode.AdoptSignature &&
      <ModalCompatibleCanvas
        widthRatio={4}
        isVisible={isVisible}
        onChange={onSignatureChange}
        canvasProps={canvasProps}
      />}
    {mode === SigningStateEntryMode.AdoptInitials &&
      <ModalCompatibleCanvas
        widthRatio={1.25}
        isVisible={isVisible}
        onChange={onInitialsChange}
        canvasProps={canvasProps}
      />}
  </div>;
}
