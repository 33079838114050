import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { Icon } from '../../dragged-components/Icon';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { Button, ButtonProps } from 'react-bootstrap';
import clsJn from '@property-folders/common/util/classNameJoin';
import { useYdocBinder } from '../../hooks/useYdocBinder';

export type TwoIconToggleProps = TransactionConsumerProps & {
  presentIcon: string,
  absentIcon: string,
  watchString: string,
  className: ButtonProps['className']
};

export function TwoIconToggleListItemPresent({ presentIcon, absentIcon, watchString, className, ...binderProps }: TwoIconToggleProps) {
  const { fullPath, value: presentList } = useLightweightTransaction<string[]>(binderProps);

  const { updateDraft } = useYdocBinder<string[]>({ path: fullPath, buildParents: true });
  const isPresent = (presentList??[]).includes(watchString);

  const handleToggle = () => {

    updateDraft?.(draft => {
      if (!Array.isArray(draft)) {
        console.error('Could not operate on non-list');
        return;
      }
      if (draft.includes(watchString)) {
        draft.splice(draft.findIndex(i=>i===watchString),1);
        return;
      }
      draft.push(watchString);

    });
  };
  return <Button
    variant='light'
    className={clsJn('btn-list-action coll-del-button form-floating', className)}
    onClick={handleToggle}
  ><Icon name={isPresent?presentIcon:absentIcon} /></Button>;
}