import { DocumentType } from "@property-folders/contract/rest/document";
import { Badge } from "react-bootstrap";

export function DocumentTypeBadge({ type }: { type: DocumentType }) {
  switch (type) {
    case 'Subscription':
      return <Badge bg='subscription' >reaforms</Badge>;
    case 'MyFile':
      return <Badge bg='my-file'>SignAnything</Badge>;
    case 'Property':
      return <Badge bg='property'>Greatforms</Badge>;
  }

  return <></>;
}
