import { useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { getPreviousNoticeOptions } from '@property-folders/common/data-and-text/constants';
import '../Form.scss';
import { CollectionEditor } from './CollectionEditor';
import { WrField } from './CommonComponentWrappers';
import { NarrowAgentInput } from './NarrowAgentInput';

export const PreviousAgentInput = ({  ...restProps }: TransactionConsumerProps): JSX.Element => {
  const { value: transRoot } = useTransactionField({ parentPath: restProps.parentPath, myPath: '' });

  const noticeOptions = getPreviousNoticeOptions(transRoot?.prevAgentConfirm);

  return (
    <div className="d-flex w-100">
      <div className='w-100'>
        <div className="d-flex w-100 flex-wrap gapped-row">

          <div className='flex-grow-1'>
            <WrField.CheckRadio
              label='Any previous agents?'
              radioType='checkbox'
              options={{ 'true': 'Yes', 'false': 'No' }}
              valueType='boolean'
              inline={true}
              name='prevAgentEnable'
              myPath='prevAgentEnable'
            />
          </div>
          {transRoot?.prevAgentEnable && <div className='flex-grow-1'>
            <WrField.CheckRadio
              label='Will the Vendor confirm which previous agents?'
              radioType='checkbox'
              options={{ 'true': 'Yes', 'false': 'No' }}
              valueType='boolean'
              inline={true}
              name='prevAgentConfirm'
              myPath='prevAgentConfirm'
            />
          </div>}

        </div>
        {transRoot?.prevAgentEnable && <div className='mt-2'>
          {transRoot?.prevAgentConfirm && <CollectionEditor
            title='Confirmed previous agents'
            level={3}
            myPath="prevAgents"
            autoAddFirst={true}
            allowAdd={true}
            compact={false}
            childProps={{ noPersonAuto: true, manageSalesRepresentatives: false }}
            childItemRenderer={NarrowAgentInput}
            itemNoun='Agent'
            restorationFieldDisplay='company'
            addTooltip='Add agents known to have previously represented this Vendor in the sale of this Property'
          />}
          <div className="d-flex w-100 flex-wrap gapped-row">
            <div className='flex-grow-1'>
              <WrField.CheckRadio
                label='Previous agency agreements expired?'
                radioType='checkbox'
                options={{ 'true': 'Yes', 'false': 'No' }}
                valueType='boolean'
                inline={true}
                name='prevAgentExpire'
                myPath='prevAgentExpire'
              />
            </div>
          </div>
          {typeof transRoot?.prevAgentExpire === 'boolean' && !transRoot?.prevAgentExpire && <div className="d-flex w-100 flex-wrap gapped-row">
            <div className='flex-grow-1'>
              <WrField.CheckRadio
                label='Written termination to previous agent(s) given?'
                radioType='checkbox'
                options={noticeOptions}
                inline={false}
                name='prevAgentNotice'
                myPath='prevAgentNotice'
              />
            </div>
          </div>}
        </div>}
      </div>
    </div>
  );
};
