import './SigningFieldsConfiguration.scss';
import {
  CustomFieldConfiguration,
  FolderType,
  FormCodeUnion,
  FormInstance,
  MaterialisedPropertyData,
  PropertyRootKey,
  SigningParty,
  SigningPartyTypeOptions,
  TransactionMetaData
} from '@property-folders/contract/yjs-schema/property';
import {
  CustomFieldMetaGroup,
  customFieldMetas,
  defaultFontSize,
  defaultLineHeight
} from '@property-folders/contract/property/meta';
import { SigningConfigurationProps } from '../SigningConfiguration';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, ListGroup, Modal } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { DroppablePositioned } from './DroppablePositioned';
import { DraggablePositioned } from './DraggablePositioned';
import clsJn from '@property-folders/common/util/classNameJoin';
import { uuidv4 } from 'lib0/random';
import { useLightweightTransaction, useTransactionField } from '../../../hooks/useTransactionField';
import {
  FormUtil,
  getSigningOrderVersion,
  PartySnapshotLoader,
  SigningOrderVersion
} from '@property-folders/common/util/form';
import { MultiBackend, MultiBackendOptions, PointerTransition, TouchTransition } from 'react-dnd-multi-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { CreateCustomFieldDetails, CustomFieldDetails, DraggableType, ExistingCustomFieldDetails } from './types';
import { CreateCustomField, CustomFieldPartyInfo, ExistingCustomField } from './CustomField';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DragLayer } from './DragLayer';
import Select from 'react-select';
import { BinderFn, useImmerYjs } from '../../../hooks/useImmerYjs';
import { noBubble } from '@property-folders/portal/src/util';
import { Icon } from '../../Icon';
import { useLiveQuery } from 'dexie-react-hooks';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { PageDimensions, PDFViewer, PDFViewerRefProps, ZoomMode } from '../../PDFViewer/PDFViewer';
import { SetupPdfLoadStateContext } from '../../../context/pdfLoadStateContext';
import { clamp, upperFirst } from 'lodash';
import { ContentType, CustomFieldType, FieldPosition, Maybe } from '@property-folders/contract';
import { CustomFieldAttributesEditor, setCustomAttribute } from './CustomFieldAttributesEditor';
import { CoordinateMath, Rect } from '@property-folders/common/util/coords';
import { getUploadAsV2, materialisePropertyData } from '@property-folders/common/yjs-schema/property';
import { PDFMinimap } from '../../PDFViewer/PDFMinimap';
import { PdfInformationExtractor, TextDimensionEstimator } from '@property-folders/common/util/pdf';
import {
  FormTypes,
  getOrderedParties,
  mapSigningPartySourceTypeToCategoryRespectingOverride
} from '@property-folders/common/yjs-schema/property/form';
import { adjustRectToMinDimensions, AttrDefaultLoader, autoResize, getNextGroupId, safeGenerateCustomField } from './common';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useBrandConfig, useEntities } from '../../../hooks/useEntity';
import { PlonkLayer } from './PlonkLayer';
import { clickNoBubble } from '@property-folders/common/util/clickNoBubble';
import { CustomFieldFilter, getCustomFieldsFilterWhenCoveringFor } from '@property-folders/common/yjs-schema/property/cover-sheet-customisation';
import {
  loadFormUploadContents,
  makeMergedFormUploadPdfData,
  MergeProcessFileMeta
} from '@property-folders/common/util/upload-forms';
import { usePdfWorker } from '../../../hooks/usePdfWorker';
import { FieldType, parseFieldName } from '@property-folders/common/signing/pdf-form-field';
import { v4 } from 'uuid';

const scaleValue = CoordinateMath.scaleValue;
const dndBackends: MultiBackendOptions = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: PointerTransition
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      transition: TouchTransition
    }
  ]
};

const allFields = (Object.keys(CustomFieldType) as CustomFieldType[]);
const signingFields = allFields.filter(key => customFieldMetas[key].displayGroup === 'signing');
const completionFields = allFields.filter(key => customFieldMetas[key].displayGroup === 'completion');
const contactFields = allFields.filter(key => customFieldMetas[key].displayGroup === 'contact');
const propertyFields = allFields.filter(key => customFieldMetas[key].displayGroup === 'property');
const serveFields = allFields.filter(key => customFieldMetas[key].displayGroup === 'serve');
const otherFields = allFields.filter(key => customFieldMetas[key].displayGroup === 'other');

const defaultCustomFieldDisplay: Record<CustomFieldMetaGroup, 'full' | 'restricted' | 'hidden'> = {
  signing: 'full',
  completion: 'full',
  property: 'full',
  contact: 'full',
  other: 'full',
  serve: 'hidden'
};

const requiresRemoteFeatureFlag = [CustomFieldType.remoteText, CustomFieldType.remoteCheck, CustomFieldType.remoteRadio];

function decideCustomFieldDisplay(
  formCode: FormCodeUnion,
  isFirstParty: boolean,
  remoteFeatureFlag: boolean,
  signingOrderVersion: number,
  filterFn: CustomFieldFilter,
  // user's private file
  folderType: FolderType
): Record<CustomFieldMetaGroup, CustomFieldType[]> {
  const base = FormTypes[formCode]?.customiseFieldTypes;
  const withinProperty = folderType === FolderType.Property;
  const config = {
    signing: base?.signing || defaultCustomFieldDisplay.signing,
    property: withinProperty
      ? (base?.property || defaultCustomFieldDisplay.property)
      : 'hidden',
    contact: base?.contact || defaultCustomFieldDisplay.contact,
    other: base?.other || defaultCustomFieldDisplay.other,
    serve: base?.serve || defaultCustomFieldDisplay.serve
  };
  const filterFields = (fields: CustomFieldType[]) => fields.filter(filterFn);

  return {
    signing: filterFields(signingFields.filter(key => {
      if (config.signing === 'hidden') return false;
      if (config.signing === 'restricted' && customFieldMetas[key].displayRestricted) return false;
      if (customFieldMetas[key].firstPartyOnly && (!isFirstParty || signingOrderVersion !== SigningOrderVersion.Flat)) return false;
      if (!remoteFeatureFlag && requiresRemoteFeatureFlag.includes(key)) return false;
      if (!withinProperty && key === CustomFieldType.authority) return false;
      return true;
    })),
    completion: filterFields(completionFields.filter(key => {
      if (config.signing === 'hidden') return false;
      if (config.signing === 'restricted' && customFieldMetas[key].displayRestricted) return false;
      if (customFieldMetas[key].firstPartyOnly && (!isFirstParty || signingOrderVersion !== SigningOrderVersion.Flat)) return false;
      if (!remoteFeatureFlag && requiresRemoteFeatureFlag.includes(key)) return false;
      if (!withinProperty && key === CustomFieldType.authority) return false;
      return true;
    })),
    property: filterFields(config.property === 'full' ? propertyFields : []),
    contact: filterFields(config.contact === 'full' ? contactFields : []),
    other: filterFields(config.other === 'full' ? otherFields : []),
    serve: filterFields(config.serve === 'full' ? serveFields : [])
  };
}

const staticFieldTypesWeCareAbout = [
  FieldType.FieldTimestamp,
  FieldType.FieldValue,
  FieldType.FieldWitness,
  FieldType.PartyTimestamp,
  FieldType.System
];
type StaticField = CustomFieldConfiguration & {
  staticOpts?: {
    hoverReveal?: boolean
  }
};

export function SigningFieldsConfiguration({
  formCode,
  formId,
  ydoc,
  yMetaRootKey = PropertyRootKey.Meta
}: SigningConfigurationProps) {
  const { value: data } = useTransactionField<MaterialisedPropertyData>({
    myPath: ''
  });
  const { value: instance, fullPath: formPath } = useLightweightTransaction<FormInstance>({
    parentPath: FormUtil.getFormPath(formCode, formId),
    bindToMetaKey: true
  });
  const { value: parties } = useTransactionField<SigningParty[]>({
    parentPath: formPath,
    myPath: 'signing.parties',
    bindToMetaKey: true
  });
  const {
    value: customFields
  } = useLightweightTransaction<CustomFieldConfiguration[]>({
    parentPath: formPath,
    myPath: 'signing.customFields',
    bindToMetaKey: true
  });
  const {
    value: customFieldDefaults
  } = useLightweightTransaction<Record<string, any>>({
    parentPath: formPath,
    myPath: 'signing.customFieldDefaults',
    bindToMetaKey: true
  });
  const { orderedParties, firstOrderedParty } = useMemo(() => {
    const orderedParties = getOrderedParties(parties, instance?.signing, formCode as FormCodeUnion) || [];
    return {
      orderedParties,
      firstOrderedParty: orderedParties.at(0)
    };
  }, [parties, instance]);

  const memberEntities = useEntities() ?? {};
  const {
    bindState: metaBindState
  } = useImmerYjs<TransactionMetaData>(ydoc, yMetaRootKey);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const worker = usePdfWorker();
  const formConfigs = useBrandConfig();
  const brand = formConfigs.getFormConfig(meta?.entity?.id);

  const { uploadedFileUrl, segments, deletedPageIndexes, coverPageIndexes, disablePageActions, staticFields } = useLiveQuery<{
    uploadedFileUrl?: string,
    segments: MergeProcessFileMeta[],
    deletedPageIndexes: Set<number>,
    coverPageIndexes: Set<number>,
    disablePageActions?: boolean,
    staticFields: StaticField[]
  }>(async () => {
    if (instance?.signing?.preSigningCustomisationSessionSnapshot?.file?.id) {
      // const uploadedFileUrl = URL.createObjectURL(new Blob)
      const fileData = (await FileStorage.read(instance.signing.preSigningCustomisationSessionSnapshot.file.id))?.data;
      if (fileData) {
        const preSigningFields = instance.signing.preSigningCustomisationSessionSnapshot.fields ?? [];
        const getPartyIdForFieldId = (fieldId: string) => preSigningFields.find(f => f.id === fieldId)?.partyId || '';
        return {
          uploadedFileUrl: URL.createObjectURL(fileData),
          coverPageIndexes: new Set(),
          deletedPageIndexes: new Set(),
          // todo: do we need this?
          segments: [],
          disablePageActions: true,
          staticFields: await new PdfInformationExtractor(await fileData.arrayBuffer()).extractPredefinedFieldsGeneric<StaticField>(
            field =>  {
              const type = parseFieldName(field.id)?.type;
              return type != null && staticFieldTypesWeCareAbout.includes(type);
            },
            (_, rect, field, page, __) => {
              const parsed = parseFieldName(field.id);
              if (!parsed) return undefined;
              const position: FieldPosition = {
                ...rect,
                page
              };
              const appearanceStuff = {
                fontSize: field.appearance?.fontSize || defaultFontSize,
                lineHeight: field.appearance?.fontSize || defaultLineHeight,
                fontColour: '#000000',
                bg: Boolean(field.appearance?.backgroundColour)
              };
              switch (parsed.type) {
                case FieldType.FieldTimestamp:
                  return {
                    id: v4(),
                    // note: we don't really have a field timestamp custom field type, so this will have to do
                    type: CustomFieldType.timestamp,
                    position,
                    partyId: getPartyIdForFieldId(parsed.fieldId),
                    ...appearanceStuff
                  };
                case FieldType.FieldValue:
                  return {
                    id: v4(),
                    type: CustomFieldType.signature,
                    position,
                    partyId: getPartyIdForFieldId(parsed.fieldId),
                    ...appearanceStuff
                  };
                case FieldType.FieldWitness:
                  return {
                    id: v4(),
                    // note: we don't have a witness custom field type
                    type: CustomFieldType.text,
                    text: 'Witness',
                    position,
                    partyId: getPartyIdForFieldId(parsed.fieldId),
                    ...appearanceStuff
                  };
                case FieldType.PartyTimestamp:
                  return {
                    id: v4(),
                    type: CustomFieldType.timestamp,
                    position,
                    partyId: parsed.partyId,
                    ...appearanceStuff
                  };
                case FieldType.Serve:
                  return {
                    id: v4(),
                    type: (() => {
                      switch (parsed.fieldId) {
                        case 'PURCHASER_NAME':
                          return CustomFieldType.purchaserName;
                        case 'PURCHASER_ADDRESS':
                          return CustomFieldType.purchaserAddress;
                        case 'CONTRACT_DATE':
                          return CustomFieldType.contractDate;
                      }
                    })(),
                    position,
                    partyId: getPartyIdForFieldId(parsed.fieldId),
                    ...appearanceStuff
                  };
                case FieldType.System:
                  return {
                    id: v4(),
                    type: CustomFieldType.text,
                    text: (() => {
                      switch (parsed.fieldId) {
                        case 'DATE_OF_AGREEMENT':
                          return 'Agreement Date';
                        case 'DATE_OF_EXPIRY':
                          return 'Expiry Date';
                        case 'DATE_OF_FILL':
                          return 'Fill Date';
                        case 'SIGNING_AND_DATE':
                        case 'SIGNING_STATUS':
                          return 'Signing Status';
                        case 'NO_OP':
                          return 'System Field';
                      }
                    })(),
                    position,
                    ...appearanceStuff,
                    staticOpts: {
                      hoverReveal: true
                    }
                  };
              }
            }
          )
        };
      } else {
        return {
          segments: [],
          deletedPageIndexes: new Set(),
          coverPageIndexes: new Set(),
          staticFields: []
        };
      }
    }

    const upload = getUploadAsV2(instance?.upload, instance?.created);
    if (!upload) {
      return {
        segments: [],
        deletedPageIndexes: new Set(),
        coverPageIndexes: new Set(),
        staticFields: []
      };
    }

    const segmentsMeta: MergeProcessFileMeta[] = [];
    // final product indexes
    const deletedPageIndexes = new Set<number>();
    const uploadedFileUrl = URL.createObjectURL(new Blob([
      await makeMergedFormUploadPdfData({
        files: await loadFormUploadContents({
          form: instance,
          fnGetFile: id => FileStorage.read(id).then(result => result?.data)
        }),
        destructive: false,
        onFileMeta: (meta, actions) => {
          segmentsMeta.push(meta);
          actions
            ?.filter(a => a.action === 'delete')
            ?.map(a => a.pageIndex + meta.start)
            ?.forEach(deletedPage => deletedPageIndexes.add(deletedPage));
        },
        fns: {
          makeCover: (title: string) => {
            return worker.generateCoverPage({
              formType: 'simple',
              documentLabel: '',
              headline: title,
              agentName: '',
              meta: {
                documentLabel: '',
                headline: title,
                agentName: ''
              },
              brand,
              noBoldContentMode: true
            });
          }
        }
      })
    ], { type: ContentType.Pdf }));

    return {
      uploadedFileUrl,
      segments: segmentsMeta,
      deletedPageIndexes,
      coverPageIndexes: new Set(segmentsMeta.filter(s => s.isCover).map(s => s.start)),
      staticFields: []
    };

    // may need to revisit the dependencies of this effect
  }, [instance?.upload, instance?.signing?.preSigningCustomisationSessionSnapshot?.file?.id]) || { segments: [], deletedPageIndexes: new Set() };

  const { binder } = useImmerYjs<TransactionMetaData>(ydoc, yMetaRootKey);
  const partyInfos = useMemo<CustomFieldPartyInfo[]>(() => {
    if (!ydoc) return [];

    const snapshotLoader = new PartySnapshotLoader(
      FormUtil.getSigningSessionSignatureSnapshots(orderedParties, data, { memberEntities }),
      orderedParties,
      memberEntities,
      sublineageId => materialisePropertyData(ydoc, sublineageId)
    );
    const result = new Map<string, CustomFieldPartyInfo>();
    for (const party of orderedParties) {
      const snap = snapshotLoader.getSnapshot(party);
      if (snap) {
        result.set(party.id, {
          id: party.id,
          type: party.type,
          colour: party?.colour || 'white',
          category: mapSigningPartySourceTypeToCategoryRespectingOverride(party.source),
          snapshot: snap,
          isFirstOrdered: Boolean(firstOrderedParty?.id && firstOrderedParty.id === party.id)
        });
      }
    }

    return [...result.values()];
  }, [data, orderedParties, !!ydoc, firstOrderedParty?.id, memberEntities]);
  const [selectedParty, setSelectedParty] = useState<CustomFieldPartyInfo | undefined>(partyInfos.at(0));
  const [selectedFieldId, setSelectedFieldId] = useState('');
  const [plonkable, setPlonkable] = useState<Maybe<CreateCustomFieldDetails>>(undefined);
  const [canPlonk, setCanPlonk] = useState<boolean>(false);
  const [showContext, setShowContext] = useState<{
    x: number,
    y: number,
    id: string,
    target: Element,
    // note, this is a snapshot of the field
    field: CustomFieldConfiguration,
    pdfDimensions: PageDimensions
  } | undefined>(undefined);
  const { selectedField, groupBoxes } = useMemo<{
    selectedField?: CustomFieldConfiguration,
    groupBoxes?: Record<string, GroupBoxDimensions | undefined>
  }>(() => {
    if (!customFields) return {};

    const selectedField = selectedFieldId ? customFields?.find(cf => cf.id === selectedFieldId) : undefined;
    if (selectedField && 'group' in selectedField && selectedField.group) {
      const group = selectedField.group;
      const type = selectedField.type;
      const groupBoxes: Record<string, {minX: number, minY: number, maxX: number, maxY: number} | undefined> = {};
      const groupFields = customFields
        .filter(cf => 'group' in cf
          && cf.group
          && cf.group === group
          && cf.type === type);
      if (groupFields.length <= 1) {
        return { selectedField };
      }

      groupFields.forEach(cf => {
        const { y, x, width, height, page } = cf.position;
        const match = groupBoxes[page];
        if (match) {
          match.minY = Math.min(match.minY,y, y+height);
          match.maxY = Math.max(match.maxY,y, y+height);
          match.minX = Math.min(match.minX,x, x+width);
          match.maxX = Math.max(match.maxX,x, x+width);
        } else {
          groupBoxes[cf.position.page] = {
            minY: Math.min(y, y+height),
            maxY: Math.max(y, y+height),
            minX: Math.min(x, x+width),
            maxX: Math.max(x, x+width)
          };
        }
      });

      if (Object.keys(groupBoxes).length) {
        return {
          selectedField,
          groupBoxes: Object.fromEntries(Object.entries(groupBoxes)
            .map<[string, GroupBoxDimensions | undefined]>(([key, value]) => [key,
              value ? {
                x: value.minX,
                y: value.minY,
                width: value.maxX - value.minX,
                height: value.maxY - value.minY
              } : undefined
            ]))
        };
      }
    }

    return {
      selectedField
    };
  }, [selectedFieldId, customFields]);
  if (groupBoxes) {
    console.log('groupBox', groupBoxes);
  }
  const [viewerRef, setViewerRef] = useState<PDFViewerRefProps | null>(null);
  const estimator = useMemo(() => new TextDimensionEstimator(), []);

  const deleteSelected = useCallback(() => {
    if (!selectedFieldId) return;
    if (!binder) return;
    binder.update(draft => {
      const signing = FormUtil.getSigning(formCode, formId, draft);
      if (!signing?.customFields) return;
      const idx = signing.customFields.findIndex(cf => cf.id === selectedFieldId);
      if (idx < 0) return;
      signing.customFields.splice(idx, 1);
    });
    setSelectedFieldId('');
  }, [selectedFieldId, binder]);

  const translateSelected = useCallback((dX: number, dY: number) => {
    if (!selectedFieldId) return false;
    if (!binder) return false;
    if (!viewerRef) return false;
    if (!selectedField) return false;

    const { height, width, x, y, page: pageIndex } = selectedField.position;
    const pdfDimensions = viewerRef?.getPageDimensions(pageIndex);
    if (!pdfDimensions) return false;
    const pageStaticFields = (staticFields || []).filter(sf => sf.position.page === pageIndex);
    if (!isPositionAllowed(
      clampFieldPosition({
        height,
        width,
        x: x + dX,
        y: y + dY
      }, pdfDimensions),
      pageStaticFields,
      selectedField.type
    )) return true;

    binder.update(draft => {
      const signing = FormUtil.getSigning(formCode, formId, draft);
      if (!signing?.customFields) return;
      const match = signing.customFields.find(cf => cf.id === selectedFieldId);
      if (!match) return;

      // don't want to mutate the underlying data when checking if allowed, so clampFieldPosition must be called twice
      if (!isPositionAllowed(clampFieldPosition({
        height: match.position.height,
        width: match.position.width,
        x: match.position.x + dX,
        y: match.position.y + dY
      }, pdfDimensions), pageStaticFields, match.type)) return;

      // just apply the increments directly, don't apply scaling.
      match.position.x += dX;
      match.position.y += dY;

      clampFieldPosition(match.position, pdfDimensions);
    });
    return true;
  }, [selectedFieldId, binder, viewerRef, selectedField]);

  const checkAddField = (item: CustomFieldDetails, location: { x: number, y: number }, zoneRect: DOMRect, pageIndex: number, page?: PageDimensions) => {
    if (!page) return false;
    if (item?.mode !== DraggableType.Create) return false;
    if (!binder) return false;
    const scaleX = (value: number) => scaleValue(value, zoneRect.width, page.width);
    const scaleY = (value: number) => scaleValue(value, zoneRect.height, page.height);
    const newCf = safeGenerateCustomField({
      field: item,
      partyId: selectedParty?.id,
      position: {
        page: pageIndex,
        // note: may need to perform some translation from UI coordinate space to pdf page's coordinate space.
        x: scaleX(location.x),
        y: scaleY(location.y),
        width: scaleX(item.width || 100),
        height: scaleY(item.height || 25)
      },
      newId: '',
      attrs: new AttrDefaultLoader(customFieldDefaults || {}),
      party: partyInfos.find(p => p.id === selectedParty?.id),
      data,
      estimator,
      existingFields: customFields
    });
    if (!newCf) return false;

    clampFieldPosition(newCf.position, page);
    return isPositionAllowed(newCf, (staticFields || []).filter(sf => sf.position.page === newCf.position.page), newCf.type);
  };

  const addField = (item: CustomFieldDetails, location: { x: number, y: number }, zoneRect: DOMRect, pageIndex: number, page?: PageDimensions) => {
    if (!page) return;
    if (item?.mode !== DraggableType.Create) return;
    if (!binder) return;
    const scaleX = (value: number) => scaleValue(value, zoneRect.width, page.width);
    const scaleY = (value: number) => scaleValue(value, zoneRect.height, page.height);
    const newCf = safeGenerateCustomField({
      field: item,
      partyId: selectedParty?.id,
      position: {
        page: pageIndex,
        // note: may need to perform some translation from UI coordinate space to pdf page's coordinate space.
        x: scaleX(location.x),
        y: scaleY(location.y),
        width: scaleX(item.width || 100),
        height: scaleY(item.height || 25)
      },
      newId: uuidv4(),
      attrs: new AttrDefaultLoader(customFieldDefaults || {}),
      party: partyInfos.find(p => p.id === selectedParty?.id),
      data,
      estimator,
      existingFields: customFields
    });
    if (!newCf) return;

    clampFieldPosition(newCf.position, page);
    if (!isPositionAllowed(newCf, (staticFields || []).filter(sf => sf.position.page === newCf.position.page), newCf.type)) {
      console.log('addField blocked due to static field overlap');
      return;
    }

    binder.update(draft => {
      const signing = FormUtil.getSigning(formCode, formId, draft);
      if (!signing) return;
      if (!signing.customFields) {
        signing.customFields = [];
      }
      signing.customFields.push(newCf);
    });
    return newCf.id;
  };

  const cloneField = useCallback((
    id: string,
    page: PageDimensions,
    mutateFn?: (
      clone: CustomFieldConfiguration,
      opts: {
        original: CustomFieldConfiguration
        fields: CustomFieldConfiguration[]
      }
    ) => void
  ) => {
    if (!binder) return undefined;
    let newId: string | undefined;
    binder.update(draft => {
      const signing = FormUtil.getSigning(formCode, formId, draft);
      if (!signing) return;
      if (!signing.customFields) {
        signing.customFields = [];
      }
      const original = signing.customFields.find(cf => cf.id === id);
      if (!original) return;
      newId = uuidv4();
      const clone = JSON.parse(JSON.stringify(original)) as CustomFieldConfiguration;
      clone.id = newId;
      mutateFn?.(clone, { original, fields: signing.customFields });
      clampFieldPosition(clone.position, page);
      signing.customFields.push(clone);
    });
    return newId;
  }, [binder]);

  const cloneRadio = useCallback((id: string, page: PageDimensions) => {
    return cloneField(id, page, (clone, opts) => {
      if (clone.type === CustomFieldType.remoteRadio) {
        delete clone.on;
      }
      autoResize(clone, { estimator });
      // "append" the clone to the bottom of the original.
      clone.position.y = opts.original.position.y + opts.original.position.height;
    });
  }, [cloneField]);

  const cloneCheckbox = useCallback((id: string, page: PageDimensions) => {
    return cloneField(id, page, (clone, opts) => {
      if ('group' in clone && clone.group) {
        clone.group = getNextGroupId(customFields || [], 'check-');
      }
      autoResize(clone, { estimator });
      // "append" the clone to the bottom of the original.
      clone.position.y = opts.original.position.y + opts.original.position.height;
    });
  }, [cloneField, customFields]);

  useEffect(() => {
    const unselectHandler = () => {
      setSelectedFieldId('');
    };
    document.body.addEventListener('click', unselectHandler);

    return () => {
      document.body.removeEventListener('click', unselectHandler);
    };
  }, []);

  useEffect(() => {
    const translationsMap: Record<string, [number, number]> = {
      ArrowDown: [0, 1],
      ArrowUp: [0, -1],
      ArrowLeft: [-1, 0],
      ArrowRight: [1, 0]
    };
    const keyHandler = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Backspace':
        case 'Delete':
          deleteSelected();
          break;
        case 'Escape':
          setPlonkable(undefined);
          break;
        default: {
          const translation = translationsMap[e.key];
          if (translation && translateSelected(...translation)) {
            e.preventDefault();
            break;
          }
          break;
        }
      }
    };
    document.body.addEventListener('keydown', keyHandler);

    return () => {
      document.body.removeEventListener('keydown', keyHandler);
    };
  }, [deleteSelected, translateSelected]);

  useEffect(() => {
    if (!showContext) return;
    // hook into body click event to clear context
    const handler = () => {
      setShowContext(undefined);
    };
    document.body.addEventListener('click', handler);
    document.body.addEventListener('contextmenu', handler);
    return () => {
      document.body.removeEventListener('click', handler);
      document.body.removeEventListener('contextmenu', handler);
    };
  }, [!!showContext]);

  const viewerRefCallback = useCallback((newRef: PDFViewerRefProps) => {
    setViewerRef(newRef);
  }, []);
  const pdfScrollContainer = useRef<HTMLElement>(null);

  const drawCustomFieldTypeBlock = (title: string, fields: CustomFieldType[], makeItem: (type: CustomFieldType) => CreateCustomFieldDetails) => {
    if (!fields.length) {
      return <></>;
    }
    return <>
      <h5 className='m-0'>{title}</h5>
      {fields.map((type) => {
        const item = makeItem(type);
        if (customFieldMetas[type].firstPartyOnly && !instance?.signing?.useSigningOrder && getSigningOrderVersion(instance?.signing) !== SigningOrderVersion.Flat) {
          return <div className='w-100' style={{ cursor: 'not-allowed' }}>
            <CreateCustomField
              details={item}
              parties={partyInfos}
              dragging={false}
              fillWidth={true}
              disableReason={'Parties must be invited individually to use this field.'}
            />
          </div>;
        }
        const onlyPartyTypes = customFieldMetas[type].onlyPartyTypes;
        if (onlyPartyTypes && (!selectedParty || !onlyPartyTypes.includes(selectedParty.type))) {
          const signingMethod = selectedParty
            ? SigningPartyTypeOptions[selectedParty.type]
            : 'unknown';
          return <div className='w-100' style={{ cursor: 'not-allowed' }}>
            <CreateCustomField
              details={item}
              parties={partyInfos}
              dragging={false}
              fillWidth={true}
              disableReason={`The party's configured signing method is not supported (${signingMethod}).`}
            />
          </div>;
        }

        return (
          <DraggablePositioned<CreateCustomFieldDetails>
            key={`create-${type}`}
            type={DraggableType.Create}
            item={item}
          >
            {(provided) => (<div
              ref={provided.innerRef}
              className={clsJn('draggable w-100')}
              onClick={clickNoBubble(() => setPlonkable(item))}
            >
              <CreateCustomField
                details={item}
                parties={partyInfos}
                dragging={false}
                fillWidth={true}
              />
            </div>
            )}
          </DraggablePositioned>
        );
      })}
    </>;
  };

  const { pfRemoteCompletion } = useFeatureFlags();

  const isMyFile = meta?.folderType === FolderType.MyFile;
  const {
    signing,
    completion,
    contact,
    serve,
    property,
    other
  } = useMemo(() => decideCustomFieldDisplay(
    formCode as FormCodeUnion,
    Boolean(firstOrderedParty?.id && firstOrderedParty.id === selectedParty?.id),
    Boolean(pfRemoteCompletion),
    getSigningOrderVersion(instance?.signing),
    getCustomFieldsFilterWhenCoveringFor(instance?.upload?.editableAsCoverFor),
    meta?.folderType || FolderType.Property
  ), [formCode, firstOrderedParty?.id, selectedParty?.id, pfRemoteCompletion, instance?.signing?.signingOrderVersion]);

  useEffect(() => {
    if (!plonkable) return;
    const handler = () => {
      setPlonkable(undefined);
    };
    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, [!!plonkable]);

  return <div className='overflow-auto d-flex flex-row h-100'>
    {!!showContext && <div
      className='bg-dark light'
      style={{ position: 'fixed', top: `${showContext.y}px`, left: `${showContext.x}px`, zIndex: 1000 }}
    >
      <ListGroup>
        {showContext.field.type === CustomFieldType.remoteRadio && <ListGroup.Item action onClick={noBubble(() => {
          if (!showContext.field) return;
          const id = cloneRadio(showContext.field.id, showContext.pdfDimensions);
          setSelectedFieldId(id || '');
          setShowContext(undefined);
        })}>Add option</ListGroup.Item>}
        {showContext.field.type === CustomFieldType.remoteCheck && <ListGroup.Item action onClick={noBubble(() => {
          if (!showContext.field) return;
          const id = cloneCheckbox(showContext.field.id, showContext.pdfDimensions);
          setSelectedFieldId(id || '');
          setShowContext(undefined);
        })}>Clone</ListGroup.Item>}
        <ListGroup.Item action onClick={() => {
          deleteSelected();
          setSelectedFieldId('');
          setShowContext(undefined);
        }}>Remove</ListGroup.Item>
      </ListGroup>
    </div>}
    <DndProvider backend={MultiBackend} options={dndBackends}>
      <PlonkLayer
        parties={partyInfos}
        property={data}
        plonkable={plonkable}
        contentScale={1}
      />
      <DragLayer
        parties={partyInfos}
        property={data}
        dragToScrollContainer={pdfScrollContainer?.current}
        onDragStart={() => setPlonkable(undefined)}
      />
      <DroppablePositioned<ExistingCustomFieldDetails>
        // if we want to allow dragging back to delete, then populate with DraggableType.Existing type
        accept={[]}
        getDroppedItemDimensions={item => ({ width: item.position.width, height: item.position.height })}
        onDrop={(_, item) => {
          if (!binder) return;
          binder.update(draft => {
            const signing = FormUtil.getSigning(formCode, formId, draft);
            if (!signing?.customFields) return;
            const index = signing.customFields.findIndex(x => x.id === item.id);
            if (index >= 0) {
              signing.customFields.splice(index, 1);
            }
          });
        }}>
        {(provided) => (<Col
          ref={provided.innerRef}
          md={2}
          className={clsJn(
            'd-flex flex-column align-items-center justify-space-between',
            provided.canDrop && provided.isOver && 'droppable-over cursor-delete'
          )}
        >
          <div
            className={clsJn('d-flex w-100 flex-column gap-3 p-3 align-items-start flex-grow-1 overflow-auto')}
          >
            {partyInfos.length > 0 && <Select
              className={'w-100'}
              options={partyInfos}
              value={selectedParty}
              placeholder={'Select a party...'}
              isSearchable={false}
              isOptionSelected={(option, value) => {
                return value.at(0)?.id === option.id;
              }}
              onChange={newValue => setSelectedParty(newValue ? newValue : undefined)}
              formatOptionLabel={(data, meta) => {
                const small = [
                  upperFirst(data.category || '')
                ].map(x => x.trim()).filter(x => !!x).join(', ');
                return <div>
                  <div><span>{data.snapshot?.name || 'Unknown'}</span></div>
                  {!isMyFile && <div><small>{small}</small></div>}
                </div>;
              }}
              styles={{
                control: (base, control) => {
                  if (!control.hasValue) {
                    return { ...base, borderRadius: '0' };
                  }
                  const value = control.getValue().at(0);
                  if (!value?.colour) {
                    return { ...base, borderRadius: '0' };
                  }
                  return {
                    ...base,
                    borderRadius: '0',
                    borderLeft: `4px solid ${value.colour}`
                  };
                },
                menu: base => ({ ...base, borderRadius: '0' }),
                option: (base, option) => ({ ...base, borderLeft: `4px solid ${option.data.colour}` })
              }}
            />}
            {!!selectedParty && drawCustomFieldTypeBlock('Signing fields', signing, type => ({
              mode: DraggableType.Create,
              type,
              width: 150,
              height: 40,
              partyId: selectedParty.id
            }))}
            {!!selectedParty && drawCustomFieldTypeBlock('Remote Completion', completion, type => ({
              mode: DraggableType.Create,
              type,
              width: 150,
              height: 40,
              partyId: selectedParty.id
            }))}
            {!!selectedParty && drawCustomFieldTypeBlock('Contact fields', contact, type => ({
              mode: DraggableType.Create,
              type,
              width: 150,
              height: 40,
              partyId: selectedParty.id
            }))}
            {drawCustomFieldTypeBlock('Property fields', property, type => ({
              mode: DraggableType.Create,
              type,
              width: 150,
              height: 40
            }))}
            {drawCustomFieldTypeBlock('Serve Fields', serve, type => ({
              mode: DraggableType.Create,
              type,
              width: 150,
              height: 40
            }))}
            {drawCustomFieldTypeBlock('Other', other, type => ({
              mode: DraggableType.Create,
              type,
              width: 150,
              height: 40
            }))}
          </div>
          {!!(selectedFieldId && selectedField) && <ListGroup
            onClick={noBubble()}
            className={clsJn('w-100 sticky-footer-shadow')}
          >
            <ListGroup.Item className={'d-flex flex-row align-items-center gap-1'}>
              <Icon {...customFieldMetas[selectedField.type].icon} />
              <span className={'fw-bold'}>{customFieldMetas[selectedField.type].title}</span>
            </ListGroup.Item>
            <ListGroup.Item>{customFieldMetas[selectedField.type].description}</ListGroup.Item>
            {/*conditionally show value and other things*/}
            {customFieldMetas[selectedField.type].attributes.length > 0 && <ListGroup.Item>
              <CustomFieldAttributesEditor
                meta={customFieldMetas[selectedField.type]}
                field={selectedField}
                estimator={estimator}
              />
            </ListGroup.Item>}
            <ListGroup.Item className={'d-flex flex-column gap-2'}>
              {selectedField.type === CustomFieldType.remoteRadio && <Button variant='outline-secondary' onClick={() => {
                if (!selectedField) return;
                const dims = viewerRef?.getPageDimensions(selectedField.position.page);
                if (!dims) return;
                const id = cloneRadio(selectedField.id, dims);
                setSelectedFieldId(id || '');
              }}>Add option</Button>}
              {selectedField.type === CustomFieldType.remoteCheck && <Button variant='outline-secondary' onClick={() => {
                if (!selectedField) return;
                const dims = viewerRef?.getPageDimensions(selectedField.position.page);
                if (!dims) return;
                const id = cloneCheckbox(selectedField.id, dims);
                setSelectedFieldId(id || '');
              }}>Clone</Button>}
              <Button variant={'outline-danger'} onClick={() => deleteSelected()}>Remove field</Button>
            </ListGroup.Item>
          </ListGroup>}
        </Col>)}
      </DroppablePositioned>
      <Col
        md={8}
        className='d-flex flex-column align-items-center justify-content-start gap-3'
        style={{ background: 'var(--clr-bg-pdf-preview)' }}
      >
        <div className='w-100 h-100 position-relative'>
          {uploadedFileUrl && <SetupPdfLoadStateContext><PDFViewer
            ref={viewerRefCallback}
            scrollContainerRef={pdfScrollContainer}
            pdfUrl={uploadedFileUrl}
            bookmark=''
            activeViews={2}
            filename={instance?.upload?.name || 'uploaded.pdf'}
            allowPrint={false}
            allowDownload={false}
            renderTextLayer={false}
            zoomMode={ZoomMode.Manual}
            useLoadSuccessForCompletion={true}
            // page rendering here:
            // - page drop zone for new/existing fields
            // - renders existing fields for the page
            pageWrapElement={({ pageIndex, dimensions: pdfDimensions, children: pageContent }) => {
              if (deletedPageIndexes.has(pageIndex)) return <div className='d-none'>{pageContent}</div>;
              const pageStaticFields = staticFields?.filter(sf => sf.position.page === pageIndex) ?? [];
              return <DroppablePositioned<CustomFieldDetails>
                accept={pdfDimensions ? [DraggableType.Create, DraggableType.Existing] : []}
                getDroppedItemDimensions={item => {
                  switch (item.mode) {
                    case DraggableType.Create:
                      return { width: item.width, height: item.height };
                    case DraggableType.Existing:
                      return { width: item.position.width, height: item.position.height };
                  }
                }}
                checkCanDrop={(itemRect, zoneRect, item) => {
                  if (!pdfDimensions) return true;
                  const scaleX = (value: number) => scaleValue(value, zoneRect.width, pdfDimensions.width);
                  const scaleY = (value: number) => scaleValue(value, zoneRect.height, pdfDimensions.height);
                  const scaledRect: Rect = {
                    x: scaleX(itemRect.x),
                    y: scaleY(itemRect.y),
                    width: scaleX(itemRect.width),
                    height: scaleY(itemRect.height)
                  };
                  return isPositionAllowed(
                    item.mode === DraggableType.Create
                      // if we're dragging on from the sidebar then simulate the size at drop
                      ? adjustRectToMinDimensions(scaledRect, item.type, true)
                      : scaledRect,
                    pageStaticFields,
                    item.type
                  );
                }}
                onDrop={(_, item, location, zoneRect) => {
                  if (!binder) return;
                  if (!pdfDimensions) return;

                  switch (item?.mode) {
                    case DraggableType.Create: {
                      const newId = addField(item, location, zoneRect, pageIndex, pdfDimensions);
                      if (newId) {
                        setSelectedFieldId(newId);
                      }
                      return;
                    }
                    case DraggableType.Existing: {
                      const scaleX = (value: number) => scaleValue(value, zoneRect.width, pdfDimensions.width);
                      const scaleY = (value: number) => scaleValue(value, zoneRect.height, pdfDimensions.height);
                      binder.update(draft => {
                        const signing = FormUtil.getSigning(formCode, formId, draft);
                        if (!signing) return;
                        if (!signing.customFields) {
                          signing.customFields = [];
                        }
                        const matching = signing.customFields.find(f => f.id === item.id);
                        if (!matching) return;

                        matching.position.page = pageIndex;
                        // note: may need to perform some translation from UI coordinate space to pdf page's coordinate space.
                        matching.position.x = scaleX(location.x);
                        matching.position.y = scaleY(location.y);
                      });
                      setSelectedFieldId(item.id);
                      return;
                    }
                  }
                }}>
                {(droppableProvided) => {
                  const displayDimensions = droppableProvided.getZoneRect();
                  return (<div
                    ref={droppableProvided.innerRef}
                    style={{ width: 'min-content', marginInline: 'auto' }}
                    className={clsJn(
                      'mt-3 position-relative',
                      droppableProvided.canDrop && !droppableProvided.isOver && 'droppable',
                      droppableProvided.isOver && droppableProvided.canDrop && 'droppable-over',
                      plonkable && 'droppable-over',
                      plonkable && canPlonk && 'plonkable',
                      plonkable && !canPlonk && 'not-plonkable'
                    )}
                    onMouseMove={plonkable ? e => {
                      const zoneRect = e.currentTarget.getBoundingClientRect();
                      const { clientX, clientY } = e;
                      setCanPlonk(checkAddField(
                        plonkable,
                        {
                          x: Math.max(0, clientX - zoneRect.x),
                          y: Math.max(0, clientY - zoneRect.y)
                        },
                        zoneRect,
                        pageIndex,
                        pdfDimensions
                      ));
                    } : undefined}
                    onClick={e => {
                      if (!plonkable) return;

                      e.stopPropagation();
                      const zoneRect = e.currentTarget.getBoundingClientRect();
                      const { clientX, clientY } = e;
                      addField(
                        plonkable,
                        {
                          x: Math.max(0, clientX - zoneRect.x),
                          y: Math.max(0, clientY - zoneRect.y)
                        },
                        zoneRect,
                        pageIndex,
                        pdfDimensions
                      );
                    }}
                  >
                    {/*the actual pdf page content*/}
                    {pageContent}
                    {pdfDimensions && displayDimensions && <StaticPageFields
                      fields={pageStaticFields}
                      pdfDimensions={pdfDimensions}
                      displayDimensions={displayDimensions}
                      partyInfos={partyInfos}
                      property={data}
                    />}
                    {/*custom fields belonging to this page*/}
                    {pdfDimensions && displayDimensions && <PageFields
                      pdfDimensions={pdfDimensions}
                      displayDimensions={displayDimensions}
                      partyInfos={partyInfos}
                      formId={formId}
                      fields={(customFields || []).filter(cf => cf.position.page === pageIndex)}
                      property={data}
                      binder={binder}
                      selectedFieldId={selectedFieldId}
                      formCode={formCode as FormCodeUnion}
                      onSelectField={id => setSelectedFieldId(id)}
                      onContext={(x, y, id, target, field) => {
                        setSelectedFieldId(id);
                        setShowContext({ x, y, id, target, field, pdfDimensions });
                      }}
                      estimator={estimator}
                      groupBox={groupBoxes?.[pageIndex]}
                      checkPositionAllowed={(position, type) => isPositionAllowed(position, pageStaticFields, type)}
                    />}

                  </div>);
                }}
              </DroppablePositioned>;
            }}
          /></SetupPdfLoadStateContext>}
        </div>

      </Col>
      <Col md={2} style={{ background: 'var(--clr-bg-pdf-preview)' }}>
        {uploadedFileUrl && <PDFMinimap
          url={uploadedFileUrl}
          thumbnailWrapElement={props => {
            return <MinimapActionWrapper
              key={props.pageIndex}
              pageIndex={props.pageIndex}
              allowDelete={props.pageCount > (deletedPageIndexes.size + 1) && !disablePageActions}
              deleted={deletedPageIndexes.has(props.pageIndex)}
              allowActions={!disablePageActions && !coverPageIndexes?.has(props.pageIndex)}
              onAction={(actionIndex, action) => {
                if (!binder) return Promise.resolve();
                binder.update(draft => {
                  const formInstance = FormUtil.getFormState(formCode, formId, draft);
                  if (!formInstance?.upload) return;
                  const upload = getUploadAsV2(formInstance.upload, formInstance.created);
                  if (!upload) return;
                  formInstance.upload = upload;

                  const fSegments = segments.filter(s => {
                    const end = s.start + s.pages;
                    return actionIndex >= s.start && actionIndex < end;
                  });
                  const segment = fSegments.at(0);
                  if (!segment) return;
                  if (segment.isCover) return;
                  actionIndex = actionIndex - segment.start;
                  const file = upload.files?.find(f => f.id === segment.sourceId);
                  if (!file) return;
                  if (!file.actions) {
                    file.actions = [];
                  }

                  const actions = file.actions;
                  switch (action) {
                    case 'undelete':
                      for (let i = actions.length - 1; i >= 0; i--) {
                        if (actions[i].action === 'delete' && actions[i].pageIndex === actionIndex) {
                          actions.splice(i, 1);
                        }
                      }
                      return;
                    default:
                      actions.push({
                        action,
                        pageIndex: actionIndex
                      });
                      return;
                  }
                });
                return Promise.resolve();
              }}
            >
              {props.children}
            </MinimapActionWrapper>;
          }}
        />}
      </Col>
    </DndProvider>
  </div>;
}

interface ScalingDimensions {
  width: number;
  height: number;
}

interface GroupBoxDimensions {
  x: number,
  y: number,
  width: number,
  height: number
}

function PageFields({
  binder,
  displayDimensions,
  fields,
  formCode,
  formId,
  onSelectField,
  onContext,
  partyInfos,
  property,
  pdfDimensions,
  selectedFieldId,
  estimator,
  groupBox,
  checkPositionAllowed
}: {
  binder: BinderFn<TransactionMetaData>,
  displayDimensions: ScalingDimensions,
  fields: CustomFieldConfiguration[],
  formCode: FormCodeUnion,
  formId: string,
  onSelectField: (id: string) => void,
  onContext: (x: number, y: number, id: string, target: Element, field: CustomFieldConfiguration) => void,
  partyInfos: CustomFieldPartyInfo[],
  property: MaterialisedPropertyData,
  pdfDimensions: ScalingDimensions,
  selectedFieldId: string,
  estimator: TextDimensionEstimator,
  groupBox?: GroupBoxDimensions,
  checkPositionAllowed: (position: { x: number, y: number, width: number, height: number }, type: CustomFieldType) => boolean
}) {
  const pdfToDisplayScaleX = (value: number) => scaleValue(value, pdfDimensions.width, displayDimensions.width);
  const pdfToDisplayScaleY = (value: number) => scaleValue(value, pdfDimensions.height, displayDimensions.height);
  const displayToPdfScaleX = (value: number) => scaleValue(value, displayDimensions.width, pdfDimensions.width);
  const displayToPdfScaleY = (value: number) => scaleValue(value, displayDimensions.height, pdfDimensions.height);
  const contentScale = pdfToDisplayScaleX(1);

  return <>
    {groupBox && <div style={{
      position: 'absolute',
      top: `${pdfToDisplayScaleY(groupBox.y - 4)}px`,
      left: `${pdfToDisplayScaleX(groupBox.x - 4)}px`,
      width: `${pdfToDisplayScaleY(groupBox.width + 8)}px`,
      height: `${pdfToDisplayScaleX(groupBox.height + 8)}px`,
      border: '1px solid blue',
      borderRadius: '2px'
    }}></div>}
    {fields.map(cf => {
      const f: ExistingCustomFieldDetails = {
        ...cf,
        mode: DraggableType.Existing,
        position: {
          y: pdfToDisplayScaleY(cf.position.y),
          x: pdfToDisplayScaleX(cf.position.x),
          width: pdfToDisplayScaleX(cf.position.width),
          height: pdfToDisplayScaleY(cf.position.height),
          page: cf.position.page
        }
      };
      return (<DraggablePositioned
        key={f.id}
        type={f.mode}
        item={f}
        otherAttrs={{ contentScale }}
      >
        {(draggableProvided) => {
          return (<div
            ref={draggableProvided.innerRef}
            style={{
              position: 'absolute',
              top: `${f.position.y}px`,
              left: `${f.position.x}px`,
              width: `${f.position.width}px`,
              height: `${f.position.height}px`
            }}
            className={clsJn(
              draggableProvided.isDragging && 'd-none',
              'movable'
            )}
          >
            <ExistingCustomField
              details={f}
              parties={partyInfos}
              property={property}
              dragging={false}
              resizable={customFieldMetas[f.type].resize === 'manual'}
              onSelect={() => onSelectField(f.id)}
              onContext={(x, y, target) => onContext(x, y, f.id, target, f)}
              selected={f.id === selectedFieldId}
              otherSelected={Boolean(selectedFieldId && f.id !== selectedFieldId)}
              contentScale={contentScale}
              onResizing={(delta) => checkPositionAllowed(clampFieldPosition({
                x: cf.position.x + displayToPdfScaleX(delta.left),
                y: cf.position.y + displayToPdfScaleX(delta.top),
                width: cf.position.width + displayToPdfScaleX(delta.width),
                height: cf.position.height + displayToPdfScaleX(delta.height)
              }, pdfDimensions), cf.type)}
              onResize={(delta) => {
                if (!binder) return false;
                const finalPosition = clampFieldPosition({
                  x: cf.position.x + displayToPdfScaleX(delta.left),
                  y: cf.position.y + displayToPdfScaleX(delta.top),
                  width: cf.position.width + displayToPdfScaleX(delta.width),
                  height: cf.position.height + displayToPdfScaleX(delta.height)
                }, pdfDimensions);
                const allowed = checkPositionAllowed(finalPosition, cf.type);
                if (!allowed) {
                  return false;
                }
                let succeeded = false;
                binder.update(draft => {
                  const signing = FormUtil.getSigning(formCode, formId, draft);
                  if (!signing?.customFields) return;
                  const match = signing.customFields.find(field => f.id === field.id);
                  if (!match) return;
                  match.position.x = finalPosition.x;
                  match.position.y = finalPosition.y;
                  match.position.width = finalPosition.width;
                  match.position.height = finalPosition.height;
                  succeeded = true;
                });
                return succeeded;
              }}
              onEdit={(text) => {
                if (!binder) return;
                binder.update(draft => {
                  const signing = FormUtil.getSigning(formCode, formId, draft);
                  if (!signing?.customFields) return;
                  const match = signing.customFields.find(field => f.id === field.id);
                  switch (match?.type) {
                    case CustomFieldType.text:
                    case CustomFieldType.remoteText:
                      match.text = text;
                      break;
                    case CustomFieldType.remoteCheck:
                      match.label = text;
                      autoResize(match, { estimator });
                      break;
                    case CustomFieldType.remoteRadio:
                      match.label = text;
                      autoResize(match, { estimator });
                      break;
                  }
                });
              }}
              onAttrChange={(name, raw) => {
                const meta = customFieldMetas[f.type];
                const attr = meta.attributes.find(a => a.name === name);
                if (!attr) return;
                setCustomAttribute({
                  binder,
                  attr,
                  raw,
                  field: f,
                  formId,
                  formCode,
                  estimator
                });
              }}
            />
          </div>);
        }}
      </DraggablePositioned>);
    })}
  </>;
}

function StaticPageFields({
  fields,
  displayDimensions,
  pdfDimensions,
  partyInfos,
  property
}: {
  fields: StaticField[],
  displayDimensions: ScalingDimensions,
  pdfDimensions: ScalingDimensions,
  partyInfos: CustomFieldPartyInfo[],
  property: MaterialisedPropertyData,
}) {
  const pdfToDisplayScaleX = (value: number) => scaleValue(value, pdfDimensions.width, displayDimensions.width);
  const pdfToDisplayScaleY = (value: number) => scaleValue(value, pdfDimensions.height, displayDimensions.height);
  const contentScale = pdfToDisplayScaleX(1);

  return <>{fields.map(cf => {
    const f: ExistingCustomFieldDetails = {
      ...cf,
      mode: DraggableType.Existing,
      position: {
        y: pdfToDisplayScaleY(cf.position.y),
        x: pdfToDisplayScaleX(cf.position.x),
        width: pdfToDisplayScaleX(cf.position.width),
        height: pdfToDisplayScaleY(cf.position.height),
        page: cf.position.page
      }
    };
    return <div
      style={{
        position: 'absolute',
        top: `${f.position.y}px`,
        left: `${f.position.x}px`,
        width: `${f.position.width}px`,
        height: `${f.position.height}px`
      }}>
      <ExistingCustomField
        details={f}
        parties={partyInfos}
        property={property}
        dragging={false}
        resizable={false}
        contentScale={contentScale}
        selected={false}
        otherSelected={false}
        onResize={() => false}
        onResizing={() => false}
      />
      <div style={{ position: 'absolute', top: 0, right: 0 }}>
        <span className="material-symbols-outlined fs-6">lock</span>
      </div>
    </div>;
  })}</>;
}

type MinimapThumbnailActionHandler = (pageIndex: number, action: 'rotate_cw' | 'rotate_ccw' | 'delete' | 'undelete') => Promise<void>;

function MinimapActionWrapper({ pageIndex, onAction, allowDelete, deleted, children, allowActions }: React.PropsWithChildren<{
  pageIndex: number,
  onAction: MinimapThumbnailActionHandler,
  allowDelete: boolean,
  deleted: boolean,
  allowActions: boolean
}>) {
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  return <div
    key={pageIndex}
    className='mt-2 position-relative'
  >
    <div
      key='toolbar'
      className='d-flex flex-row justify-content-between w-100 thumbnail-toolbar gap-2 p-1'
      style={{ background: 'var(--clr-bg-pdf-toolbar)', backdropFilter: 'blur(3px)' }}
    >
      <div className='d-flex flex-row gap-2'>
        {!deleted && allowActions && <Button
          variant='secondary'
          title='Rotate page 90 degrees clockwise'
          onClick={() => onAction(pageIndex, 'rotate_cw')}
        ><Icon name='rotate_90_degrees_cw'/></Button>}
        {!deleted && allowActions && <Button
          variant='secondary'
          title='Rotate page 90 degrees counter-clockwise'
          onClick={() => onAction(pageIndex, 'rotate_ccw')}
        ><Icon name='rotate_90_degrees_ccw'/></Button>
        }
      </div>
      <div>
        {allowDelete && !deleted && allowActions && <Button
          variant='secondary'
          title='Remove page'
          onClick={() => setConfirmingDelete(true)}
        ><Icon name='delete'/></Button>}
        {deleted && allowActions && <Button
          variant='secondary'
          title='Restore page'
          onClick={() => onAction(pageIndex, 'undelete')}
        ><Icon name='restore'/></Button>}
      </div>
    </div>
    <div className='position-relative w-100 h-100'>
      {children}
      {deleted && <div
        className='position-absolute w-100 h-100 d-flex align-items-center justify-content-center'
        style={{ backdropFilter: 'blur(3px) brightness(0.8)', zIndex: '1', bottom: '0' }}
      >
        <span className='fs-4 fw-bold'>Deleted</span>
      </div>}
    </div>
    {confirmingDelete && <Modal key='modal' show={true} onHide={() => setConfirmingDelete(false)}>
      <Modal.Header>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You're about to delete this page from the document.
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => setConfirmingDelete(false)}>Cancel</Button>
        <Button variant='danger' onClick={() => {
          onAction(pageIndex, 'delete');
          setConfirmingDelete(false);
        }}>Delete</Button>
      </Modal.Footer>
    </Modal>}
  </div>;
}

function clampFieldPosition(position: Pick<FieldPosition, 'x' | 'y' | 'width' | 'height'>, page: ScalingDimensions) {
  // clamp rect to within the pdf page dimensions
  position.x = clamp(position.x, 0, page.width - position.width);
  position.y = clamp(position.y, 0, page.height - position.height);
  position.width = clamp(position.width, 1, page.width);
  position.height = clamp(position.height, 1, page.height);
  return position;
}

function rectsIntersect(a: Rect, b: Rect) {
  // a.left <= b.right && a.right >= b.left &&
  // a.top <= b.bottom && a.botom >= b.top
  return a.x <= (b.x + b.width) && (a.x + a.width) >= b.x &&
    // if cartesian coordinates: a.y >= (b.y + b.height) && (a.y + a.height) <= b.y;
    // but we are using computer/screen coordinates:
    a.y <= (b.y + b.height) && (a.y + a.height) >= b.y;
}

type IsPositionAllowedItem = Rect | { position: Rect };
function isPositionAllowed(candidate: IsPositionAllowedItem, others: IsPositionAllowedItem[], type: CustomFieldType): boolean {
  const checkRect = 'position' in candidate
    ? candidate.position
    : candidate;

  const meta = customFieldMetas[type];
  if (meta?.hMin && checkRect.height < meta.hMin) {
    return false;
  }
  if (meta?.wMin && checkRect.width < meta.wMin) {
    return false;
  }

  for (const other of others) {
    const otherRect = 'position' in other
      ? other.position
      : other;
    if (rectsIntersect(checkRect, otherRect)) {
      return false;
    }
  }
  return true;
}
