import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Container, Form, Modal } from 'react-bootstrap';
import { FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import FormCheck from 'react-bootstrap/FormCheck';
import { useDropzone } from 'react-dropzone';
import clsJn from '@property-folders/common/util/classNameJoin';
import './WetSigningModal.scss';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { SigningPartyTypeOptions } from '@property-folders/components/dragged-components/signing/Common';
import { ContentType, FormCode, FormSigningState, ManifestData, ManifestType, MaterialisedPropertyData, SigningParty, SigningPartyType } from '@property-folders/contract';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { materialiseProperty, signFields } from '@property-folders/common/yjs-schema/property';
import { processPdf } from '@property-folders/common/util/pdf/pdf-process';
import { FileStorage, FileType, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { v4 } from 'uuid';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { useStore } from 'react-redux';
import { formatAct } from '@property-folders/common/util/pdfgen/formatters/clauses';
import { LegalJurisdiction } from '@property-folders/common/data-and-text/constants';
import { Predicate } from '@property-folders/common/predicate';
import { useQpdfWorker } from '../../hooks/useQpdfWorker';
import { useCurrentEntity } from '../../hooks/useEntity';
import { WorkflowConditionTriggerType } from '@property-folders/contract/yjs-schema/entity-settings';
import { shouldCoolingOffStartNow } from '@property-folders/common/util/form';
import { evaluateCondition } from '@property-folders/common/workflow-rules/RuleEvaluation';

enum WetSigningState {
  Initial,
  Confirm
}

export function WetSigningModal({
  onClose,
  formCode,
  formId,
  signingSessionId,
  signingParty
}: {
  onClose: ()=>void,
  formCode: string,
  formId: string,
  signingSessionId: string,
  signingParty: SigningParty
}) {
  const { ydoc, transactionMetaRootKey, transactionRootKey } = useContext(YjsDocContext);
  const formDal = ydoc ? new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey) : undefined;
  const enableCounterpartSigning = !FormTypes[formCode].disableCounterpartSigning;
  const disableCounterpartSigning = !enableCounterpartSigning;
  const formFamily = FormTypes[formCode].formFamily;
  const parties = formDal?.getSigningParties(formCode, formId, signingSessionId);
  const signingSession = formDal?.getFormSigningSession(formCode, formId);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { instance: fileSync } = useContext(FileSyncContext);
  const { value: property } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '' });

  const uploadInput = useRef<HTMLInputElement | null>(null);
  const [state, setState ] = useState(WetSigningState.Initial);
  const [confirmText, setConfirmText] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [willUpload, setWillUpload] = useState(true);
  const [pdf, setPdf] = useState<{filename:string, data:ArrayBuffer}|undefined>(undefined);
  const [signers, setSigners] = useState<{id:string, name:string, alreadySigned:boolean, signedType:SigningPartyType, signWet:boolean, date:number|undefined}[]>([]);
  const store = useStore();
  const qpdfWorker = useQpdfWorker();
  const [showCoolingOffWarning, setShowCoolingOffWarning] = useState(false);
  const localEntity = useCurrentEntity();

  const now = new Date();
  // toISOString may return in UTC, which could result in odd date alignment
  const todayDateStr = `${now.getFullYear()}-${(now.getMonth()+1).toString().padStart(2,'0')}-${(now.getDate()).toString().padStart(2,'0')}`;
  const todayDateTimestamp = new Date(todayDateStr).valueOf();

  useEffect(()=> {
    setSigners(parties?.map(p => {
      const proxyMode = Predicate.proxyNotSelf(p.proxyAuthority);
      const fullName = (proxyMode ? p.proxyName : p.snapshot?.name) || '';
      return {
        id: p.id,
        name: fullName,
        alreadySigned: !!p.signedTimestamp,
        signedType: p.type,
        signWet: p.id === signingParty.id || disableCounterpartSigning,
        date: p.signedTimestamp
      };
    })||[]);
  }, []);

  //Cooling-off warning
  useEffect(() => {
    setShowCoolingOffWarning(shouldShowCoolingOffWarning());
  }, [signers, todayDateTimestamp, ydoc, localEntity?.workflowRules, property]);

  const shouldShowCoolingOffWarning = () => {
    if (formCode !== FormCode.RSC_ContractOfSale || !ydoc || !signers || !property) return false;

    // dont show warning unless every signer will have signed
    if (!signers.every(s => (s.signWet || s.alreadySigned) && s.date)) return false;

    const rules = localEntity?.workflowRules;
    const applicableRules = rules?.filter(r => r.trigger === WorkflowConditionTriggerType.CoolingOffBegun && r.enabled && r.conditions?.length);
    if (!applicableRules?.length) return false;

    const localProperty = materialiseProperty(ydoc);
    if (!localProperty) return false;

    // augment the signer/signed state locally to determine whether we are actually preventing a cooling-off
    const signing = localProperty?.alternativeRoots?.[transactionRootKey]?.meta?.formStates?.[FormCode.RSC_ContractOfSale]?.instances?.[0]?.signing;
    const signersToAugment = signing?.parties;
    if (!signing || !signersToAugment) return false;

    // Set the signing.state to Signed but don't commit, to help us test whether "Cooling off begins"
    // so we can warn them that cooling off won't be raised if we are uploading a paper copy signed before today.
    signing.state = FormSigningState.Signed;
    for (const signer of signers.filter(s => s.signWet)) {
      const augmentSigner = signersToAugment.find(s => s.id === signer.id);
      if (!augmentSigner) continue;
      augmentSigner.signedTimestamp = todayDateTimestamp;
    }

    //if signing today wouldn't cause cooling-off, then we dont need to show the warning
    if (!shouldCoolingOffStartNow(localProperty, transactionRootKey)) return false;

    for (const signer of signers.filter(s => s.signWet)) {
      const augmentSigner = signersToAugment.find(s => s.id === signer.id);
      if (!augmentSigner) continue;
      augmentSigner.signedTimestamp = signer.date;
    }

    //if signing on the dates the user specified would still allow cooling-off, we dont need to should the warning
    if (shouldCoolingOffStartNow(localProperty, transactionRootKey)) return false;

    //now check the actual event rule will pass
    const event = {
      trigger: WorkflowConditionTriggerType.CoolingOffBegun,
      propertyId: localProperty?.data?.id,
      sublineageDataKey: transactionRootKey
    };

    const matProperty = materialiseProperty(ydoc);
    return !!(matProperty && applicableRules?.some(rule => rule?.conditions?.[0] && evaluateCondition(rule.conditions[0], matProperty, event)));
  };

  const handleDrop = async (acceptedFiles: File[] | FileList) => {
    setPdf({
      filename: acceptedFiles?.[0]?.name,
      data: await acceptedFiles?.[0]?.arrayBuffer()
    });
  };

  useEffect(()=> {
    if (!pdf) return;

    // we can't work on the original pdf.data or it becomes detached
    const copyOfPdfData = new ArrayBuffer(pdf.data.byteLength);
    // copies the data - https://stackoverflow.com/a/22114687
    new Uint8Array(copyOfPdfData).set(new Uint8Array(pdf.data));

    qpdfWorker.decrypt({ pdf: copyOfPdfData }).then(decryptedPdf => {
      processPdf(decryptedPdf).then(pdfFile => {
        if (!pdfFile?.pdf || pdfFile?.isEncrypted) {
          setErrorMessage(`Failed to attach ${pdf.filename}. Please make sure PDF is not encrypted and try again`);
          setPdf(undefined);
          return;
        }
      });
    });
  }, [pdf]);

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    event.target.files && handleDrop(event.target.files);
  };

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop: (files) => handleDrop(files),
    noClick: true,
    accept: { 'application/pdf': [] }
  });

  const confirmSigning = async () => {
    let pdfId = '';
    if (willUpload && pdf?.data) {
      const decryptedPdf = await qpdfWorker.decrypt({ pdf: pdf.data });
      const pdfFile = await processPdf(decryptedPdf);
      const manifestData: ManifestData = {
        manifestType: ManifestType.None
      };

      pdfId = v4();

      await FileStorage.write(
        pdfId,
        FileType.PropertyFile,
        ContentType.Pdf,
        new Blob([pdfFile.pdf.buffer], { type: ContentType.Pdf }),
        StorageItemSyncStatus.PendingUpload,
        {
          propertyFile: {
            propertyId: property.id,
            formId,
            formCode
          }
        },
        { store, ydoc },
        manifestData,
        undefined,
      );

      FileSync.triggerSync(fileSync);
    }

    signers?.filter(s => s.signWet)?.map(s =>
      signFields(ydoc, {
        formCode,
        formId,
        signingSessionId,
        partyId: s.id,
        signedType: SigningPartyType.SignWet,
        wetSignedFileId: pdfId,
        fields: [{ id: signingSession?.fields?.find(f => f.partyId === s.id)?.id, timestamp: s?.date }],
        dataRootKey: transactionRootKey,
        metaRootKey: transactionMetaRootKey
      })
    );

    onClose?.();
  };

  const continueDisabled = (willUpload && !pdf)
    || signers.every(s => !s.signWet)
    || signers.some(s => s.signWet && !s.date);

  const skipConfirm = false; //TODO - remove

  return (
    <Modal dialogClassName="modal-wet-signing" show={!closed} onHide={onClose} backdrop="static">
      <Modal.Header closeButton><Modal.Title>Paper Signing</Modal.Title></Modal.Header>
      <Modal.Body>
        {state === WetSigningState.Initial &&
          <div>
            <div className={'mb-3'}>
              <FormCheck type='radio' label='I will upload a signed document' checked={willUpload} id={'willUpload'} onChange={() => setWillUpload(true)} />
              <FormCheck type='radio' label='I will not upload a document' checked={!willUpload} id={'willNotUpload'} onChange={() => setWillUpload(false)} />
            </div>
            {willUpload
              ? <div>
                <input {...getInputProps()} ref={uploadInput} className={'d-none'} accept={'.pdf'} onChange={handleUpload} />

                <div style={{ height: '140px' }} {...getRootProps()} className={clsJn('drop-target mb-3', isDragAccept && 'drop-accept', pdf && 'is-uploaded' )} tabIndex={-1}>
                  {pdf
                    ? <div className={'d-flex text-black'}><Icon name='check_circle' icoClass='me-1' style={{ color: 'green' }}/>{pdf.filename}<span className="material-symbols-outlined cursor-pointer ms-2" onClick={()=>setPdf(undefined)}>delete</span></div>
                    : <>
                      <div className={'mb-2'}>Drag and drop the signed PDF</div>
                      <div className={'mb-2'}>or</div>
                      <Button variant={'primary'} className={'mr-3'} onClick={() => uploadInput?.current?.click()}>Choose PDF</Button>
                    </>}
                </div>
                {errorMessage && <Alert variant='danger' dismissible onClose={()=>setErrorMessage('')}>{errorMessage}</Alert>}

              </div>
              : <Alert style={{ height: '140px' }} variant="info">
                <Alert.Heading>Note</Alert.Heading>
                Without a copy of the signed document, reaforms will be unable to:
                <ol>
                  <li>retain the signed document for future reference; or</li>
                  <li>distribute the fully executed document to the parties.</li>
                </ol>
              </Alert>
            }

            <Container fluid className={'mb-3 p-0'}>
              <div className={'mb-3'}>Select the parties that have signed the uploaded paper document:</div>
              <div className={'grid-container ms-2'}>
                {signers?.map(s => <React.Fragment key={`signer-${s.id}`}>
                  <div className={'d-flex flex-row align-items-center me-4'}>
                    {s.alreadySigned
                      ? <div className={'d-flex flex-row me-4'} style={{ marginLeft: -5 }}><Icon name='check_circle' icoClass='me-1' style={{ color: 'green' }}/><span className={'opacity-50'}>{s.name}</span></div>
                      : <FormCheck type='checkbox' id={s.id} label={s.name} disabled={s.alreadySigned || disableCounterpartSigning} checked={s.alreadySigned || s.signWet}
                        onChange={() => setSigners(p => p.map(p => p.id === s.id ? { ...p, signWet: !p.signWet } : p))}
                      />}
                  </div>
                  <div>{s.alreadySigned
                    ? <span className={'opacity-50'}>{formatTimestamp(s?.date, sessionInfo?.timeZone, false)} {SigningPartyTypeOptions[s.signedType]}</span>
                    : <div className={'d-flex flex-row align-items-center'}>
                      <Form.Control
                        type={'date'}
                        style={{ maxWidth: '200px', padding: '2px 2px' }}
                        value={s.signWet && s?.date ? new Date(s.date).toISOString().split('T')[0] : undefined}
                        disabled={!s.signWet}
                        max={todayDateStr}
                        onChange={(e) => {
                          const dateTimestamp = new Date(e.target.value).valueOf();
                          // Update the signers date if valid date
                          !(dateTimestamp > todayDateTimestamp) && setSigners(p => p.map(p => p.id === s.id ? { ...p, date: dateTimestamp } : p));
                        }}
                      />
                      {s.signWet && <div className={'ms-2'}>via paper</div>}
                    </div>}
                  </div>
                </React.Fragment>)}
              </div>
            </Container>

            {!signers.every(s => s.alreadySigned || s.signWet) && <div>
              <div className={'fw-bold'}>Counterpart signing</div>
              A legal agreement does not require all parties to sign the same document. If parties sign identical copies of the document, taken together, these document form a valid, binding agreement.<br />
              If the documents are not identical, then the agreement is not valid and binding.
              {formFamily === FormCode.RSAA_SalesAgencyAgreement && <div className={'mt-2'}>Under the {formatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994', { noItalics: true })}, the agent is required to distribute the fully-signed and executed document, including all counterparty documents, to all of the parties to the agreement immediately or at a later time within 48 hours as agreed between the parties.</div>}
              {formFamily === FormCode.RSC_ContractOfSale && <div className={'mt-2'}>Under the {formatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994', { noItalics: true })}, the agent is required to distribute the fully-signed and executed document, including all counterparty documents, to all of the parties to the agreement immediately.</div>}
            </div>}

            {showCoolingOffWarning && <Alert variant='warning' className='mt-3'>
              This Contract has been signed on paper, in the past. Greatforms will not send "Cooling Off" notifications for this transaction.
            </Alert>}
          </div>
        }

        {state === WetSigningState.Confirm &&
          <Container fluid className={'mb-3 p-0'}>
            <div className={'mb-2'}>You are confirming that the following parties signed the document on the dates specified:</div>
            <div className={'grid-container ms-2 mb-4'}>
              {signers?.filter(s=> s.signWet)?.flatMap(s => <React.Fragment key={`signer-${s.id}`}>
                <div className={'d-flex flex-row align-items-center me-4'}><Icon name='check_circle' icoClass='me-1' style={{ color: 'green' }}/><span className={'fw-bold'}>{s.name}</span></div>
                <div className={'d-flex flex-row align-items-center'}><span className={'fw-bold'}>{formatTimestamp(s?.date, sessionInfo?.timeZone, false)}</span></div>
              </React.Fragment>)}
            </div>

            <div className={'mb-2'}>
              Completing this incorrectly may cause any of the following issues:
              <ul>
                <li>a non-binding document being represented as binding;</li>
                <li>a binding document being represented as non-binding;</li>
                <li>the date of the document being incorrect.</li>
              </ul>
            </div>
            <div className={'mb-3'}>
              <div className={'mb-1'}>To confirm the details entered, type "WETINK" below:</div>
              <Form.Control placeholder={'WETINK'} style={{ width: '200px' }} value={confirmText} onChange={e => setConfirmText(e.target.value)}></Form.Control>
            </div>
          </Container>
        }

      </Modal.Body>
      <Modal.Footer>
        <Button variant={'secondary'} className={'mr-3'} onClick={onClose}>Cancel</Button>
        {state === WetSigningState.Initial && <Button variant={'primary'} className={'mr-3'} disabled={continueDisabled} onClick={() => skipConfirm ? confirmSigning() : setState(WetSigningState.Confirm)}>Continue</Button>}
        {state === WetSigningState.Confirm && <Button variant={'secondary'} className={'mr-3'} onClick={() => setState(WetSigningState.Initial)}>Back</Button>}
        {state === WetSigningState.Confirm && <Button variant={'primary'} className={'mr-3'} disabled={confirmText.toUpperCase() !== 'WETINK'} onClick={confirmSigning}>Submit</Button>}
      </Modal.Footer>
    </Modal>
  );
}
