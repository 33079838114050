import { DOMConversionMap, DOMConversionOutput, DOMExportOutput, EditorConfig, ElementNode, LexicalEditor, LexicalNode, RangeSelection, SerializedElementNode } from 'lexical';
import { IS_CHROME } from '../shared/src/environment';
import invariant from '../shared/src/invariant';
import { $isConditionContainerNode } from './ConditionContainerNode';
import { domOnBeforeMatch, setDomHiddenUntilFound } from './ConditionUtils';
import { $findMatchingParent } from '@lexical/utils';
import { $isListNode } from '@lexical/list';

type SerializedCollapsibleContentNode = SerializedElementNode;

export function $convertConditionContentElement(domNode: HTMLElement): DOMConversionOutput | null {
  const node = $createConditionContentNode();
  return { node };
}

export class ConditionContentNode extends ElementNode {
  static getType(): string {
    return 'condition-content';
  }

  static clone(node: ConditionContentNode): ConditionContentNode {
    return new ConditionContentNode(node.__key);
  }

  createDOM(config: EditorConfig, editor: LexicalEditor): HTMLElement {
    const dom = document.createElement('div');
    dom.classList.add('condition-content');
    if (IS_CHROME) {
      editor.getEditorState().read(() => {
        const containerNode = this.getParentOrThrow();
        invariant(
          $isConditionContainerNode(containerNode),
          'Expected parent node to be a CollapsibleContainerNode',
        );
        if (!containerNode.__open) {
          setDomHiddenUntilFound(dom);
        }
      });
      domOnBeforeMatch(dom, () => {
        editor.update(() => {
          const containerNode = this.getParentOrThrow().getLatest();
          invariant(
            $isConditionContainerNode(containerNode),
            'Expected parent node to be a CollapsibleContainerNode',
          );
          if (!containerNode.__open) {
            containerNode.toggleOpen();
          }
        });
      });
    }
    return dom;
  }

  updateDOM(prevNode: this, dom: HTMLElement): boolean {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-condition-content')) {
          return null;
        }
        return {
          conversion: $convertConditionContentElement,
          priority: 2
        };
      }
    };
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('div');
    element.setAttribute('data-lexical-condition-content', 'true');
    return { element };
  }

  static importJSON(
    serializedNode: SerializedCollapsibleContentNode,
  ): ConditionContentNode {
    return $createConditionContentNode().updateFromJSON(serializedNode);
  }

  isShadowRoot(): boolean {
    return true;
  }
}

export function $createConditionContentNode(): ConditionContentNode {
  return new ConditionContentNode();
}

export function $isConditionContentNode(node: LexicalNode | null | undefined): node is ConditionContentNode {
  return node instanceof ConditionContentNode;
}

export function $isSelectionOnFirstLineOfCondition(selection: RangeSelection): boolean {
  if (!selection) return false;
  const textNode = selection.anchor.getNode();
  const parentContentNode = $findMatchingParent(textNode, $isConditionContentNode);
  if (!parentContentNode) return false;

  let firstChild = parentContentNode.getFirstChild();
  if ($isListNode(firstChild)) {
    firstChild = firstChild.getFirstChild();
  }

  return (firstChild === textNode.getParent() || firstChild === textNode);
}

export function $isSelectionOnLastLineOfCondition(selection: RangeSelection): boolean {
  if (!selection) return false;
  const textNode = selection.anchor.getNode();
  const parentContentNode = $findMatchingParent(textNode, $isConditionContentNode);
  if (!parentContentNode) return false;
  return (parentContentNode?.getLastChild() === textNode.getParent() || parentContentNode?.getLastChild() === textNode);
}
