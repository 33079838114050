import { WizardStepPage, WizardStepPageProps } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { PrimaryAgentInput } from '@property-folders/components/dragged-components/form/PrimaryAgentInput';
import { AssociatedAgentsInput } from '@property-folders/components/dragged-components/form/AssociatedAgentsInput';
import { AgencyTermInput } from '@property-folders/components/dragged-components/form/AgencyTermInput';
import { PreviousAgentInput } from '@property-folders/components/dragged-components/form/PreviousAgentsInput';
import { AgentAuthorityInput } from '@property-folders/components/dragged-components/form/AgentAuthorityInput';
import { ShowGuidanceNotesButton } from '../guidance/ShowGuidanceNotesButton';
import { useContext } from 'react';
import { FormContext } from '@property-folders/components/context/FormContext';
export function AgentWizardStepPage(props: Partial<WizardStepPageProps> & { preferEditAuthRep?: boolean }) {
  const { authRepMode } = useContext(FormContext);
  const showAgentOnly = !authRepMode;
  const embedded = 'embedded' in props && props.embedded;
  const editAuthRep = !!props.preferEditAuthRep && !!authRepMode;
  return <WizardStepPage
    name="agent"
    label={editAuthRep ? 'Agent\'s Authorised Representative' : 'Agent'}
    icon="real_estate_agent"
    variationWatchPaths={props.variationWatchPaths}
    embedded={embedded}
  >
    <div className='subsection scrollspy-target' data-focus-path="subsection-primary-agent">
      <PrimaryAgentInput showHeading={false} authRepMode={editAuthRep} />
    </div>
    {showAgentOnly && <div className="subsection scrollspy-target" data-focus-path="subsection-associated-agents">
      <div className="fs-4 d-flex align-items-center">Associated agents <ShowGuidanceNotesButton
        noteId="associatedAgents"/></div>
      <AssociatedAgentsInput/>
    </div>}
    {showAgentOnly && <div className="subsection scrollspy-target" data-focus-path="subsection-agency-term">
      <div className="fs-4">Term of Agreement</div>
      <AgencyTermInput/>
    </div>}
    {!props.shortMode && showAgentOnly && <div className="subsection scrollspy-target" data-focus-path="subsection-previous-agents">
      <div className="fs-4 d-flex align-items-center">Previous agents <ShowGuidanceNotesButton noteId="previousAgent"/>
      </div>
      <PreviousAgentInput/>
    </div>}
    {showAgentOnly && <div className="subsection scrollspy-target" data-focus-path="subsection-agent-authority">
      <div className="fs-4">Agent authority</div>
      <AgentAuthorityInput/>
    </div>}

  </WizardStepPage>;
}
