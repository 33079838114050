import {
  Dimension,
  drawCompoundSignatureWithinDimensions,
  getDimensionsOfSignaturePartOfCompoundSignature
} from '@property-folders/common/signing/draw-compound-signature';
import { useEffect, useState } from 'react';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import clsJn from '@property-folders/common/util/classNameJoin';
import { loadImage } from 'canvas';
import { TimestampPosition } from '@property-folders/contract';
import { frameSignature } from '@property-folders/common/util/image/frame-signature';
import { renderWithCanvasContext } from '@property-folders/common/util/image/render-with-canvas-context';
import { removeImage } from '@property-folders/common/util/image/remove-canvas';

export function StackedFieldImage({
  image: rawImage,
  name,
  timestamp,
  timestampPosition,
  clearable,
  timeZone,
  dimensions,
  pixelsPerInch,
  frame
}: {
  image: string,
  name?: string,
  timestamp?: number,
  timestampPosition?: TimestampPosition
  clearable: boolean,
  timeZone: string,
  dimensions: Dimension,
  pixelsPerInch: number,
  frame?: {
    top: string,
    bot: string;
  }
}) {
  const [image, setImage] = useState<string>('');

  useEffect(() => {
    (async () => {
      const loaded = await loadImage(rawImage);
      const cleanups = [() => removeImage(loaded)];
      try {
        const framed = frameSignature(
          loaded,
          frame?.top,
          frame?.bot,
          getDimensionsOfSignaturePartOfCompoundSignature({
            dimensions,
            textPixelsPerInch: pixelsPerInch
          })
        );
        if (!framed) throw new Error('Could not generate signature frame');

        const signature = await loadImage(framed);
        cleanups.push(() => removeImage(signature));

        const stacked = renderWithCanvasContext((canvas, context) => {
          drawCompoundSignatureWithinDimensions({
            context: context,
            signature,
            name: name || '',
            timestamp: timestamp && timestamp > 0
              ? formatTimestamp(timestamp, timeZone, false)
              : 'Signature upload pending',
            dimensions,
            textPixelsPerInch: pixelsPerInch,
            timestampPosition
          });
        }, { width: dimensions.width, height: dimensions.height });
        if (!stacked) throw new Error('Could not generate stacked signature');
        setImage(stacked);
      } finally {
        for (const cleanup of cleanups) {
          cleanup();
        }
      }
    })().catch(console.error);
  }, [name, timestamp]);

  if (!image) return <></>;

  return <img
    src={image}
    className={clsJn({
      'h-100': true,
      'w-100': true,
      'clearable-field': clearable })}
    style={{
      aspectRatio: dimensions.width / dimensions.height
    }} />;
}
