import { SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import { determineIfFormPassesConditions } from '@property-folders/common/util/stagedDataTransformations';
import { FormDescriptorRecord } from '@property-folders/common/yjs-schema/property';
import { FormCode, FormCodeUnion, FormFamilyState, FormStates } from '@property-folders/contract';
import { ContractMgmtOverviewCard } from '../../dragged-components/ContractMgmtOverviewCard';
import { ContractRootKeySummary } from '../../hooks/useAlternateRootKeyContracts';
import { FormInstanceCard } from '../../dragged-components/FormInstanceCard';
import { useNavigate } from 'react-router-dom';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { OfferManagementCardBody } from '../../dragged-components/OfferManagmentCardBody';
import { PurchaserManagementCardBody } from '../../dragged-components/PurchaserManagmentCardBody';
import { compareFormInstances } from '@property-folders/common/util/compareFormInstances';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { FormUtil } from '@property-folders/common/util/form';
import { allVendorsSigned } from '../../dragged-components/signing/ServeToPurchaserSection';

export function FormCodeCard ({
  formFamilyState,
  formDefinition,
  formCode,
  formStates,
  vqIsBeingPrepared,
  excludeExpiredFamilyVariations,
  onForceFocus,
  contracts,
  handleContractCreate,
  forceFocusId,
  createAndGoToFormOrder,
  headline,
  propertyId,
  createAndGoToForm,
  navigateToForm,
  debugReleaseForm,
  setShowServeToNewPurchaserParams,
  debugDeleteForm,
  setFormFamilyContext,
  preventForm1Create
}: {
  formCode: FormCodeUnion
  formDefinition: FormDescriptorRecord
  formFamilyState: FormFamilyState | undefined
  formStates: FormStates | undefined
  vqIsBeingPrepared: boolean
  contracts: ContractRootKeySummary[]
  excludeExpiredFamilyVariations: (descriptorMapped: [string, FormDescriptorRecord]) => boolean
  forceFocusId: string | null
  onForceFocus: (FormCode: FormCodeUnion, forceMe: boolean)=>void
  handleContractCreate: ()=>void
  createAndGoToFormOrder: (formCode: FormCodeUnion) => Promise<void>
  createAndGoToForm: (formCode: FormCodeUnion, opts?: {verbosity?: string}) => Promise<void>
  propertyId: string,
  headline: string
  navigateToForm: (instanceId: string, formCode: string)=>void
  debugReleaseForm: (instanceId: string, formCode: string)=>void
  setShowServeToNewPurchaserParams: (newState: { formCode: string, formId: string } | undefined) => void
  debugDeleteForm: (instanceId: string, formCode: string) => void
  setFormFamilyContext: (code: FormCodeUnion) => void,
  preventForm1Create: boolean
}) {
  const navigate = useNavigate();
  const formInstances = formFamilyState?.instances;
  const { alreadyPresent, dependencyPassed, failedInstances } = determineIfFormPassesConditions(
    formCode,
    formDefinition.suggestion,
    formStates
  );

  const instancesExist = Array.isArray(formInstances) && formInstances.length > 0;

  if (!instancesExist) {
    if (formCode === SubscriptionFormCode.SAR008_VendorQuestionnaire && vqIsBeingPrepared) {
      return <></>;
    }

    const actionable = Boolean(
      formDefinition.suggestion
                && !alreadyPresent
                && dependencyPassed
                && !formDefinition.renderOpts?.notYetImplemented
    );

    if (!(formDefinition.suggestion && !alreadyPresent)) {
      return <></>;
    }

    if (!excludeExpiredFamilyVariations([formCode, formDefinition])) {
      return <></>;
    }

    if (formCode === FormCode.ContractManagement) {
      return <ContractMgmtOverviewCard
        contracts={contracts}
        onContractCreate={handleContractCreate}
        onContractFromOfferCreate={console.warn}
        renderOpts={formDefinition.renderOpts}
        notActionable={!actionable}
        onForceFocus={(forceMe)=>onForceFocus(formCode, forceMe)}
        forceFocus={forceFocusId ? forceFocusId === formCode : undefined}
      />;
    }

    return <FormInstanceCard
      key={formCode}
      onOrder={formDefinition.orderable ? () => createAndGoToFormOrder(formCode) : undefined}
      onOpen={preventForm1Create ?
        undefined :
        () => formDefinition.navigateTo
          ? navigate(LinkBuilder.propertyPagePath({ id: propertyId, nicetext: headline }, formDefinition.navigateTo))
          : createAndGoToForm(formCode)}
      onCreate={preventForm1Create
        ? undefined
        :({ verbosity }) => createAndGoToForm(formCode, { verbosity })}
      fCode={formCode}
      cardClass="form-adder"
      renderOpts={formDefinition.renderOpts}
      notActionable={!actionable}
      failedFormCodeList={failedInstances}
      onForceFocus={onForceFocus}
      forceFocus={forceFocusId ? forceFocusId === formCode : undefined}
    >
      {
        formCode === FormCode.OfferManagement
          ? <OfferManagementCardBody propertyId={propertyId} headline={headline}></OfferManagementCardBody>
          : formCode === FormCode.PurchaserManagement
            ? <PurchaserManagementCardBody propertyId={propertyId} headline={headline}></PurchaserManagementCardBody>
            : undefined
      }

    </FormInstanceCard>;
  } else {
    const openText = formInstances.length > 1
      ? 'Open Latest'
      : 'Open';
    const instance = formInstances[0];
    const latestInstance = [...formInstances].sort(compareFormInstances)[0];
    const creatableCodes= FormUtil.getCreateableFormCodes(formStates, formCode);

    const creatableCode = creatableCodes.length === 1
      ? creatableCodes[0]
      : undefined;

    return <FormInstanceCard
      key={formCode}
      instance={instance}
      formFamilyState={formFamilyState}
      fCode={formCode}
      onOpen={() => navigateToForm(latestInstance.id, latestInstance.formCode)}
      onViewOrder={latestInstance.order?.job?.id
        ? () => navigateToForm(latestInstance.id, latestInstance.formCode)
        : undefined}
      onSimRelease={() => debugReleaseForm(latestInstance.id, latestInstance.formCode)}
      onServe={latestInstance.formCode && FormTypes[latestInstance.formCode].serveToPurchaser && allVendorsSigned(latestInstance.signing)
        ? () => setShowServeToNewPurchaserParams({ formId: latestInstance.id, formCode: latestInstance.formCode })
        : undefined}
      onDelete={FormTypes[latestInstance.formCode].orderable ? () => debugDeleteForm(latestInstance.id, latestInstance.formCode) : undefined}
      onOpenRelated={() => setFormFamilyContext(formCode)}
      onCreateAnother={creatableCode ? () => createAndGoToForm(creatableCode) : undefined}
      openText={openText}
      onForceFocus={onForceFocus}
      forceFocus={forceFocusId ? forceFocusId === formCode : undefined}
      variationRecommendationEnabled={true}
    />;
  }
}
