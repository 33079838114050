import { DOMConversionOutput, DOMExportOutput, EditorConfig, ElementNode, LexicalEditor, LexicalNode, SerializedElementNode } from 'lexical';
import { IS_CHROME } from '../shared/src/environment';
import invariant from '../shared/src/invariant';
import { $isConditionContainerNode } from './ConditionContainerNode';

type SerializedConditionTitleNode = SerializedElementNode;

export function $convertSummaryElement(domNode: HTMLElement): DOMConversionOutput | null {
  const node = $createConditionTitleNode();
  return { node };
}

export class ConditionTitleNode extends ElementNode {
  static getType(): string {
    return 'condition-title';
  }

  static clone(node: ConditionTitleNode): ConditionTitleNode {
    return new ConditionTitleNode(node.__key);
  }

  createDOM(config: EditorConfig, editor: LexicalEditor): HTMLElement {
    const dom = document.createElement('summary');
    dom.classList.add('condition-title');
    if (IS_CHROME) {
      dom.addEventListener('click', () => {
        editor.update(() => {
          const collapsibleContainer = this.getLatest().getParentOrThrow();
          invariant(
            $isConditionContainerNode(collapsibleContainer),
            'Expected parent node to be a CollapsibleContainerNode',
          );
          collapsibleContainer.toggleOpen();
        });
      });
    }
    return dom;
  }

  updateDOM(prevNode: this, dom: HTMLElement): boolean {
    return false;
  }

  //cont export this, it will be re-created on import
  exportDOM(): DOMExportOutput {
    return { element: null };
  }

  static importJSON(serializedNode: SerializedConditionTitleNode): ConditionTitleNode {
    return $createConditionTitleNode().updateFromJSON(serializedNode);
  }

  static transform(): (node: LexicalNode) => void {
    return (node: LexicalNode) => {
      invariant(
        $isConditionTitleNode(node),
        'node is not a CollapsibleTitleNode',
      );
      if (node.isEmpty()) {
        node.remove();
      }
    };
  }
}

export function $createConditionTitleNode(): ConditionTitleNode {
  return new ConditionTitleNode();
}

export function $isConditionTitleNode(
  node: LexicalNode | null | undefined,
): node is ConditionTitleNode {
  return node instanceof ConditionTitleNode;
}
