import React, { ChangeEvent, ReactElement, useEffect, useRef } from 'react';
import { Button, FloatingLabel, Form, FormControl } from 'react-bootstrap';
import { useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { PresentUsersList } from '../presence/PresentUsersList';
import { Maybe } from '@property-folders/common/types/Utility';
import clsJn from '@property-folders/common/util/classNameJoin';
import './FormCheckOverride.scss';
import { tryParseInt } from '@property-folders/common/util';
import './CommonComponentWrappers.scss';
import { IconClickCheck, TextClickCheck } from './TextClickCheck';

import { AsyncTypeahead, Hint, Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { FilterByCallback, Option, TypeaheadInputProps, TypeaheadManagerChildProps } from 'react-bootstrap-typeahead/types/types';
import { Predicate } from '@property-folders/common/predicate';
import { PathType } from '@property-folders/contract/yjs-schema/model';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { ShowGuidanceNotesButton } from '../guidance/ShowGuidanceNotesButton';
import { notesTable } from '../../display/GuidanceNotes';

type ElemOrString = JSX.Element | string;

type WrFieldProps = TransactionConsumerProps & {
  name: string,
  useCanonical?: boolean
  textEnd?: boolean
  deleteParentItemEmpty?: boolean
};

type GuidanceNotes = {
  guidanceNoteId?: string;
};

type WrFieldSelectProps = WrFieldProps & GuidanceNotes & {
  label?: string,
  options: {[name:string]: string} | {name: string, label: string, groupheading?: boolean, groupentry?: boolean, disabled?: boolean}[] // value: label
  valueType?: 'boolean' | 'int' // If value type is boolean, use strings true and false for meaning
  tight?: boolean // Move chevron
  autoFocus?: boolean
  canClear?: boolean
  optionValueFilter?: (oldValue: string | undefined)=>string|undefined
  optionRender?: (option: Option, index: number) => JSX.Element
  inputRender?: (inputProps: TypeaheadInputProps, props: TypeaheadManagerChildProps) => JSX.Element;
};

type WrFieldRadioCheckProps = WrFieldProps & {
  label?: string | ReactElement,
  options?:
  {
    [name:string]: (string | {label: string|ReactElement, order?: number, disabled?: boolean})
  }
  | {name: string, label: string|ReactElement, groupheading?: boolean, groupentry?: boolean}[] // value: label
  valueType?: 'boolean' | 'int' | 'string' // If value type is boolean, use strings true and false for meaning
};

export type WrFieldControlProps = WrFieldProps & GuidanceNotes & {
  id?: string;
  label?: ElemOrString;
  type?: string;
  placeholder?: string;
  maxLength?: number;
  minLength?: number;
  setDefaultValue?: string;
  textArea?: {
    rows?: number;
    minHeight?: string;
    height?: string;
  } | true;
  className?: string;
  containerClassName?: string;
  noBlurAction?: boolean;
  onChange?: (event: React.ChangeEvent<typeof FormControl>) => void;
  onBlur?: (e: React.FocusEvent<typeof FormControl, Element>) => void;
  onFocus?: (e: React.FocusEvent<typeof FormControl, Element>) => void;
  spellCheck?: boolean;
  autoComplete?: string;
  inputMode?: string;
  defaultValueLabel?: string;
  alwaysShowDefaultSetter?: boolean;
  dateFromToday?: boolean;
  /**
   * Formatted YYYY-MM-DD
   */
  dateFromOther?: string;
  dateUntilToday?: boolean;
  extraErrors?: string[];
  disabled?: boolean;
  valuePlaceholder?: string;
  autoFocus?: boolean;
  inlineSpan?: boolean;
  hasAsterisks?: boolean;
  invalidCharacterRegex?: RegExp;
  warn?: (value: any) => boolean;
  minWidth?: string;
};

type WrAutoCompleteProps = WrFieldControlProps & GuidanceNotes & {
  label?: ElemOrString
  onSuggestSelect?: (selection: Option[], selectedFromPath?:PathType)=>void;
  onlySelectCallback?: boolean
  options: Option[]
  optionRender?: (option: Option, index: number) => JSX.Element
  extraRowData?: JSX.Element
  selectOnly?: boolean
  onChange?: (event: React.ChangeEvent<typeof FormControl>) => void
  onBlur?: (e: React.FocusEvent<typeof FormControl, Element>) => void
  onFocus?: (e: React.FocusEvent<typeof FormControl, Element>) => void
  spellCheck?: boolean
  canClear?: boolean
  onClear?: ()=>void
  valueType?: string;
  autocompleteLabelKey?: string;
  inputRender?: (inputProps: TypeaheadInputProps, props: TypeaheadManagerChildProps) => JSX.Element;
  menuClassName?: string;
};

type WrAsyncAutoCompleteProps = WrAutoCompleteProps & {
  isLoading: boolean;
  onSearch: (query: string) => void;
  promptText?: JSX.Element | string;
  searchText?: JSX.Element | string;
  debounceMs?: number;
  filterBy?: string[] | FilterByCallback;
  useCache?: boolean;
  onInputChange?: (text: string, event: ChangeEvent<HTMLInputElement>) => void;
};

type WrTextAreaProps = WrFieldProps & {
  label?: ElemOrString
  placeholder?: string;
  rows?: number;
  minHeight?: string;
  maxLength?: number;
  showLengthRemaining?: boolean;
};

type WrTextProps = WrFieldProps & {
  placeholder?: string;
  maxLength?: number;
  showLengthRemaining?: boolean;
  disabled?: boolean;
  className?: string;
  label?: ElemOrString;
  extraErrors?: string[];
};

const errorLookup: Record<string, any> = {
  email: 'Invalid email',
  notBeforeOtherDate: 'Date must be after special condition settlement date',
  stillInvalidAfterProcessing: 'This field is required'
  /*required: 'This field is required' // Yeah but do we really need to flag this straight away? How can we make this only appear after defocus?*/
};

const displayErrorKeys = new Set(Object.keys(errorLookup));

export const generateStandardFieldWrapping = (
  label: Maybe<ElemOrString>,
  errorKeys: string[],
  required: boolean,
  fullPath: string,
  children: JSX.Element,
  guidanceContent?: any,
  controlId?: string,
  extraRow?: JSX.Element,
  displaySelectArrow?: boolean,
  extraErrors?: string[],
  postValidationActionMessage?: string,
  hasAsterisks?: boolean,
  extraClasses?: string
) => {
  const usingErrors = errorKeys.filter(key=>displayErrorKeys.has(key) );

  const errorText = extraErrors && extraErrors.length > 0
    ? extraErrors[0]
    : postValidationActionMessage && usingErrors[0] === 'stillInvalidAfterProcessing'
      ? postValidationActionMessage
      : (usingErrors.length > 0 ? errorLookup[usingErrors[0]] : null);

  const innerContents = <>
    <PresentUsersList pathPrefix={fullPath} offset={true}></PresentUsersList>
    {children}

    {extraRow && <div className={clsJn('extra-row d-flex', displaySelectArrow && 'pad-row-right')}>{extraRow}</div>}
    {guidanceContent && <div>{guidanceContent}</div>}
    {/* d-block to force invalid feedback to show, as we're already conditionally displaying it */}
    {!extraRow && errorText && <div className='d-block invalid-feedback'>{errorText}</div>}
  </>;
  const errorFocusClass = composeErrorPathClassName(fullPath, undefined);
  return label
    ? <FloatingLabel className={clsJn(errorFocusClass, 'flex-grow-1 common-label', extraRow && 'use-extra-row', extraClasses)} controlId={controlId} label={(required || hasAsterisks) ? <span>{label} <sup style={{ color: 'red' }}>*</sup></span> : label}>
      {innerContents}
    </FloatingLabel>
    : <div className={clsJn(errorFocusClass, 'common-label psuedo-form-floating', extraRow && 'use-extra-row')}>
      {innerContents}
    </div>
  ;
};

/**
 * Calls handleUpdate with data from an element change event, taking into account native actions
 * @param handleUpdate obtained from useTransactionField
 */
function getNativeCompatibleOnChangeHandler(
  handleUpdate: (originalValue: any, immediate?: boolean, eventSource?: (string | undefined), eventData?: (string | undefined)) => void,
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  invalidCharacterRegex?: RegExp
) {
  return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (invalidCharacterRegex) {
      const beforeNotEmpty = event.target.value.length > 0;
      event.target.value = event.target.value.replace(invalidCharacterRegex, '');
      if (beforeNotEmpty && event.target.value.length === 0) {
        event.preventDefault();
        return false;
      }
    }
    // We are overriding paste behaviour if it involves an email address within angle brackets
    const nativeEvt = event.nativeEvent as InputEvent;
    const isDropInsert = nativeEvt.inputType === 'insertFromDrop';
    // We are taking the 'data', meaning in a paste, even if you insert into the middle of
    // something, the preparser will only get what was pasted
    handleUpdate(
      isDropInsert && nativeEvt.data
        ? nativeEvt.data
        : event.target?.value,
      isDropInsert,
      nativeEvt.data
        ? 'insert'
        : undefined
    );
    onChange?.(event);
  };
}

function getRenderedValue(options: Option[], value: any, valueType: string) {
  const calculatedValue = valueType === 'boolean' && typeof value === 'boolean' ? (value ? 'true' : 'false') : value?.toString();
  return options?.find(o => o.name === calculatedValue)?.label || calculatedValue;
}

/**Warning: Does not set values itself when passing in selectOnly + onSuggestSelect
 *
 * The caller is expected to set the values when selectOnly + onSuggestSelect are set, we just
 * listen for feedback from here
 *
 * @param param0.deleteParentItemEmpty See {@link useTransactionField} documentation on field of same name.
 *  note that this parameter only has meaning if all sibling fields also support this parameter
 *  (which at the time of writing, is only this field type)
 * @returns
 */
const AutoComplete = ({
  name,
  label,
  type = 'text',
  placeholder,
  maxLength,
  setDefaultValue,
  textArea,
  textEnd,
  parentPath,
  myPath,
  useCanonical,
  deleteParentItemEmpty,
  className,
  onSuggestSelect,
  options,
  optionRender,
  extraRowData,
  selectOnly,
  onChange,
  onBlur,
  onFocus,
  spellCheck,
  canClear = true,
  onClear,
  valueType,
  autocompleteLabelKey,
  onlySelectCallback,
  filterBy,
  extraErrors,
  guidanceNoteId,
  autoComplete,
  disabled,
  optionValueFilter,
  inputRender,
  ...restProps
}: WrAutoCompleteProps & { filterBy?: (option: Record<string, unknown>|string, props: Record<string, unknown>)=>boolean} & {optionValueFilter: (oldValue: string|undefined)=>string|undefined}) => {
  const {
    value: valueRaw, handleUpdate: handleAutocompleteInputUpdate, valid, errorKeys, required, justUpdated, handleAwarenessBlur, handleAwarenessFocus, fullPath, hasVaried, readOnly, mergedRules
  } = useTransactionField({ bindToMetaKey: restProps.bindToMetaKey, ydocForceKey: restProps.ydocForceKey, parentPath, myPath, useCanonical, deleteParentItemEmpty });
  const value = optionValueFilter ? optionValueFilter(valueRaw) : valueRaw;
  const defaultMode = !value && setDefaultValue !== undefined;
  const maybeProp: {[prop:string]: any} = {};
  if (textArea) {
    maybeProp.as = 'textarea';
    if (typeof textArea === 'object' && textArea.rows) {
      maybeProp.rows = textArea.rows;
    }
  }
  if (spellCheck === undefined) {
    spellCheck = false;
  }
  maybeProp.spellCheck = spellCheck;
  const typeaheadRef = useRef<typeof Typeahead>();
  const ownInputRef = useRef<HTMLInputElement>();

  useEffect(()=>{
    if (ownInputRef.current && Predicate.isNotNullish(value)) {
      const renderedValue = getRenderedValue(options, value, valueType);
      ownInputRef.current.value = `${renderedValue}`;
    }
  }, [value, !!ownInputRef.current]);

  const typeaheadOnChange = (selections: Option[]) => {
  // Clear the internal text buffer, so that all options are suggested
  // next time it is focussed
    typeaheadRef?.current?.clear?.();
    ownInputRef?.current?.blur();
    onSuggestSelect?.(selections, fullPath);
    if (onlySelectCallback && onSuggestSelect) {
      return;
    }
    const selection = selections?.[0] as unknown;
    if (!(selection && typeof selection === 'object' && (autocompleteLabelKey??'label') in selection)) {
      return;
    }
    if (ownInputRef?.current && Predicate.isNotNullish(selection[autocompleteLabelKey??'label'])) {
      ownInputRef.current.value = `${selection[autocompleteLabelKey??'label']}`;
    }
  };

  return <Typeahead
    disabled={disabled||readOnly}
    readOnly={disabled||readOnly}
    ref={typeaheadRef}
    id={fullPath}
    labelKey={autocompleteLabelKey}
    options={options}
    onChange={typeaheadOnChange}
    highlightOnlyResult={true}
    filterBy={filterBy}
    placeholder={placeholder ?? ''}
    renderMenu={(results, { renderMenuItemChildren, newSelectionPrefix, paginationText, ...menuProps }) => {
      if (disabled) return <></>;
      return selectOnly || results.length > 0
        ? <Menu {...menuProps} style={{ overflow: 'visible', padding: 0, right: 0, left: 0, top: label ? 54 : 'unset', filter: 'drop-shadow(0px 0px 3px darkgrey)' }}>
          <div style={{ maxHeight: '280px', overflowY: 'auto', overflowX: 'hidden' }}>
            {results.map(optionRender
              ? (result, index) => <MenuItem key={`${result[autocompleteLabelKey??'label']}-${index}`} option={result} position={index}>{optionRender(result, index)}</MenuItem>
              : (result, index) => (
                <MenuItem key={`${result[autocompleteLabelKey??'label']}-${index}`} disabled={result.groupheading || result.disabled} className={clsJn(result.groupheading && 'fw-bold text-black')} option={result} position={index}>
                  {result[autocompleteLabelKey??'label']}
                </MenuItem>
              ))}
          </div>
          {canClear && value && <div
            className={'position-absolute cursor-pointer btn'}
            style={{ bottom: -38, backgroundColor: '#f4f5fa', padding: 8, textAlign: 'center', border: '1px solid #ced4da', left: -1, right: -1 }}
            onClick={()=> {
              onClear?.();
              typeaheadRef?.current?.hideMenu?.();
              typeaheadRef?.current?.blur?.();
              ownInputRef.current.value = '';
            }}
          >Clear Selection</div>}
        </Menu>
        : <></>;
    }}

    renderInput={({ inputRef, referenceElementRef, ...inputProps }, childProps) => {
      const {
        onChange: autoOnChange,
        onBlur: autoOnBlur,
        onFocus: autoOnFocus,
        value: autoValue,
        className: selectedClassName,
        autoComplete: _autoComplete,
        onClick: autoOnClick,
        ...autocompleteProps
      } = inputProps;

      if (inputRender) {
        return inputRender({ inputRef, referenceElementRef, ...inputProps }, childProps);
      }

      const renderedValue = getRenderedValue(options, value, valueType);

      const controlInput = <Form.Control
        defaultValue={renderedValue}
        ref={(node) => {
          inputRef(node);
          referenceElementRef(node);
          ownInputRef.current = node;
        }}
        autoComplete={autoComplete || 'new-password'}
        spellCheck='false'
        className={clsJn(
          renderedValue && 'form-select',
          className,
          selectedClassName,
          selectOnly && 'fake-input-select',
          valid ? '':'is-invalid',
          textEnd && 'text-end',
          hasVaried && 'varied-control',
        )}
        style={justUpdated ? { backgroundColor: 'lightgrey' } : {}}
        name={name}
        type={type}

        onChange={e => {
          if (!(selectOnly && onSuggestSelect)) {
          // We are overriding paste behaviour if it involves an email address within angle brackets
            const nativeEvt = e.nativeEvent as InputEvent;
            const isDropInsert = nativeEvt.inputType === 'insertFromDrop';
            // We are taking the 'data', meaning in a paste, even if you insert into the middle of
            // something, the preparser will only get what was pasted
            handleAutocompleteInputUpdate(isDropInsert ? nativeEvt.data : e.target?.value, isDropInsert, nativeEvt.data ? 'insert' : undefined);
            onChange?.(e);
          }
          autoOnChange?.(e);
        }}
        onBlur={e => {
          handleAwarenessBlur(e);
          !(selectOnly && onSuggestSelect) && handleAutocompleteInputUpdate(e.target?.value, true);
          typeaheadRef?.current?.clear?.();
          autoOnBlur?.(e);
          onBlur?.(e);
          if (ownInputRef.current && Predicate.isNotNullish(value)) ownInputRef.current.value = `${renderedValue}`;
        }}
        onFocus={e => {
          handleAwarenessFocus(e);
          autoOnFocus?.(e);
          onFocus?.(e);
        }}
        maxLength={maxLength ?? 300}
        {...maybeProp}
        {...restProps}
        {...autocompleteProps}
      />;

      const mainControl = generateStandardFieldWrapping(label, errorKeys, required, fullPath, controlInput, undefined, 'autocompleteinner'+fullPath, extraRowData, selectOnly, extraErrors, mergedRules._validationRequirementFailedMessage);

      return <div>
        <Hint>
          <div
            className={clsJn('flex-grow-1', defaultMode && 'd-flex flex-row')}
            onClick={(e)=>{
              if (readOnly) {
                return;
              }
              ownInputRef.current?.focus();
              autoOnClick?.(e);
            }}
          >
            {mainControl}

            {!disabled && <div className={'cursor-pointer position-absolute top-0 bottom-0'} style={{ right: 0, width: '35px' }}></div>}
          </div>
        </Hint>
        {guidanceNoteId!=null && <div className='position-absolute inner-positioned-guide-icon-container'><ShowGuidanceNotesButton noteId={guidanceNoteId}/></div>}
        {extraRowData && extraErrors && <div className='d-block invalid-feedback'>{extraErrors[0]}</div>}
      </div>;
    }}
  />;
};

function ColourPicker({
  name,
  parentPath,
  myPath,
  ydocForceKey,
  bindToMetaKey,
  defaultValue,
  text,
  containerClassName,
  labelClassName
}: WrFieldProps & { defaultValue?: string, text?: string | JSX.Element, containerClassName?: string, labelClassName?: string }) {
  const {
    value,
    handleUpdate,
    readOnly
  } = useTransactionField({
    myPath,
    parentPath,
    bindToMetaKey,
    ydocForceKey
  });

  useEffect(() => {
    if (value) return;
    if (readOnly) return;
    if (!defaultValue) return;
    handleUpdate(defaultValue, true);
  }, [readOnly, defaultValue, value]);

  return <Form.Group className={clsJn('color-picker', containerClassName)}>
    {(text || name) && <Form.Label title={name} htmlFor={`${parentPath}.${myPath}`} className={clsJn(labelClassName || 'lead', 'me-2')}>{text||name}</Form.Label>}
    <Form.Control
      type="color"
      id={`${parentPath}.${myPath}`}
      value={value}
      className={'cursor-pointer'}
      onChange={e => handleUpdate(e.target.value, true)}
      title={name}
    />
  </Form.Group>;
}

export const WrField = {
  Select: ({
    name,
    label,
    options,
    valueType,
    tight,
    autoFocus,
    canClear,
    guidanceNoteId,
    optionRender,
    inputRender,
    ...restProps
  }: WrFieldSelectProps & Parameters<typeof useTransactionField>[0]) => {
    const { value, handleUpdate, valid, hasVaried, handleRemove } = useTransactionField(restProps);
    const arrayOptions = Array.isArray(options)
      ? options
      : Object.keys(options)?.map(k => ({ name: k, label: options[k] }));

    return <WrField.AutoComplete
      guidanceNoteId={guidanceNoteId}
      options={arrayOptions}
      onSuggestSelect={(s) => {
        const value = s?.[0]?.name;
        const val = valueType === 'boolean'
          ? value === 'true'
          : valueType === 'int'
            ? tryParseInt(value, undefined)
            : value;
        handleUpdate(val, true, undefined, undefined, s?.[0]?.label);
      }}
      canClear={canClear}
      onClear={handleRemove}
      name={name}
      label={label}
      valueType={valueType}
      autoFocus={autoFocus}
      autoComplete='off'
      parentPath={restProps.parentPath}
      myPath={restProps.myPath}
      selectOnly={true}
      optionRender={optionRender}
      inputRender={inputRender}
      className={clsJn(
        'cursor-pointer',
        valid ? '':'is-invalid',
        tight && 'tight-select',
        hasVaried && 'varied-control',
      )}
      {...restProps}
    />;
  },
  AutoComplete,
  AsyncAutoComplete: ({
    name,
    label,
    type = 'text',
    placeholder,
    maxLength,
    minLength,
    setDefaultValue,
    textArea,
    textEnd,
    parentPath,
    myPath,
    useCanonical,
    deleteParentItemEmpty,
    className,
    menuClassName,
    onSuggestSelect,
    options,
    optionRender,
    extraRowData,
    selectOnly,
    onChange,
    onBlur,
    onFocus,
    spellCheck,
    isLoading,
    onSearch,
    promptText,
    searchText,
    debounceMs,
    autocompleteLabelKey,
    filterBy,
    autoComplete,
    disabled,
    useCache,
    onInputChange,
    ...restProps
  }: WrAsyncAutoCompleteProps) => {
    const {
      value, handleUpdate: handleAutocompleteInputUpdate, valid, errorKeys, required, justUpdated, handleAwarenessBlur, handleAwarenessFocus, fullPath, suppressLoad, hasVaried, readOnly, mergedRules
    } = useTransactionField({ parentPath, myPath, useCanonical, deleteParentItemEmpty, bindToMetaKey: restProps.bindToMetaKey });
    const typeaheadRef = useRef<typeof AsyncTypeahead>();
    const ownInputRef = useRef<HTMLInputElement>();

    useEffect(()=>{
      if (ownInputRef.current && Predicate.isNotNullish(value)) {
        ownInputRef.current.value = `${value}`;
      }
    }, [value, !!ownInputRef.current]);

    const typeaheadOnChange: typeof onSuggestSelect = (...params) => {
      // There's no point clearing the async internal text field, as we tend to use this component
      // for much larger datasets where searches are paramount
      const selection = params?.[0]?.[0] as unknown;
      if (!(selection && typeof selection === 'object' && 'label' in selection)) {
        return;
      }
      if (onSuggestSelect) {
        onSuggestSelect(...params);
      } else {
        handleAutocompleteInputUpdate(selection.label, true);
      }
      if (ownInputRef?.current  && Predicate.isNotNullish(selection.label)) {
        ownInputRef.current.value = `${selection.label}`;
      }
    };

    return <AsyncTypeahead
      disabled={readOnly || disabled}
      ref={typeaheadRef}
      delay={debounceMs}
      labelKey={autocompleteLabelKey}
      isLoading={isLoading}
      onSearch={onSearch} // onSearch must not change every time the value is updated. Try making it a useCallback if you're not getting suggestions
      promptText={promptText}
      searchText={searchText}
      minLength={minLength}
      id={fullPath}
      useCache={useCache}
      options={options || []}
      onChange={typeaheadOnChange}
      onInputChange={onInputChange}
      highlightOnlyResult={true}
      placeholder={placeholder ?? ''}
      filterBy={filterBy}
      renderMenu={(results, { renderMenuItemChildren, newSelectionPrefix, paginationText, ...menuProps }) => {

        return <Menu {...menuProps} className={menuClassName}>
          {results.map(optionRender
            ? (result, index) => <MenuItem key={`${result[autocompleteLabelKey??'label']}-${index}`} option={result} position={index}>{optionRender(result, index)}</MenuItem>
            : (result, index) => (
              <MenuItem key={`${result[autocompleteLabelKey??'label']}-${index}`} option={result} position={index}>
                {result.label}
              </MenuItem>
            ))}
        </Menu>;
      }}

      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
        const {
          onChange: autoOnChange,
          onBlur: autoOnBlur,
          onFocus: autoOnFocus,
          value: autoValue,
          className: selectedClassName,
          autoComplete: _autoComplete,
          onClick: autoOnClick,
          ...autocompleteProps
        } = inputProps;

        const defaultMode = !value && setDefaultValue !== undefined;
        const maybeProp: {[prop:string]: any} = {};
        if (textArea) {
          maybeProp.as = 'textarea';
          if (typeof textArea === 'object' && textArea.rows) {
            maybeProp.rows = textArea.rows;
          }
        }
        if (spellCheck === undefined) {
          spellCheck = false;
        }
        maybeProp.spellCheck = spellCheck;

        const renderedValue = autoValue || value;

        const controlInner = <Form.Control
          defaultValue={renderedValue}
          ref={(node) => {
            inputRef(node);
            referenceElementRef(node);
            ownInputRef.current = node;
          }}
          autoComplete={autoComplete}
          spellCheck='false'
          className={clsJn(
            className,
            selectedClassName,
            selectOnly && 'fake-input-select',
            valid ? '':'is-invalid',
            textEnd && 'text-end',
            hasVaried && 'varied-control',
          )}
          style={justUpdated ? { backgroundColor: 'lightgrey' } : {}}
          name={name}
          type={type}
          onChange={e => {
            autoOnChange?.(e);
            if (!(selectOnly && onSuggestSelect)) {
              const nativeEvt = e.nativeEvent as InputEvent;
              const isDropInsert = nativeEvt.inputType === 'insertFromDrop';

              handleAutocompleteInputUpdate(isDropInsert ? nativeEvt.data : e.target?.value, isDropInsert, nativeEvt.data ? 'insert' : undefined);
              onChange?.(e);
            }
          }}
          onBlur={e => {
            handleAwarenessBlur(e);
            !(selectOnly && onSuggestSelect) && handleAutocompleteInputUpdate(e.target?.value, true);
            autoOnBlur?.(e);
            onBlur?.(e);
            if (ownInputRef.current && Predicate.isNotNullish(value)) ownInputRef.current.value = `${value}`;
          }}
          onFocus={e => {
            handleAwarenessFocus(e);
            autoOnFocus?.(e);
            onFocus?.(e);
          }}
          maxLength={maxLength ?? 300}
          {...maybeProp}
          {...restProps}
          {...autocompleteProps}
        />;
        const mainControl = generateStandardFieldWrapping(label, errorKeys, required, fullPath, controlInner, undefined, 'autocompleteinner'+fullPath, extraRowData, selectOnly, undefined, mergedRules._validationRequirementFailedMessage);

        return <Hint>
          <div
            className={clsJn('flex-grow-1', defaultMode && 'd-flex flex-row')}
            onClick={(e)=>{
              if (readOnly) {
                return;
              }
              ownInputRef.current?.focus();
              autoOnClick?.(e);
            }}
          >
            {mainControl}
          </div>
        </Hint>;
      }}
    />;

  },
  Control: ({
    id,
    name,
    label,
    type = 'text',
    placeholder,
    maxLength,
    setDefaultValue,
    defaultValueLabel,
    textArea,
    textEnd,
    parentPath,
    myPath,
    ydocForceKey,
    useCanonical,
    deleteParentItemEmpty,
    className,
    containerClassName,
    onChange,
    onBlur,
    onFocus,
    spellCheck,
    autoComplete,
    inputMode,
    alwaysShowDefaultSetter = false,
    dateFromToday = false,
    dateFromOther,
    dateUntilToday = false,
    extraErrors,
    valuePlaceholder,
    guidanceNoteId,
    inlineSpan,
    hasAsterisks,
    invalidCharacterRegex,
    warn,
    minWidth,
    ...restProps
  }: WrFieldControlProps) => {
    const {
      value,
      handleUpdate,
      valid,
      errorKeys,
      required,
      justUpdated,
      handleAwarenessBlur,
      handleAwarenessFocus,
      fullPath,
      hasVaried,
      readOnly,
      mergedRules,
      subtype
    } = useTransactionField({ parentPath, myPath, useCanonical, deleteParentItemEmpty, ydocForceKey, bindToMetaKey: restProps.bindToMetaKey });

    const defaultMode = !readOnly && (!value || alwaysShowDefaultSetter) && setDefaultValue !== undefined;
    const maybeProp: {[prop:string]: any} = {};
    const showWarn = typeof warn === 'function'
      ? warn(value)
      : false;
    if (textArea) {
      maybeProp.as = 'textarea';
      if (typeof textArea === 'object' && textArea.rows) {
        maybeProp.rows = textArea.rows;
      }
    }
    if (type === 'date') {
      // In the unlikely scenario of a poor browser
      maybeProp.pattern = '\\d{4}-\\d{2}-\\d{2}';
      maybeProp.placeholder = 'YYYY-MM-DD';
      if (dateFromToday) {
        const d = new Date();
        maybeProp.min = `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${(d.getDate()).toString().padStart(2, '0')}`;
      }
      if (dateFromOther && /\d{4}-\d{2}-\d{2}/.test(dateFromOther)) {
        // Probably could do some validation here, that it isn't the 48th day of the 13th month, but
        // things would have to get pretty silly for that
        maybeProp.min = dateFromOther;
      } else if (dateFromOther) {
        console.warn('Invalid date format for earliest date');
      }
      if (dateUntilToday) {
        const d = new Date();
        maybeProp.max = `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${(d.getDate()).toString().padStart(2, '0')}`;
      }

    }
    if (type === 'time') {
      maybeProp.step = 60 * 5;
      maybeProp.min = '06:00';
      maybeProp.max = '22:00';
    }
    if (spellCheck === undefined) {
      spellCheck = false;
    }
    maybeProp.spellCheck = spellCheck;
    if (autoComplete === undefined) {
      autoComplete = 'new-password';
    }
    maybeProp.autoComplete = autoComplete;

    if (inputMode) {
      maybeProp.inputMode = inputMode;
    }

    let subtypeInvalidCharacterRegex: RegExp | undefined;
    if (['abnacn', 'abnacnAnyValid'].includes(subtype)) {
      subtypeInvalidCharacterRegex = /[^\d() ]/;
    }

    const controlInner = <>
      <Form.Control
        disabled={readOnly}
        className={clsJn(
          hasVaried && 'varied-control',
          valuePlaceholder && 'always-placeholder',
          className,
          !(Array.isArray(extraErrors) && extraErrors.length > 0) && valid ? '':'is-invalid',
          textEnd && 'text-end',
          showWarn && 'softwarn'
        )}
        style={{
          backgroundColor: justUpdated ? 'lightgrey' : undefined,
          minHeight: typeof textArea === 'object' ? textArea.minHeight : undefined,
          height: typeof textArea === 'object' ? textArea.height : undefined,
          resize: typeof textArea === 'object' ? 'none' : undefined,
          ...minWidth && { minWidth: minWidth }
        }}
        name={name}
        type={type}
        placeholder={placeholder ?? valuePlaceholder ?? ''}
        value={value ?? ''}
        onChange={getNativeCompatibleOnChangeHandler(handleUpdate, onChange, invalidCharacterRegex ?? subtypeInvalidCharacterRegex)}
        onBlur={e => {
          handleAwarenessBlur(e);
          handleUpdate(e.target?.value, true);
          onBlur?.(e);
        }}
        onFocus={e => {
          handleAwarenessFocus(e);
          onFocus?.(e);
        }}
        maxLength={maxLength ?? 300}
        {...maybeProp}
        {...restProps}
      />
    </>;
    const mainControl = label
      ? generateStandardFieldWrapping(label, errorKeys, required, fullPath, controlInner, undefined, id??fullPath, undefined, undefined, extraErrors, mergedRules._validationRequirementFailedMessage, hasAsterisks, textArea ? 'form-floating-textarea' : '')
      : controlInner;
    const errorFocusClass = composeErrorPathClassName(fullPath, undefined);

    const unwrappedInnards = <>
      {mainControl}
      {defaultMode && <Button
        title='Apply this common value'
        variant='light'
        style={{ maxHeight: '51px' }}
        onClick={()=>handleUpdate(setDefaultValue, true)}
      >
        {defaultValueLabel ?? setDefaultValue}
      </Button>}
      {guidanceNoteId && <div className='position-absolute inner-positioned-guide-icon-container'>
        <ShowGuidanceNotesButton noteId={guidanceNoteId} />
      </div>}
    </>;

    return inlineSpan
      ? <span className={clsJn(errorFocusClass, containerClassName, 'position-relative')}>{unwrappedInnards}</span>
      : <div className={clsJn(defaultMode && 'd-flex flex-row flex-grow-1', errorFocusClass, containerClassName, 'position-relative')}>
        {unwrappedInnards}
      </div>;
  },
  TextArea: ({ name, label, placeholder, rows, minHeight, maxLength, showLengthRemaining, textEnd, ...restProps }: WrTextAreaProps) => {
    const {
      value, handleUpdate, valid, errorKeys, required, justUpdated, handleAwarenessBlur, handleAwarenessFocus, fullPath, hasVaried, readOnly, mergedRules
    } = useTransactionField<string>(restProps);
    const backgroundColor = justUpdated
      ? 'lightgrey'
      : undefined;
    const sanMaxLength = maxLength && maxLength > 0
      ? maxLength
      : 300;
    const remaining = value
      ? sanMaxLength - value.length
      : sanMaxLength;

    const controlInner = <Form.Control
      disabled={readOnly}
      as={'textarea'}
      className={clsJn(
        valid ? '':'is-invalid',
        textEnd && 'text-end',
        hasVaried && 'varied-control',
      )}
      style={{ backgroundColor, minHeight }}
      name={name}
      type={'text'}
      rows={rows}
      placeholder={placeholder ?? ''}
      value={value ?? ''}
      onChange={getNativeCompatibleOnChangeHandler(handleUpdate)}
      onBlur={e => {
        handleAwarenessBlur(e);
        handleUpdate(e.target?.value, true);
      }}
      onFocus={e=>{
        handleAwarenessFocus(e);
      }}
      maxLength={sanMaxLength}
    />;
    const mainControl = label
      ? generateStandardFieldWrapping(label, errorKeys, required, fullPath, controlInner, undefined, undefined, undefined, undefined, undefined, mergedRules._validationRequirementFailedMessage)
      : controlInner;
    return <div>
      {mainControl}
      {showLengthRemaining && <p>Characters remaining: {remaining}</p>}
    </div>;
  },
  Text: ({ name, label, placeholder, maxLength, showLengthRemaining, textEnd, disabled: forceReadOnly, className, extraErrors, ...restProps }: WrTextProps) => {
    const {
      value, handleUpdate, valid, errorKeys, required, justUpdated, handleAwarenessBlur, handleAwarenessFocus, fullPath, hasVaried, readOnly, mergedRules
    } = useTransactionField<string>(restProps);
    const backgroundColor = justUpdated
      ? 'lightgrey'
      : undefined;
    const sanMaxLength = maxLength && maxLength > 0
      ? maxLength
      : 300;
    const remaining = value
      ? sanMaxLength - value.length
      : sanMaxLength;

    const controlInner = <Form.Control
      disabled={readOnly || forceReadOnly}
      className={clsJn(
        valid ? '':'is-invalid',
        textEnd && 'text-end',
        hasVaried && 'varied-control',
        composeErrorPathClassName(fullPath, undefined)
      )}
      style={{ backgroundColor }}
      name={name}
      type={'text'}
      placeholder={placeholder ?? ''}
      value={value ?? ''}
      onChange={getNativeCompatibleOnChangeHandler(handleUpdate)}
      onBlur={e => {
        handleAwarenessBlur(e);
        handleUpdate(e.target?.value, true);
      }}
      onFocus={e=>{
        handleAwarenessFocus(e);
      }}
      maxLength={sanMaxLength}
    />;
    const mainControl = label
      ? generateStandardFieldWrapping(label, errorKeys, required, fullPath, controlInner, undefined, undefined, undefined, undefined, extraErrors, mergedRules._validationRequirementFailedMessage)
      : controlInner;
    return <div className={className}>
      {mainControl}
      {showLengthRemaining && <p>Characters remaining: {remaining}</p>}
    </div>;
  },
  CheckRadio: ({ name, label, options, valueType = 'string', radioType, inline = true, guidanceNotesMap, titleGuidanceNoteKey, style, className, sublists, disabled: allDisabled, overrideValue, afterChange, ...restProps }: WrFieldRadioCheckProps & {
    radioType?: 'checkbox' | 'radio',
    style?: React.CSSProperties,
    inline?: boolean,
    disabled?: boolean
    guidanceNotesMap?: {[optionValue: string]: keyof typeof notesTable}
    titleGuidanceNoteKey?: keyof typeof notesTable,
    sublists?: {[position: number]: ReactElement}, // 0 is before first option, 1 is after first option, 2 after the second option etc.
    overrideValue?: string | null, // Value is not put through filter, so must be of string type, being either a string number or the strings true and false... I think. Certainly if specified as 'int' or 'boolean' type
    afterChange?: (val:any) => void,
  }) => {
    const {
      value, handleUpdate, valid, errorKeys, mergedRules, required, justUpdated, handleAwarenessBlur, handleAwarenessFocus, fullPath, hasVaried, readOnly, handleRemove
    } = useTransactionField(restProps);

    const calculatedValue = overrideValue ?? (valueType === 'boolean' && typeof value === 'boolean'
      ? (value ? 'true' : 'false')
      : valueType === 'int' && typeof value === 'number'
        ? value.toString()
        : value?.toString() // the options list will always be a string, so we can safely convert to string here
    );
    const postValidationActionMessage = mergedRules._validationRequirementFailedMessage;
    const usingErrors = errorKeys.filter(key=>displayErrorKeys.has(key) );
    const errorText = postValidationActionMessage && usingErrors[0] === 'stillInvalidAfterProcessing'
      ? postValidationActionMessage
      : (usingErrors.length > 0 ? errorLookup[usingErrors[0]] : null);
    const wrappedHandleUpdate = (originalNewValue: any, immediate = false, eventSource?: string, eventData?: string, displayLabel?: string) => {
      handleUpdate(originalNewValue, immediate, eventSource, eventData, displayLabel);
      value !== originalNewValue && afterChange?.(originalNewValue);
    };
    return <div className='CheckRadio' style={justUpdated ? { backgroundColor: 'lightgrey' } : {}}>
      {label && <div className='lead d-flex align-items-center'>
        <span>
          {label}{titleGuidanceNoteKey && <ShowGuidanceNotesButton noteId={titleGuidanceNoteKey}/>}{required && <sup className='fs-6' style={{ color: 'red' }}> *</sup>}
        </span></div>}
      <div className={clsJn(label && 'ms-2')}>
        {sublists?.[0]&&<div className='sublist'>{sublists[0]}</div>}
        {Object.entries(options??{}).sort((a,b) => {
          if (typeof a[1] === 'object' &&'order' in a[1] && typeof b[1] === 'object' && 'order' in b[1]) {
            return a[1].order - b[1].order;
          }

          return 0;
        }).map(([key,val], index) => {
          const valLabel = typeof val === 'object' && 'label' in val ? val.label : val;
          const clickHandle = calculatedValue === key
            ? handleRemove
            : valueType === 'boolean'
              ? () => wrappedHandleUpdate(key === 'true', true, undefined, undefined, valLabel)
              : valueType === 'int'
                ? () => wrappedHandleUpdate(parseInt(key), true, undefined, undefined, valLabel)
                : () => wrappedHandleUpdate(key === 'true' || key === 'false' ? key === 'true' : key, true, undefined, undefined, valLabel);
          const label = guidanceNotesMap?.[key] ? <>{valLabel}<ShowGuidanceNotesButton noteId={guidanceNotesMap?.[key]} /></> : valLabel;
          const disabled = allDisabled || ( typeof val === 'object' ? !!val.disabled : false);
          return <><TextClickCheck
            className={clsJn(className, composeErrorPathClassName(fullPath, undefined), )}
            style={style}
            isInvalid={valid ? undefined : true}
            disabled={readOnly || disabled}
            key={name+key}
            name={name+key}
            inline={inline}
            onSelected={clickHandle}
            label={label}
            type={radioType ?? 'checkbox'}
            checked={key === calculatedValue}
            markVaried={hasVaried && key === calculatedValue}
            onFocus={handleAwarenessFocus}
            onBlur={handleAwarenessBlur}
          />
          {sublists?.[index+1]&&<div className='sublist'>{sublists[index+1]}</div>}
          </>;
        })}

      </div>
      {/* d-block to force invalid feedback to show, as it usually requires an adjacent invalid */}
      {/* field */}
      {errorText && <div className='invalid-feedback d-block'>{errorText}</div>}
    </div>;
  },
  BoolCheck: (
    {
      name, label, inline = true, inverter, noToggleOff, unsetIfUnchecked, parentPath, myPath, disabled, overrideChecked, nullishIsFalse, noAwareness, iconUnchecked, iconChecked, ...restProps
    }: WrFieldProps & {
      ydocForceKey?: string
      overrideChecked?: boolean,
      disabled?: boolean,
      inline?: boolean,
      inverter?: boolean,
      noToggleOff?: boolean,
      unsetIfUnchecked?: boolean,
      label?: string,
      title?: string,
      nullishIsFalse?: boolean,
      noAwareness?: boolean,
      iconUnchecked?: string,
      iconChecked?: string,
      className?: string
    }) => {
    const {
      value, handleUpdate, justUpdated, handleAwarenessBlur, handleAwarenessFocus, fullPath, hasVaried, readOnly, handleRemove
    } = useTransactionField({ ...restProps, myPath, parentPath  });
    // XOR https://stackoverflow.com/questions/4540422/why-is-there-no-logical-xor
    function calculateDisplayValue(sourceValue: boolean|undefined) {
      return (!nullishIsFalse && sourceValue == null) ? false : (inverter ? !sourceValue : sourceValue);
    }
    const displayValue = calculateDisplayValue(value);
    const clickHandle = () => {
      const currentValue = overrideChecked ?? displayValue;

      // currentValue represents the current visual value, so even if inverted, the user expects this
      // to unset
      if (unsetIfUnchecked && currentValue) {
        handleRemove();
        return;
      }
      if (noToggleOff && currentValue) {
        return;
      }
      // The tricky part here, is if display value is null, this leads to odd behaviour
      // for checking the inverted value. To set it on, is to set the value to false. However
      // !null (ie !value) leads to true, which will not check the box the user expected. So
      // we reinvert the display value here.
      handleUpdate(!(inverter ? !currentValue : currentValue), true);

    };
    // In case of infinite loop bug that occurs for as yet unknown reasons, and it's somewhere which
    // won't be interacted with multiple agents. Checkboxes are a toggle anyway right?
    const awarenessEnable = noAwareness
      ? {}
      : {
        onFocus: handleAwarenessFocus,
        onBlur: handleAwarenessBlur
      };
    return <div className={composeErrorPathClassName(fullPath, null)} style={ { ...(!iconUnchecked && justUpdated ? { backgroundColor: 'lightgrey' } : {}), height: 'fit-content' }}>
      {iconUnchecked ?
        <IconClickCheck
          disabled={readOnly || disabled}
          name={name}
          onSelected={clickHandle}
          iconUnchecked={iconUnchecked}
          iconChecked={iconChecked}
          checked={overrideChecked ?? displayValue}
          onClick={clickHandle}
          {...restProps}
        />
        : <TextClickCheck
          {...awarenessEnable}
          disabled={readOnly || disabled}
          inline={inline}
          name={name}
          onSelected={clickHandle}
          label={label || ''}
          type={'checkbox'}
          checked={overrideChecked ?? displayValue}
          onClick={clickHandle}
          markVaried={hasVaried}
          {...restProps}
        />}

    </div>;
  },
  ColourPicker
};
