import { Button, Modal } from 'react-bootstrap';
import { AsyncButton } from "./AsyncButton";

type Props = {
  show: boolean;
  name?: string;
  onArchive: () => Promise<void>;
};

export function ArchiveDocumentModal({
  name,
  show,
  onArchive,
  setShow
}: Props) {
  return <Modal show={show}>
    <Modal.Header><h3>Archive</h3></Modal.Header>
    <Modal.Body>
      Are you sure you want to archive {name ?? 'this document'}?
    </Modal.Body>
    <Modal.Footer>
      <Button variant='secondary' onClick={() => setShow(false)}>Cancel</Button>
      <AsyncButton className='btn-danger' onClick={() => onArchive()}>Archive</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
