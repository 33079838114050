import { UserUtil } from '@property-folders/common/util/user';
import { Icon } from './Icon';
import React, { useEffect, useRef, useState } from 'react';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';
import { Button, Overlay, Popover, Spinner } from 'react-bootstrap';
import clsJn from '@property-folders/common/util/classNameJoin';
import { PopperRef } from 'react-bootstrap/types';
import { useBreakpointValue } from '../hooks/useBreakpointValue';
import { GetTeamMembersResponse } from '@property-folders/contract/rest/teams';
import { Maybe } from '@property-folders/contract';

export function Avatar({ name, entityName, agentId, teamId, entity, contents, embedded, nameClassname, customIcon, overrideInitialsSource }: {
  name: string,
  entityName: string,
  agentId?: number,
  teamId?: number,
  entity?: boolean,
  contents?: {
    show?: boolean,
    placement?: 'bottom' | 'top'
  },
  embedded?: boolean,
  nameClassname?: string,
  customIcon?: JSX.Element,
  overrideInitialsSource?: string
}) {
  const bpContentsShow = useBreakpointValue<boolean>({ md: true, base: false }, false);
  const initials = UserUtil.getInitials(overrideInitialsSource ?? name);
  const style = UserUtil.getThumbnailStyle(overrideInitialsSource ?? name, agentId);

  return <div className={'d-flex flex-row justify-content-start align-items-center gap-3 avatar'}>
    <span className='user-initials' style={style} title={name}>{customIcon ? customIcon : (teamId || entity) ? <Icon name={(teamId ? 'group' : 'domain')} /> : initials}</span>
    <div className={clsJn('user-text d-flex flex-column justify-content-center', embedded && 'text-nowrap text-overflow-ellipsis')}>
      <div><h5 className={clsJn('m-0 overflow-hidden text-overflow-ellipsis', nameClassname)}>{
        bpContentsShow && contents?.show && teamId
          ? <PopupTeamInfo teamId={teamId} name={name} placement={contents?.placement || 'bottom'} />
          : name
      }</h5></div>
      <div>{entityName}</div>
    </div>
  </div>;
}

function PopupTeamInfo({
  name,
  teamId,
  placement
}: {
  name: string,
  teamId: number,
  placement: 'bottom' | 'top'
}) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState<Maybe<GetTeamMembersResponse>>(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShow(!show);
  };

  useEffect(() => {
    if (data) return;
    if (!show) return;

    const ac = new AbortController();
    WrappedFetch.json<GetTeamMembersResponse>(LinkBuilder.restApi(`/teams/${teamId}/members`), { signal: ac.signal })
      .then(data => !ac.signal.aborted && data && setData(data))
      .catch(err => {
        console.error(err);
        setErrorMessage('Failed to load team details');
      });

    return () => ac.abort();
  }, [show, !!data]);

  return (
    <div ref={containerRef}>
      <Button
        ref={buttonRef}
        variant='link'
        className={clsJn('p-0 text-dark')}
        onClick={handleClick}
      >
        <h5 className='m-0 text-start'>{name}</h5>
      </Button>
      <Overlay
        show={show}
        target={buttonRef}
        placement={placement}
        container={containerRef}
        rootClose
        onHide={() => setShow(false)}
      >
        <UpdatingPopover>
          <Popover.Header as="h3">Team members</Popover.Header>
          <Popover.Body>
            {!data && !errorMessage && <div className='d-flex flex-row justify-content-center'>
              <Spinner animation='border' />
            </div>}
            {errorMessage && <b className='small text-danger'>{errorMessage}</b>}
            {data?.items && <div className='w-100 small d-grid gap-3' style={{ gridTemplateColumns: 'repeat(3, minmax(0, 1fr))' }}>
              {data.items.map((item, index) => <div key={index} style={{ fontSize: '8px' }}>
                <Avatar key={index} name={item.name} entityName={''} agentId={item.id} embedded={true} />
              </div>
              )}
            </div>}
            {data?.items && !data.items.length && <div>
              No members
            </div>}
          </Popover.Body>
        </UpdatingPopover>
      </Overlay>
    </div>
  );
}

const UpdatingPopover = React.forwardRef<HTMLDivElement, React.PropsWithChildren<{ show?: boolean, popper?: PopperRef }>>(
  ({ popper, children, show: _, ...props }, ref) => {
    const maxWidth = useBreakpointValue({ sm: '468px', md: '692px', base: '276px' }, '276px');
    useEffect(() => {
      popper?.scheduleUpdate?.();
    }, [children, popper, maxWidth]);

    return (
      <Popover ref={ref} popper={popper} {...props} style={{ ...(props as any).style, maxWidth }}>
        {children}
      </Popover>
    );
  },
);
