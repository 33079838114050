import { $createParagraphNode, $getChildCaretAtIndex, $getPreviousSelection, $getRoot, $getSelection, $isRangeSelection, $isRootOrShadowRoot, $isTextNode, $splitNode, ElementNode, LexicalNode } from 'lexical';

/**
 * Fix original version of this function adding extra paragraphs
 * @param node
 */
export function $insertNodeToNearestRootV2<T extends LexicalNode>(node: T): T {
  const selection = $getSelection() || $getPreviousSelection();

  if ($isRangeSelection(selection)) {
    const { focus } = selection;
    const focusNode = focus.getNode();
    const focusOffset = focus.offset;

    if ($isRootOrShadowRoot(focusNode)) {
      $getChildCaretAtIndex(focusNode, focusOffset, 'next').insert(node);
      node.selectNext();
    } else {
      let splitNode: ElementNode;
      let splitOffset: number;
      if ($isTextNode(focusNode)) {
        splitNode = focusNode.getParentOrThrow();
        splitOffset = focusNode.getIndexWithinParent();
        if (focusOffset > 0) {
          splitOffset += 1;
          focusNode.splitText(focusOffset);
        }
      } else {
        splitNode = focusNode;
        splitOffset = focusOffset;
      }
      const [, rightTree] = splitOffset > 0 ? $splitNode(splitNode, splitOffset) : [undefined,splitNode];

      rightTree.insertBefore(node);
      rightTree.selectStart();
      if (rightTree.getChildrenSize()===0) {
        rightTree.remove();
      }
    }
  } else {
    if (selection != null) {
      const nodes = selection.getNodes();
      nodes[nodes.length - 1].getTopLevelElementOrThrow().insertAfter(node);
    } else {
      $getRoot().append(node);
    }
    const paragraphNode = $createParagraphNode();
    node.insertAfter(paragraphNode);
    paragraphNode.select();
  }
  return node.getLatest();
}
