import React, { useCallback, useContext, useState } from 'react';
import { LineageContext } from '../../hooks/useVariation';
import { WizardDisplayContext } from '../../context/WizardContexts';
import { SpinnerButton } from '../AsyncButton';
import { Button } from 'react-bootstrap';
import { Predicate } from '@property-folders/common/predicate';

type ButtonActionCallback =
  | (() => Promise<void>)
  | (() => void);
type ButtonAction = {
  fn: ButtonActionCallback,
  label: string,
  waitingLabel: string
};

export function SigningConfigurationNextButton({
  actions
}: {
  actions: (ButtonAction | undefined)[]
}) {
  const [waiting, setWaiting] = useState<undefined | number>(undefined);
  const { snapshotData } = useContext(LineageContext);
  const { focusErrList } = useContext(WizardDisplayContext);
  const onClick = useCallback((action: ButtonAction, idx: number) => {
    setWaiting(idx);
    return Promise.resolve(action.fn())
      .catch(console.error)
      .finally(() => setWaiting(undefined));
  }, [snapshotData]);

  return <div className="d-flex flex-row-reverse gap-1 align-items-end">
    {actions.filter(Predicate.isNotNull).map((action, idx) => {
      const variant = idx === 0 ? 'primary' : 'outline-secondary';
      return (
        waiting !== undefined
          ? waiting === idx
            ? <SpinnerButton key={idx} variant={variant} processing={true}>{action.waitingLabel}</SpinnerButton>
            : <Button key={idx} variant={variant} disabled={true}>{action.label}</Button>
          : <Button
            key={idx}
            variant={variant}
            disabled={Boolean(focusErrList.length)}
            onClick={() => onClick(action, idx)}>{action.label}</Button>
      );
    })}
  </div>;
}
