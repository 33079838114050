import { EditorConfig, NodeKey, SerializedTextNode, Spread, TextNode } from 'lexical';
import { addClassNamesToElement } from '@lexical/utils';
import './ReplacementToken.scss';

export type SerializedReplacementTokenNode = Spread<{
  type: 'replacement-token'
}, SerializedTextNode>;

export function $createReplacementTokenNode(token: string): ReplacementTokenNode {
  const node = new ReplacementTokenNode(token);
  node.setMode('token');
  return node;
}

export class ReplacementTokenNode extends TextNode {
  constructor(text: string,
    key?: NodeKey,
  ) {
    super(text, key);
  }

  static getType(): string {
    return 'replacement-token';
  }

  static clone(node: ReplacementTokenNode): ReplacementTokenNode {
    return new ReplacementTokenNode(
      node.__text,
      node.__key
    );
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    addClassNamesToElement(element, 'placeholder-token', 'replacement-token', 'badge', 'rounded-pill');
    element.setAttribute('spellcheck', 'false');
    return element;
  }

  static importJSON(serializedNode: SerializedReplacementTokenNode) {
    return $createReplacementTokenNode(serializedNode.text);
  }

  exportJSON(): SerializedReplacementTokenNode {
    return {
      ...super.exportJSON(),
      type: 'replacement-token'
    };
  }
}
