import { useMemo } from 'react';
import PdfWorker from '@property-folders/components/form-gen-util/pdf/pdf-worker?worker';
import {
  CoverSheet,
  PdfWorkerDocumentDefinition,
  PdfWorkerMessage
} from '@property-folders/common/util/pdf/pdf-worker-types';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { uuidv4 } from 'lib0/random';
import { Annexure, FormCode, TransactionMetaData } from '@property-folders/contract';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { FormUtil } from '@property-folders/common/util/form';
import { getUploadAsV2 } from '@property-folders/common/yjs-schema/property';

export type GeneratePdfResponse = ArrayBuffer;
const inflightRequests: {
  [key: string]: {
    resolve: (buffer: ArrayBuffer | Uint8Array) => void;
    reject: (err: any) => void;
    type: PdfWorkerMessage['type']
    purpose?: string;
  }
} = {};

export type PdfWorker = ReturnType<typeof usePdfWorker>;

const isUint8ArrayBuffer = (b: ArrayBuffer | Uint8Array): b is Uint8Array => 'buffer' in b;

export function usePdfWorker() {
  const pdfWorker: Worker = useMemo(
    () => {
      const pf = new PdfWorker();
      pf.addEventListener('message', ev => {
        const { requestId, blob } = ev.data ?? {};
        if (!requestId) {
          console.warn('PDF worker sent invalid message', ev.data);
          return;
        }

        if (!inflightRequests[requestId]) {
          //PDF worker responded to unknown request, probably cancelled request
          return;
        }

        const { resolve, reject } = inflightRequests[requestId];

        if (!blob) {
          reject(new Error('PDF worker responded without blob'));
          return;
        }

        resolve(isUint8ArrayBuffer(blob) ? blob.buffer : blob);
      });

      return pf;
    },
    []
  );

  if (!window.Worker) {
    // supported by all browsers since ie10... I think we'll be okay
    // https://caniuse.com/webworkers
    throw new Error('Web workers unavailable');
  }

  return useMemo(() => ({
    stitchPdf: (opts: {pdfs: (Uint8Array | ArrayBuffer)[], idxBase?: number }) => {
      const requestId = uuidv4();
      const bufferArray = opts.pdfs.map(t => isUint8ArrayBuffer(t) ? t.buffer : t);

      return new Promise<ArrayBuffer>((resolve, reject) => {
        pdfWorker.postMessage(
          {
            type: 'stitchPdf',
            pdfs: bufferArray,
            idxBase: opts.idxBase,
            requestId
          },
          bufferArray
        );

        inflightRequests[requestId] = {
          resolve,
          reject,
          type: 'stitchPdf'
        };
      });
    },

    generateCoverPage: (opts: {
      formConfig: EntityBrandFormConfig,
      headline: string;
      agentName: string;
      documentLabel: string;
      formType: string
    }) => {
      const requestId = uuidv4();

      return new Promise<ArrayBuffer>((resolve, reject) => {
        pdfWorker.postMessage({
          type: 'generateCoverPage',
          requestId,
          ...opts
        });

        inflightRequests[requestId] = {
          resolve,
          reject,
          type: 'generateCoverPage'
        };
      });
    },

    generateAnnexure: async ({ annexure, brand, coversheet, meta, rootMeta }: {
      annexure: Annexure,
      brand: EntityBrandFormConfig,
      coversheet: CoverSheet,
      meta: TransactionMetaData | undefined,
      rootMeta: TransactionMetaData | undefined
    }) => {
      const requestId = uuidv4();
      const bufferArray = (await getAnnexureFileDatas(annexure, rootMeta))
        .map(t => isUint8ArrayBuffer(t) ? t.buffer : t);

      return new Promise<ArrayBuffer>((resolve, reject) => {
        pdfWorker.postMessage({
          pdfs: bufferArray,
          annexure,
          brand,
          coversheet,
          type: 'generateAnnexure',
          requestId
        }, bufferArray);

        inflightRequests[requestId] = {
          resolve,
          reject,
          type: 'generateAnnexure'
        };
      });
    },

    generatePdf: (opts: Exclude<PdfWorkerDocumentDefinition, 'type'>, purpose?: string) => {
      const requestId = uuidv4();

      return new Promise<ArrayBuffer>((resolve, reject) => {
        if (purpose) {
          for (const key of Object.keys(inflightRequests)) {
            if (inflightRequests[key].type !== 'generateDocument') {
              continue;
            }

            if (inflightRequests[key].purpose !== purpose) {
              continue;
            }

            inflightRequests[key].reject(new Error('Aborted'));
            delete inflightRequests[key];
          }
        }

        pdfWorker.postMessage({
          ...opts,
          requestId,
          type: 'generateDocument'
        });
        inflightRequests[requestId] = {
          resolve,
          reject,
          type: 'generateDocument',
          purpose
        };
      });
    },

    /**
     * split the specified pdf into separate documents according to splits option.
     * returns a zip of the split documents.
     */
    split: (opts: { pdf: Blob, splits: { page: number, name: string }[], writeLock?: boolean }) => {
      const requestId = uuidv4();
      return new Promise<string | Blob>((resolve, reject) => {
        pdfWorker.postMessage(
          {
            ...opts,
            requestId,
            type: 'splitPdf'
          }
        );
        inflightRequests[requestId] = {
          resolve,
          reject,
          type: 'splitPdf'
        };
      });
    },

    extractRange: (opts: {pdf: Blob, start: number, exclusiveEnd?: number, writeLock?: boolean}) => {
      const requestId = uuidv4();
      return new Promise<string | Blob>((resolve, reject) => {
        pdfWorker.postMessage(
          {
            ...opts,
            requestId,
            type: 'extractPdfRange'
          }
        );
        inflightRequests[requestId] = {
          resolve,
          reject,
          type: 'extractPdfRange'
        };
      });
    }
  }), []);
}

async function getAnnexureFileDatas(
  annexure: Annexure,
  uploadContainingMeta: TransactionMetaData | undefined // ie probably root, as that is where uploads are referenced by default
): Promise<ArrayBuffer[]> {
  const formId = annexure.id;
  const formCode = 'code' in annexure && annexure.code
    ? annexure.code
    : FormCode.UploadedDocument;

  const formFile = FormUtil.getFormState(formCode, formId, uploadContainingMeta);
  const uploaded = getUploadAsV2(formFile?.upload);
  if (uploaded?.files?.length) {
    const result: ArrayBuffer[] = [];
    for (const file of uploaded.files) {
      const blob = await FileStorage.readFileOnly(file.id);
      if (!blob?.size) throw new Error('Missing specified annexure file data');
      result.push(await blob.arrayBuffer());
    }
    return result;
  }

  const blob = await FileStorage.readFileOnly(annexure.id);
  if (!blob?.size) throw new Error('Could not determine annexure file data');

  return [await blob.arrayBuffer()];
}
