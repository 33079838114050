import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  NodeKey, $isTextNode, FORMAT_ELEMENT_COMMAND, $getRoot, UNDO_COMMAND, REDO_COMMAND
} from 'lexical';
import {
  $getNearestNodeOfType,
  mergeRegister
} from '@lexical/utils';
import { ListNode as ModifiedListNode, $isListNode as $ModifiedIsListNode, INSERT_ORDERED_LIST_COMMAND as MODIFIED_INSERT_ORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND as MODIFIED_REMOVE_LIST_COMMAND } from './lexical-list/src';
import { ListNode as OriginalListNode, $isListNode as $OriginalIsListNode, INSERT_ORDERED_LIST_COMMAND as ORIGINAL_INSERT_ORDERED_LIST_COMMAND ,REMOVE_LIST_COMMAND as ORIGINAL_REMOVE_LIST_COMMAND } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import * as React from 'react';
import { cmdShortcut } from '@property-folders/components/dragged-components/lexical/Environment';
import './ToolbarPlugin.scss';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { Button } from 'react-bootstrap';
import clsJn from '@property-folders/common/util/classNameJoin';
import { LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { $generateNodesFromDOM } from '@lexical/html';
import { ConditionTokens, ConditionTokenType, ReplacementTokens, TokenReplacementData } from '@property-folders/common/util/process-template';
import { flatMap, map, sortBy } from 'lodash';
import { INSERT_CONDITION_COMMAND } from './condition-plugin';
import SearchableDropdown from '../../display/SearchableDropdown';
import { useBreakpointValue } from '../../hooks/useBreakpointValue';

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote'
};

export function ToolbarPlugin(props: { templateConfig?: { documentId: number, formId?: number, defaultSiteTemplateId?: number },  useStandardListPlugin?: boolean, replacementTokens?: ReplacementTokens, conditionTokens?: ConditionTokens, plainTextOnly?: boolean }) {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph');
  const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(null);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const isMobile = useBreakpointValue({ sm: false }, true);

  const ListNode = props.useStandardListPlugin ? OriginalListNode : ModifiedListNode;
  const $isListNode = props.useStandardListPlugin ? $OriginalIsListNode : $ModifiedIsListNode;
  const INSERT_ORDERED_LIST_COMMAND = props.useStandardListPlugin ? ORIGINAL_INSERT_ORDERED_LIST_COMMAND : MODIFIED_INSERT_ORDERED_LIST_COMMAND;
  const REMOVE_LIST_COMMAND = props.useStandardListPlugin ? ORIGINAL_REMOVE_LIST_COMMAND : MODIFIED_REMOVE_LIST_COMMAND;

  type menuPlaceholder = {
    label?: string;
    key: string;
  };

  const sortedReplacementTokens: menuPlaceholder[] = useMemo(() => {
    return sortBy(map(props.replacementTokens, (t,k) => ({ key: k, label: t.label })), t => t.label);
  }, [props.replacementTokens]);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<typeof ListNode>(
            anchorNode,
            ListNode,
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
      }
    }
  }, [activeEditor]);

  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
    );
  }, [activeEditor, updateToolbar]);

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const clearFormatting = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        selection.getNodes().forEach((node) => {
          if ($isTextNode(node)) {
            node.setFormat(0);
            node.setStyle('');
          }
        });
      }
      activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
    });
  }, [activeEditor]);

  const [documentTemplates, setDocumentTemplates] = useState<{ id: string; title: string; content: string; }[]>([]);
  useEffect(() => {
    if (!props.templateConfig) return;

    LegacyApi.getTemplates(
      props.templateConfig.documentId,
      props.templateConfig.formId,
      props.templateConfig.defaultSiteTemplateId || 10)
      .then(result => {
        setDocumentTemplates(result.templates.map(t => ({
          id: t.TemplateID.toString(),
          title: t.TemplateTitle,
          content: t.TemplateText
        })));
      })
      .catch(console.error);
  }, [props.templateConfig?.documentId, props.templateConfig?.formId, props.templateConfig?.defaultSiteTemplateId]);

  const handleSelectTemplate = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const match = documentTemplates.find(t => t.id === e.target.value);
    const content = match?.content || '';
    activeEditor.update(() => {
      const root = $getRoot();
      root.clear();
      if (content) {
        const dom = new DOMParser().parseFromString(content, 'text/html');
        const nodes = $generateNodesFromDOM(activeEditor, dom);
        root.append(...nodes);
      }
    });
  }, [documentTemplates, activeEditor]);

  const handleAddToken = useCallback((token: string) => {
    activeEditor.update(() => {
      const selection = $getSelection();
      selection?.insertText(token);
    });
  }, [props.replacementTokens, activeEditor]);

  const handleNewCondition = useCallback((conditionType: ConditionTokenType, inverse: boolean) => {
    activeEditor.dispatchCommand(INSERT_CONDITION_COMMAND, { condition: conditionType, inverse: inverse });
  }, [props.conditionTokens, activeEditor]);

  type menuCondition = {
    inverse: boolean;
    label: string;
    isTrue: (data: TokenReplacementData) => boolean;
    key: string;
  };

  const allConditions: menuCondition[] = useMemo(() => {
    return flatMap(props?.conditionTokens, (v,k) => [{ key: k, ...v, inverse: false }, { key: k, ...v, inverse: true, label: `NOT ${v.label}` }]);
  }, [props.conditionTokens]);

  return <div className="toolbar">
    {!props.plainTextOnly && <><Button
      onClick={() => {
        activeEditor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
      }}
      className='toolbar-item spaced'
      title='Outdent'
      aria-label='Remove text identation. Shortcut: Shift + Tab'
    >
      <Icon name='format_indent_decrease' pack='material-symbols' />
    </Button>

    <Button
      onClick={() => {
        activeEditor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
      }}
      className='toolbar-item spaced'
      title='Indent'
      aria-label='Add text identation. Shortcut: Tab'
    >
      <Icon name='format_indent_increase' pack='material-symbols' />
    </Button>

    <Button
      onClick={() => {
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
      }}
      className={clsJn('toolbar-item spaced ', { active: isBold })}
      title={cmdShortcut('B')}
      aria-label={`Format text as bold. Shortcut: ${cmdShortcut('B')}`}
    >
      <Icon name='format_bold' pack='material-symbols' />
    </Button>

    <Button
      onClick={() => {
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
      }}
      className={clsJn('toolbar-item spaced ', { active: isItalic })}
      title={cmdShortcut('I')}
      aria-label={`Format text as italics. Shortcut: ${cmdShortcut('I')}`}
    >
      <Icon name='format_italic' pack='material-symbols' />
    </Button>

    <Button
      onClick={() => {
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
      }}
      className={clsJn('toolbar-item spaced ', { active: isUnderline })}
      title={cmdShortcut('U')}
      aria-label={`Format text to underlined. Shortcut: ${cmdShortcut('U')}`}
    >
      <Icon name='format_underlined' pack='material-symbols' />
    </Button>

    <Button
      onClick={formatNumberedList}
      className={clsJn('toolbar-item space', { active: blockType === 'number' })}
      title='Numbered list'
      aria-label='Format numbered list.'
    >
      <Icon name='format_list_numbered' pack='material-symbols' />
    </Button>

    <Button
      onClick={clearFormatting}
      className='toolbar-item spaced'
      title='Clear formatting'
      aria-label='Clear formatting'
    >
      <Icon name='format_clear' pack='material-symbols' />
    </Button></>}

    <Divider />

    <Button onClick={() => activeEditor.dispatchCommand(UNDO_COMMAND, undefined)} className='toolbar-item spaced'><Icon name='undo' pack='material-symbols' /></Button>
    <Button onClick={() => activeEditor.dispatchCommand(REDO_COMMAND, undefined)} className='toolbar-item spaced'><Icon name='redo' pack='material-symbols' /></Button>

    <Divider />

    {!!documentTemplates.length && <select onChange={handleSelectTemplate}>
      <option key='none' value='none'>* Use A Template*</option>
      {documentTemplates.map(t => (<option key={t.id} value={t.id}>{t.title}</option>))}
    </select>}

    {!!sortedReplacementTokens?.length && <div className={'me-2'}>
      <SearchableDropdown<menuPlaceholder> label={'Placeholder'} options={sortedReplacementTokens} onSelect={val => handleAddToken(val.key)} />
    </div>}

    {!!allConditions?.length && <SearchableDropdown<menuCondition> label={`Conditional${!isMobile ? ' Section' : ''}`} options={allConditions} onSelect={val => handleNewCondition(val.key, val.inverse)} />}

  </div>;
}

function Divider(): JSX.Element {
  return <div className="divider" />;
}
