import { applyMigrationsV2, applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import { FormCode, META_APPEND, PropertyRootKey, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract/yjs-schema/property';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { v4 } from 'uuid';
import { Y } from '@syncedstore/core';
import { FormUtil, generateNewRootKeyMigrationFn } from '@property-folders/common/util/form';
import { ensureFormStatesInstances } from '@property-folders/common/yjs-schema/property/form';

function buildNewRootKey(ydoc: Y.Doc, fromMetaRootKey = `${PropertyRootKey.Meta}`) {
  const dataRootKey = v4();
  const metaRootKey = dataRootKey+META_APPEND;
  applyMigrationsV2<TransactionMetaData>({
    doc: ydoc,
    docKey: metaRootKey,
    typeName: 'Property',
    migrations: [generateNewRootKeyMigrationFn(ydoc, fromMetaRootKey)]
  });

  applyMigrationsV2<TransactionMetaData>({
    doc: ydoc,
    docKey: PropertyRootKey.Meta, // Even if fromMetaRootKey is set, we track root keys at the actual property root, so don't set this to something else
    typename: 'Property',
    migrations: [{
      name: 'Inform parent of new sublineage',
      fn: (draft: TransactionMetaData) => {
        if (!Array.isArray(draft.sublineageRoots)) {
          draft.sublineageRoots = [];
        }
        draft.sublineageRoots.push(dataRootKey);
      }
    }]
  });

  return { dataRootKey, metaRootKey };
}

export async function buildContractDocumentAndForm (
  ydoc: Y.Doc,
  filesync: FileSync,
  fromDataRootKey = `${PropertyRootKey.Data}`,
  fromMetaRootKey = `${PropertyRootKey.Meta}`,
  opts?: {
    noClauseOrAnnexureClone?: boolean
  }
) {
  const { noClauseOrAnnexureClone = false } = opts??{};
  const parentData = ydoc.getMap(fromDataRootKey).toJSON();
  const parentMeta = ydoc.getMap(fromMetaRootKey).toJSON();
  const { dataRootKey, metaRootKey } = buildNewRootKey(ydoc, fromMetaRootKey);
  applyMigrationsV2<MaterialisedPropertyData>({
    doc: ydoc,
    docKey: dataRootKey,
    typeName: 'Property',
    migrations: [{
      name: 'Copy data from parent',
      fn: (draft: MaterialisedPropertyData) => {
        // This function is also used to clone contracts, so we can't just exclude every type of clause data. However
        // we can exclude 'clauses' as for legacy reasons, it is only for RSAA clauses.
        const { clauses: _1, ...retain } = parentData as MaterialisedPropertyData;
        Object.assign(draft, retain);

      }
    }]
  });
  const { formId, clauseChildId: newClauseId } = await handleNewForm(ydoc, FormCode.RSC_ContractOfSale, filesync, {}, dataRootKey, metaRootKey)??{};
  if (!formId || !newClauseId) throw new Error('Could not create new form for Contract');

  const rval = { formId, formCode: FormCode.RSC_ContractOfSale };

  if (noClauseOrAnnexureClone) return rval;

  const sourceFormFamily = FormUtil.getFormFamilyState(FormCode.RSC_ContractOfSale, parentMeta);
  const existingClauseChildId = sourceFormFamily?.clauseChildId;

  // Note that the following 2 value clones are different from that performed in the clone contract,
  // in that this one clones from the source, while the other clones directly from the PropertyRootKey
  // a la Template Contract. That said, this does sometimes copy from the Template Contract when an
  // agent clicks Create Contract.
  applyMigrationsV2_1<MaterialisedPropertyData|TransactionMetaData>({
    doc: ydoc,
    docKey: dataRootKey,
    typeName: 'Property',
    migrations: [{
      name: 'Reassign Template RSC Clauses to these Clauses',
      docKey: dataRootKey,
      fn: (draft: MaterialisedPropertyData) => {
        // Now reassign clauses (will not work with RSAA which uses the legacy location, but that doesn't move anyway)
        const existingClauses = draft.clausesByFamily?.find(f=>f.id===existingClauseChildId);
        if (existingClauses && newClauseId) {
          existingClauses.id = newClauseId;
        } else {
          return false;
        }
      }
    }, {
      name: 'Assign default special conditions for auctions',
      docKey: dataRootKey,
      fn: (draft: MaterialisedPropertyData) => {
        // We are relying on live data for this. An unsigned variation in the
        // system isn't the biggest deal, and it is only set as a default for
        // a new form
        if (draft.sale?.saleMethod !== 'auction') return false;
        if (!draft.contractSpecial) draft.contractSpecial = {};
        draft.contractSpecial.financeRequired = false;
        draft.contractSpecial.purchaserSaleRequired = false;
      }
    }, {
      name: 'Copy annexures from parent',
      docKey: metaRootKey,
      fn: (draft: TransactionMetaData) => {
        if (sourceFormFamily?.instances?.length !== 1) {
          console.warn('No support for copying annexure history across multiple instances');
          return false;
        }
        const sourceInstance = sourceFormFamily.instances[0];
        const destFormFamily = FormUtil.getFormFamilyState(FormCode.RSC_ContractOfSale, draft);
        const destInstance = FormUtil.getFormInstanceFromFamilyState(destFormFamily, formId);
        if (!destInstance) {
          console.warn('New instance not found!');
          return false;
        }
        if (!Array.isArray(destInstance?.annexures)) destInstance.annexures = [];
        const axs = destInstance.annexures;
        axs.splice(0,axs.length,...(sourceInstance.annexures??[]));
      }
    }, {
      name: 'Copy cover sheet settings from parent',
      docKey: metaRootKey,
      fn: draft => {
        const meta = draft as TransactionMetaData;
        const sourceInstance = sourceFormFamily?.instances?.[0];
        if (!sourceInstance) {
          console.warn('No source instance');
          return false;
        }
        const destInstance = ensureFormStatesInstances(meta, FormCode.RSC_ContractOfSale)[0];
        if (!destInstance) {
          console.warn('New instance not found!');
          return false;
        }
        if (sourceInstance.cover) {
          destInstance.cover = structuredClone(sourceInstance.cover);
        }
      }
    }]
  });

  return rval;
}
