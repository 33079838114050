import { MaterialisedPropertyData } from '@property-folders/contract';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { boolApplicableOpts, boolKnownOpts, chattelsOptionsContract } from '@property-folders/common/data-and-text/constants';
import {
  calculateFinalPrice2
} from '@property-folders/common/util/pdfgen/sections/contract-price';
import { WrField } from '../CommonComponentWrappers';
import { TenancySubsection } from '../TenancySubsection';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { ShowGuidanceNotesButton } from '../../guidance/ShowGuidanceNotesButton';
import { vendorWorksFieldPlaceholder } from '@property-folders/common/util/formatting/constants';
import { PoolCompliance } from '../SubSections/PoolCompliance';
import { GstWithholding } from '../../../display/form/GstWithholding';
import { CombinedInclusionsExclusions } from '../../../display/form/CombinedInclusionsExclusions';
import { PermittedEncumbrances } from '../../../display/form/PermittedEncumbrances';

interface Props {
  purchaserMode?: boolean;
}

export const ContractSchedule = ({ purchaserMode }: Props): JSX.Element => {
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({});
  const { updateDraft } = useYdocBinder<MaterialisedPropertyData>({ path: '' });
  const { finalPrice } = calculateFinalPrice2(transRoot?.vendorGst, transRoot?.contractPrice);

  return <div className="w-100">
    <CombinedInclusionsExclusions options={chattelsOptionsContract} contractMode={true} purchaserMode={purchaserMode}/>
    {!purchaserMode && <div className='subsection scrollspy-target' data-focus-path="subsection-encroach">
      <div className='fs-4'>Encroachments and fences not on boundaries  <ShowGuidanceNotesButton noteId='knownEncroachments'/></div>
      <div className="w-100 mt-2">
        <WrField.CheckRadio
          label='Are there any encroachments and fences not on boundaries?'
          radioType='checkbox'
          options={boolKnownOpts}
          valueType='boolean'
          inline={true}
          name='encroachEnable'
          myPath='encroachEnable'
        />
      </div>
      {transRoot?.encroachEnable && <div className='w-100 mt-2'>
        <WrField.Control textArea={{ rows: 3 }} name='encroach' myPath='encroach' label='Known encroachments' maxLength={1000}/>
      </div>}
    </div>}

    {!purchaserMode && <div className='subsection scrollspy-target' data-focus-path="subsection-noti-works">
      <div className='fs-4'>Alterations and improvements erected without consent <ShowGuidanceNotesButton noteId='alterationsWithoutConsent'/></div>
      <div className="w-100 mt-2">
        <WrField.CheckRadio
          label='Are there any alterations and improvements erected without consent?'
          radioType='checkbox'
          options={boolKnownOpts}
          valueType='boolean'
          inline={true}
          name='notiWorksEnable'
          myPath='notiWorksEnable'
        />
      </div>
      {transRoot?.notiWorksEnable && <div className='w-100 mt-2'>
        <WrField.Control textArea={{ rows: 3 }} name='notiWorks' myPath='notiWorks' label='Alterations' maxLength={1000}/>
      </div>}
    </div>}

    {!purchaserMode && <div className='subsection scrollspy-target' data-focus-path="subsection-vendor-works">
      <div className='fs-4'>Vendor’s Works</div>
      <div className="w-100 mt-2">
        <WrField.CheckRadio
          label='Are there any works to be carried out by Vendor prior to settlement?'
          radioType='checkbox'
          options={boolApplicableOpts}
          valueType='boolean'
          inline={true}
          name='vendorWorksEnable'
          myPath='vendorWorksEnable'
        />
      </div>
      {transRoot?.vendorWorksEnable && <div className='w-100 mt-2'>
        <WrField.Control textArea={{ rows: 3 }} name='vendorWorks' myPath='vendorWorks' label={vendorWorksFieldPlaceholder} maxLength={1000}/>
      </div>}
    </div>}

    <PoolCompliance contractMode={true}/>

    {!purchaserMode && <div className='subsection scrollspy-target' data-focus-path='subsection-tenancy'>
      <TenancySubsection saleContractMode={true}/>
    </div>}

    <GstWithholding />

    {!purchaserMode && <PermittedEncumbrances />}

  </div>;
};
