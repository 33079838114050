import { $isElementNode, $rewindSiblingCaret, DOMConversionMap, DOMConversionOutput, DOMExportOutput, EditorConfig, ElementNode, isHTMLElement, LexicalEditor, LexicalNode, NodeKey, RangeSelection, SerializedElementNode, Spread, $getSiblingCaret, $createParagraphNode } from 'lexical';
import { IS_CHROME } from '../shared/src/environment';
import invariant from '../shared/src/invariant';
import { setDomHiddenUntilFound } from './ConditionUtils';
import { $createConditionTitleNode } from './ConditionTitleNode';
import { $createConditionContentNode } from './ConditionContentNode';
import { conditionTokens } from '@property-folders/common/workflow-rules/WorkflowTriggerTemplates';
import { $createButtonNode } from '../ButtonNode';
import { $createLabelNode } from '../LabelNode';

type SerializedConditionContainerNode = Spread<{ open: boolean, condition: string, inverse: boolean }, SerializedElementNode>;

export function $convertDetailsElement(domNode: HTMLDivElement): DOMConversionOutput | null {
  const condition = domNode.getAttribute('data-lexical-condition');
  const inverse = domNode.getAttribute('data-lexical-condition-inverse');
  const node = $createConditionContainerNode(true, condition, inverse==='true', false);
  return { node };
}

export class ConditionContainerNode extends ElementNode {
  __open: boolean;
  __condition: string;
  __inverse: boolean;

  constructor(open: boolean, condition: string, inverse?: boolean, key?: NodeKey) {
    super(key);
    this.__open = open;
    this.__condition = condition;
    this.__inverse = inverse || false;
  }

  static getType(): string {
    return 'condition-container';
  }

  static clone(node: ConditionContainerNode): ConditionContainerNode {
    return new ConditionContainerNode(node.__open, node.__condition, node.__inverse, node.__key);
  }

  isShadowRoot(): boolean {
    return true;
  }

  collapseAtStart(selection: RangeSelection): boolean {
    // Unwrap the CollapsibleContainerNode by replacing it with the children
    // of its children (CollapsibleTitleNode, CollapsibleContentNode)
    const nodesToInsert: LexicalNode[] = [];
    for (const child of this.getChildren()) {
      if ($isElementNode(child)) {
        nodesToInsert.push(...child.getChildren());
      }
    }
    const caret = $rewindSiblingCaret($getSiblingCaret(this, 'previous'));
    caret.splice(1, nodesToInsert);
    // Merge the first child of the CollapsibleTitleNode with the
    // previous sibling of the CollapsibleContainerNode
    const [firstChild] = nodesToInsert;
    if (firstChild) {
      firstChild.selectStart().deleteCharacter(true);
    }
    return true;
  }

  createDOM(config: EditorConfig, editor: LexicalEditor): HTMLElement {
    // details is not well supported in Chrome #5582
    let dom: HTMLElement;
    if (IS_CHROME) {
      dom = document.createElement('div');
      dom.setAttribute('open', '');
    } else {
      const detailsDom = document.createElement('details');
      detailsDom.open = this.__open;
      detailsDom.addEventListener('toggle', () => {
        const open = editor.getEditorState().read(() => this.getOpen());
        if (open !== detailsDom.open) {
          editor.update(() => this.toggleOpen());
        }
      });
      dom = detailsDom;
    }
    dom.classList.add('condition-container');
    dom.setAttribute('data-lexical-condition', this.__condition);
    dom.setAttribute('data-lexical-condition-inverse', this.__inverse ? 'true' : 'false');
    return dom;
  }

  updateDOM(prevNode: this, dom: HTMLDetailsElement): boolean {
    const currentOpen = this.__open;
    if (prevNode.__open !== currentOpen) {
      // details is not well supported in Chrome #5582
      if (IS_CHROME) {
        const contentDom = dom.children[1];
        invariant(isHTMLElement(contentDom), 'Expected contentDom to be an HTMLElement');
        if (currentOpen) {
          dom.setAttribute('open', '');
          contentDom.hidden = false;
        } else {
          dom.removeAttribute('open');
          setDomHiddenUntilFound(contentDom);
        }
      } else {
        dom.open = this.__open;
      }
    }

    if (prevNode.__condition !== this.__condition) {
      dom.setAttribute('data-lexical-condition', this.__condition);
    }

    if (prevNode.__inverse !== this.__inverse) {
      dom.setAttribute('data-lexical-condition', this.__inverse ? 'true' : 'false');
    }

    return false;
  }

  static importDOM(): DOMConversionMap<HTMLDivElement> | null {
    return {
      div: (domNode: HTMLDivElement) => {
        if (isConditionContainerNode(domNode)) {
          return {
            conversion: $convertDetailsElement,
            priority: 1
          };
        }
        return null;
      }
    };
  }

  static importJSON(serializedNode: SerializedConditionContainerNode): ConditionContainerNode {
    return $createConditionContainerNode(serializedNode.open, serializedNode.condition, serializedNode.inverse).updateFromJSON(serializedNode);
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('div');
    if (this.__open) element.setAttribute('open', 'true');
    element.setAttribute('data-lexical-condition', this.__condition);
    element.setAttribute('data-lexical-condition-inverse', this.__inverse ? 'true' : 'false');
    element.removeAttribute('class');
    return { element };
  }

  exportJSON(): SerializedConditionContainerNode {
    return {
      ...super.exportJSON(),
      open: this.__open,
      condition: this.__condition,
      inverse: this.__inverse
    };
  }

  setOpen(open: boolean): void {
    const writable = this.getWritable();
    writable.__open = open;
  }

  getOpen(): boolean {
    return this.getLatest().__open;
  }

  toggleOpen(): void {
    this.setOpen(!this.getOpen());
  }
}

export function $createConditionContainerNode(isOpen: boolean, condition: string, inverse: boolean, isNew: boolean = true): ConditionContainerNode {
  const container = new ConditionContainerNode(isOpen, condition, inverse);
  const title = $createConditionTitleNode();
  const closeButton = $createButtonNode(' ✕ ', ()=> container.remove(), 'condition-close-button');
  title.append($createLabelNode(`${inverse ? 'NOT ' : ''}${conditionTokens?.[condition]?.label}`));
  title.append(closeButton);
  container.append(title);
  if (isNew) {
    const content = $createConditionContentNode();
    content.append($createParagraphNode());
    container.append(content);
  }
  return container;
}

export function $isConditionContainerNode(node: LexicalNode | null | undefined): node is ConditionContainerNode {
  return node instanceof ConditionContainerNode;
}

export function isConditionContainerNode(node: HTMLDivElement): boolean {
  return !!node.getAttribute('data-lexical-condition');
}

