import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { Predicate } from '@property-folders/common/predicate';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { Dropdown, FloatingLabel, Form } from 'react-bootstrap';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { ContractPriceType, depositPayAtOpts, MaterialisedPropertyData } from '@property-folders/contract';
import { calculateFinalPrice2 } from '@property-folders/common/util/pdfgen/sections/contract-price';
import React, { useEffect, useState } from 'react';
import { CoolingOffModal, CoolingOffModalData } from './coolingOffModal';
import { InstallmentsCollection } from '../../../display/form/InstallmentsItem';

const ContractPriceDisplayDropdownSection = () => {

  return <>
    <Dropdown.Header>Deposit Payment Options</Dropdown.Header>
    <div className="checkbox-container" style={{ minWidth: '200px' }}>
      <WrField.BoolCheck
        name={'dep-pay-installments'}
        label={'Instalments'}
        parentPath='contractPrice.depositInstallments'
        myPath='installmentsDisplayEnable'
      />

    </div>
  </>;
};

export const RenderContractPriceCustomiseMenu = () => {

  return <Dropdown autoClose="outside" className='header-display-custom-menu'>
    <Dropdown.Toggle variant='light'>
      Display
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <ContractPriceDisplayDropdownSection  />
    </Dropdown.Menu>
  </Dropdown>
  ;
};

export const ContractPrice = (props: TransactionConsumerProps & {
  purchaserMode?: boolean
  templateMode?: boolean
  variationsMode?: boolean
}): JSX.Element => {
  useEffect(() => {
    if (props.offerMode) return;
    props.setHeaderActions?.(() => {
      return {
        actionsRenderMode: 'customRender',
        childRenderer: RenderContractPriceCustomiseMenu
      };

    });
  }, [props.offerMode]);
  const { purchaserMode, templateMode } = props;
  const { value: property } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '' });
  const { value: contractPrice, fullPath  } = useLightweightTransaction<ContractPriceType>(props);
  const { onlyPrice } = calculateFinalPrice2(property?.vendorGst, contractPrice);
  const [changeModal, setChangeModal] = useState<CoolingOffModalData>({ visible: false });
  const showInstallments = contractPrice?.depositInstallments?.installmentsDisplayEnable;
  return (

    <div className="w-100">

      {!templateMode && <div className='subsection scrollspy-target' data-focus-path="subsection-contract-price">
        <div className='fs-4'>{purchaserMode ? 'Offer amount' : 'Purchase Price'}<sup className='fs-5' style={{ color: 'red' }}> *</sup></div>
        <div className='d-flex w-100 flex-wrap'>
          <div className='flex-grow-1 mt-2' style={{ minWidth: '200px' }}>
            <WrField.Control
              name='purchasePrice'
              myPath='purchasePrice'
              parentPath={fullPath}
              label='Purchase Price'
            />
          </div>

          {Predicate.boolFalse(onlyPrice) && !purchaserMode && <>
            <div className='flex-grow-1 mt-2' style={{ minWidth: '200px' }}>
              <WrField.Control
                name='purchaseGst'
                myPath='purchaseGst'
                parentPath={fullPath}
                label='GST on purchase price'
                key='gstField'
              />
            </div>
            <div className='flex-grow-1 mt-2' style={{ minWidth: '200px' }}>
              <FloatingLabel label='Total'>
                <Form.Control
                  disabled={true}
                  placeholder='Total'
                  value={typeof contractPrice?.purchasePrice === 'number' ? canonicalisers.aud(contractPrice?.purchasePrice + (contractPrice?.purchaseGst || 0)).display : ''}
                />
              </FloatingLabel>
            </div>
          </>}
        </div>
      </div>}

      <div className='subsection scrollspy-target' data-focus-path="subsection-deposit">
        <CoolingOffModal
          {...changeModal}
          onClose={()=>setChangeModal({ visible: false })}
        />
        <div className='fs-4'>Deposit</div>
        {!templateMode && <div className='flex-grow-1 mt-2'>
          <WrField.Control
            name='deposit'
            myPath='deposit'
            parentPath={fullPath}
            label='Deposit'
            key='depositField'
          />
        </div>}
        {!purchaserMode && <>
          <div className='w-100 d-flex'>
            <div className='mt-2'>
              <WrField.CheckRadio
                label='Deposit will be paid:'
                radioType='checkbox'
                options={{ ...depositPayAtOpts, ...(showInstallments?{ 'installments': 'in instalments' }:{}) }}
                name='depositPayAt'
                myPath='depositPayAt'
                parentPath={fullPath}
                inline={false}
                afterChange={value => {
                  if (props.variationsMode) return;
                  const newValue = value === 'immediate' ? 'no' : value === 'afterCooling' ? 'yes' : value === 'coolingWaived' ? 'waived' : undefined;
                  newValue && property?.coolingOff?.purchaserRight !== newValue && setChangeModal({ visible: true, changedField: 'deposit', newValue: newValue });
                }}
              />
            </div>
          </div>
          {contractPrice?.depositPayAt === 'other' && <div className='w-100 d-flex'>
            <div className='flex-grow-1 mt-2'>
              <WrField.Control
                name='depositDateOther'
                type='date'
                useCanonical={true}
                label={'Deposit payment date if other'}
                parentPath={fullPath}
                myPath='depositDateOther'
                placeholder='Date of deposit'
              />
            </div>
          </div>}
          {showInstallments && contractPrice?.depositPayAt === 'installments' && <div style={{ marginLeft: '2rem' }}><InstallmentsCollection
            parentPath={fullPath}
            myPath="depositInstallments"
            total={Number(contractPrice?.deposit)}

          />
          </div>}
        </>}
      </div>
    </div>
  );
};
